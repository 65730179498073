import {
    Avatar,
    Grid,
    ListItemIcon,
    Theme,
    Typography
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ReactComponent as BabyChildWhite } from "assets/images/icons/baby-child-white.svg";
import { ReactComponent as BabyChild } from "assets/images/icons/baby-child.svg";
import { ReactComponent as BabySquareWhite } from "assets/images/icons/baby-square-white.svg";
import { ReactComponent as BabySquare } from "assets/images/icons/baby-square.svg";
import { ReactComponent as Logout } from "assets/images/icons/logout.svg";
import { ReactComponent as SettingWhite } from "assets/images/icons/setting-white.svg";
import { ReactComponent as Setting } from "assets/images/icons/setting.svg";
import VectorLine from "assets/images/slider-vector-line.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
import { COLOR_BOX_SHADOW, COLOR_LIGTHEST_BLUE, COLOR_WHITE } from "theme";


const StyledMenu = withStyles({
    paper: {
        borderRadius: "12px",
        boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
        background: `linear-gradient(0deg, ${COLOR_WHITE} 50%, ${COLOR_LIGTHEST_BLUE} 100%)`,
        overflow: "visible",
        marginTop: 5,
        maxWidth: "320px",
        mt: 1.5,
        "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        ".MuiListItem-root.Mui-selected:hover": {
            backgroundColor: "red !important",
        },
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center",
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        borderRadius: "10px",
        marginRight: "10px",
        marginLeft: "10px",

        "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: { width: "25px", height: "25px" },
        root: { width: "100%" },
        avatarContainer: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            height: "100px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(1),
            marginBottom: "60px",
        },
        avatar: { width: "40px", height: "40px", objectFit: "contain" },
    }),
);
export interface UserAvatarActionMenuProps {
    profileImageUrl: string;
    fullName: string;
    registrationType: string;
    handleLogout: () => void;

    render: (
        handleClick: (event: React.MouseEvent<HTMLElement>) => void,
    ) => JSX.Element;
}
export const UserAvatarActionMenuList: React.FC<UserAvatarActionMenuProps> = ({
    profileImageUrl,
    fullName,
    handleLogout,
    registrationType,
    render,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [selectedMenu, setSelectedMenu] = useState(location.pathname);
    const onMenuItemClick = (path: string) => {
        setSelectedMenu(path);
    };
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (selectedMenu !== location.pathname) {
            history.push(selectedMenu);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMenu]);

    const isSelect = (path: string): boolean => {
        if (path === selectedMenu) {
            return true;
        }
        return false;
    };
    const classes = useStyles();
    return (
        <div className={classes.root}>

            {render(handleClick)}
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                PaperProps={{
                    elevation: 0,
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                onClose={handleClose}>
                <div className={classes.avatarContainer}>
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        style={{ marginBottom: "30px" }}>
                        <Grid item>
                            <Avatar
                                alt="Travis Howard"
                                className={classes.avatar}
                                src={profileImageUrl}
                            />
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                direction="column"
                                style={{
                                    marginTop: "10px",
                                }}>
                                <Grid item>
                                    <Typography
                                        noWrap
                                        style={{ maxWidth: "150px" }}
                                        variant="h6"
                                        color="textPrimary">
                                        {fullName}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        style={{ marginTop: "-5px" }}
                                        variant="subtitle1"
                                        color="textSecondary">
                                        {t(
                                            `auth.registerTabs.${registrationType}`,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <img src={VectorLine} alt="VectorLine" />
                </div>

                {(registrationType === "SuperAdministrator") ? (
                    <div>
                        <StyledMenuItem
                            selected={isSelect(
                                appRoutesForContext().superAdmin().allKindergartens,
                            )}
                            onClick={() =>
                                onMenuItemClick(
                                    appRoutesForContext().superAdmin().allKindergartens,
                                )
                            }>

                            <ListItemIcon>
                                {isSelect(
                                    appRoutesForContext().superAdmin().allKindergartens,
                                ) ? (
                                    <BabySquareWhite className={classes.icon} />
                                ) : (
                                    <BabySquare className={classes.icon} />
                                )}
                            </ListItemIcon>
                            <ListItemText primary={t("nav.menu.kindergartensList")} />
                        </StyledMenuItem>
                        <StyledMenuItem
                            selected={isSelect(
                                appRoutesForContext().superAdmin().allParents,
                            )}
                            onClick={() =>
                                onMenuItemClick(
                                    appRoutesForContext().superAdmin().allParents,
                                )
                            }>

                            <ListItemIcon>
                                {isSelect(
                                    appRoutesForContext().superAdmin().allParents,
                                ) ? (
                                    <BabySquareWhite className={classes.icon} />
                                ) : (
                                    <BabySquare className={classes.icon} />
                                )}
                            </ListItemIcon>
                            <ListItemText primary="Parents List" />
                        </StyledMenuItem>

                    </div>
                )
                    :
                    (registrationType === "Kindergarten") ? (
                        <div>
                            <StyledMenuItem
                                selected={isSelect(
                                    appRoutesForContext().registrationList()
                                        .childsRequestsListPath,
                                )}
                                onClick={() =>
                                    onMenuItemClick(
                                        appRoutesForContext().registrationList()
                                            .childsRequestsListPath,
                                    )
                                }>
                                <ListItemIcon>
                                    {isSelect(
                                        appRoutesForContext().registrationList()
                                            .childsRequestsListPath,
                                    ) ? (
                                        <BabyChildWhite className={classes.icon} />
                                    ) : (
                                        <BabyChild className={classes.icon} />
                                    )}
                                </ListItemIcon>
                                <ListItemText primary={t("nav.menu.childList")} />
                            </StyledMenuItem>

                            <StyledMenuItem
                                selected={isSelect(
                                    appRoutesForContext().facilitiesProfile().facilitiesProfilePath,
                                )}
                                onClick={() =>
                                    onMenuItemClick(
                                        appRoutesForContext()
                                            .facilitiesProfile()
                                            .facilitiesProfilePath,
                                    )
                                }>
                                <ListItemIcon>
                                    {isSelect(
                                        appRoutesForContext()
                                            .facilitiesProfile()
                                            .facilitiesProfilePath,
                                    ) ? (
                                        <SettingWhite className={classes.icon} />
                                    ) : (
                                        <Setting className={classes.icon} />
                                    )}
                                </ListItemIcon>
                                <ListItemText primary={t("nav.menu.profile")} />
                            </StyledMenuItem>

                        </div>
                    )
                        :
                        (
                            <div>
                                <StyledMenuItem
                                    selected={isSelect(
                                        appRoutesForContext().registrationList()
                                            .registrationListPath,
                                    )}
                                    onClick={() =>
                                        onMenuItemClick(
                                            appRoutesForContext().registrationList()
                                                .registrationListPath,
                                        )
                                    }>
                                    <ListItemIcon>
                                        {isSelect(
                                            appRoutesForContext().registrationList()
                                                .registrationListPath,
                                        ) ? (
                                            <BabyChildWhite className={classes.icon} />
                                        ) : (
                                            <BabyChild className={classes.icon} />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={t("nav.menu.childList")} />
                                </StyledMenuItem>
                                <StyledMenuItem
                                    selected={isSelect(
                                        appRoutesForContext().registrationList()
                                            .kindergartenRegistrationListPath,
                                    )}
                                    onClick={() =>
                                        onMenuItemClick(
                                            appRoutesForContext().registrationList()
                                                .kindergartenRegistrationListPath,
                                        )
                                    }>
                                    <ListItemIcon>
                                        {isSelect(
                                            appRoutesForContext().registrationList()
                                                .kindergartenRegistrationListPath,
                                        ) ? (
                                            <BabySquareWhite className={classes.icon} />
                                        ) : (
                                            <BabySquare className={classes.icon} />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={t("nav.menu.kindergartensList")} />
                                </StyledMenuItem>
                                <StyledMenuItem
                                    selected={isSelect(
                                        appRoutesForContext().profile().setProfilePathParams(1),
                                    )}
                                    onClick={() =>
                                        onMenuItemClick(
                                            appRoutesForContext()
                                                .profile()
                                                .setProfilePathParams(1),
                                        )
                                    }>
                                    <ListItemIcon>
                                        {isSelect(
                                            appRoutesForContext()
                                                .profile()
                                                .setProfilePathParams(1),
                                        ) ? (
                                            <SettingWhite className={classes.icon} />
                                        ) : (
                                            <Setting className={classes.icon} />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText primary={t("nav.menu.profile")} />
                                </StyledMenuItem>
                            </div>
                        )}
                <StyledMenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout className={classes.icon} />
                    </ListItemIcon>
                    <ListItemText primary={t("nav.menu.logout")} />
                </StyledMenuItem>
            </StyledMenu>
        </div>
    );
};

export default UserAvatarActionMenuList;
