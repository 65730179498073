export const ContactsDeTranslations = {
    contacts: {
        title: "Kontakte",
        ourContacts: {
            title1: "Unsere",
            title2: "Contacts",
        },
        facility: {
            title1: "Die Einrichtung",
            title2: "ist kurzläufig",
            title3: "erreichbar über:",
        },
        familyAndCare: "FamilyandCare gemeinnützige GmbH",
        distanceAbout: `(Entfernung ca. {{count}} Meter)`,
        admission: {
            description:
                "Zutritt auf das KiTa-Gelände nur mit vorheriger Anmeldung möglich",
        },
    },
};
export default ContactsDeTranslations;
