export const AuthEnTranslations = {
    auth: {
        loginError: "Email or password is invalid",
        login: "LOG IN",
        signUp: "SIGN UP",
        dontHaveAccountYet: "Don't have an account yet?",
        loginTitle: "Log In",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi",
        email: {
            title: "Your Email",
            missing: "Email is missing",
            invalid: "Invalid email",
        },
        password: {
            title: "Password",
            placeHolder: "Enter Password",
            missing: "Password is missing",
            invalid: "Invalid password",
        },
        confirmPassword: {
            title: "Confirm Password",
            missing: "Confirm password is missing",
            invalid: "Confirm password is invalid",
            notMatched: "Password confirm does not match",
            placeHolder: "Enter Confirm Password",
        },
        stayLoggedIn: "Stay logged in",
        passwordForgotten: "Password forgotten?",
        loginButtonText: "Log in",
        OrRegisterWith: "Or register with",
        alreadyHaveAccount: "Already have an account?",
        signUpTitle: "Sign Up",
        signUpButton: "Sign up",
        registerTabs: {
            Parent: "Client",
            Kindergarten: "Institution / Company",
            SuperAdministrator: "Super Admin",
        },
        successfullyRegistered: "Registered successfully",
        registerSucces: {
            registration: "Registration",
            isDone: " is Done!",
            registrationMessage: "You will now recieve a contirmation emall. dlease contirm vour emal address.",
            btnText: "Done",
        }
    },
};
export default AuthEnTranslations;
