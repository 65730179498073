import { Grid } from "@material-ui/core";
import React from "react";
import {
    AppBackButtonWithBackwardArrow,
    AppPrimaryButtonWithForwardIcon,
} from "components";
import { useTranslation } from "react-i18next";
export interface AppStepperFooterProps {
    activeStep: number;
    handleBack: () => void;
    handleReset?: () => void;
    stepsLength: number;
}
export const AppStepperFooter = React.memo<AppStepperFooterProps>(
    ({ activeStep, handleBack, handleReset, stepsLength }) => {
        const { t } = useTranslation();
        return (
            <>
                <Grid
                    container
                    justifyContent="flex-end"
                    spacing={2}
                    style={{ marginTop: 40 }}>
                    {handleReset && activeStep === stepsLength - 1 && (
                        <Grid item>
                            <AppBackButtonWithBackwardArrow
                                text={t("common.reset")}
                                onClick={handleReset}
                                disabled={activeStep === 0}
                            />
                        </Grid>
                    )}
                    <Grid item>
                        {activeStep !== 0 && (
                            <AppBackButtonWithBackwardArrow
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <AppPrimaryButtonWithForwardIcon
                            text={
                                activeStep === stepsLength - 1
                                    ? t("common.submit")
                                    : t("common.continue")
                            }
                            onClick={() => {
                                // activeStep === steps.length - 1 &&
                                //     submit &&
                                //     submit();
                            }}
                            type="submit"
                        />
                    </Grid>
                </Grid>
            </>
        );
    },
);

export default AppStepperFooter;
