import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { AppPrimaryButtonWithForwardIcon } from "components";
import moment, { Moment } from "moment-timezone";
import React, { useState } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RegistrationListModal } from "sdk";
import RegistrationListExpendedRowFirstContainer from "./RegistrationListExpendedRowFirstContainer";
import RegistrationListExpendedRowSecondContainer from "./RegistrationListExpendedRowSecondContainer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: "50px",
            paddingRight: "20px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
        },
        title: { paddingBottom: theme.spacing(3) },
        body: {
            display: "flex",
            width: "100%",
        },

        saveButton: { marginTop: theme.spacing(5) },
    }),
);
export interface RegistrationListExpendedRowProps
    extends ExpanderComponentProps<RegistrationListModal> {
    onSubmit: (value: RegistrationListModal) => void;
}
export const RegistrationListExpendedRow: React.FC<RegistrationListExpendedRowProps> =
    ({ data, onSubmit }) => {
        const { t } = useTranslation();
        const classes = useStyles();
        const { control, handleSubmit, reset } = useForm<RegistrationListModal>(
            {
                defaultValues: {
                    ...data,
                    Date: moment(data.Date),
                    SupportStart: moment(data.SupportStart),
                },
            },
        );
        const values = true;
        const [readOnly, setReadOnly] = useState(values);

        const submit = handleSubmit((data) => {
            reset({ ...data, Date: moment(data.Date as Moment) });
            onSubmit({
                ...(data as RegistrationListModal),
                Date: moment(data.Date as Moment),
            });
            toast.success(t("common.successfullySaved"));
            setReadOnly(true);
        });
        const onEditClick = () => {
            setReadOnly(false);
        };
        return (
            <div className={classes.root}>
                <div style={{ maxWidth: "1200px" }}>
                    <form noValidate={true} onSubmit={submit}>
                        <div className={classes.title}>
                            <Typography variant="h5">
                                {t("registrationList.childRow.title")}
                            </Typography>
                        </div>
                        <div className={classes.body}>
                            <RegistrationListExpendedRowFirstContainer
                                parentState={data}
                                control={control}
                                readonly={readOnly}
                            />
                            <RegistrationListExpendedRowSecondContainer
                                control={control}
                                readonly={readOnly}
                                parentState={data}
                            />
                        </div>
                        <Grid
                            container
                            justifyContent="center"
                            className={classes.saveButton}>
                            <Grid item>
                                <AppPrimaryButtonWithForwardIcon
                                    type={readOnly ? "button" : "submit"}
                                    style={{ minWidth: "187px" }}
                                    onClick={() => readOnly && onEditClick()}
                                    text={t(
                                        `common.${readOnly ? "edit" : "save"}`,
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        );
    };
export default RegistrationListExpendedRow;
