import {
    createStyles, Grid, makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { ReactComponent as SearchIcon } from "assets/images/icons/magnifier-white.svg";
import KindergartenSectionStep1 from "assets/images/kindergarten-section-step-1.png";
import { AppButton, AppStepperFooterProps } from "components";
import React from "react";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            paddingRight: "10px",
            paddingBottom: "150px",
            paddingLeft: "50px",
            justifyContent: "space-between",

            [theme.breakpoints.up("xl")]: {
                paddingBottom: "180px",
            },
            [theme.breakpoints.up("xll")]: {
                paddingTop: "50px",
                paddingBottom: "250px",
            },
        },
        body: {
            [theme.breakpoints.down("md")]: {
                marginTop: "10px",
            },
        },
        button: {
            height: "56px",
            width: "100%",
            margin: 5,
        },
        icon: {
            objectFit: "contain",
            width: 20,
            height: 20
        },
        image: {
            objectFit: "contain",
            width: "820px",
            height: "200px",
            [theme.breakpoints.down("lg")]: {
                width: "750px",
                height: "200px",
            },
            [theme.breakpoints.up("xll")]: {
                width: "890px",
                height: "200px",
            },
        },
    }),
);
export interface FacilitiesStep1CommonProps
    extends AppStepperFooterProps {
    handleNext: () => void;
}

export const FacilitiesStep1: React.FC<FacilitiesStep1CommonProps> = ({
    handleNext,

    ...rest
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const submit = () => {
        handleNext();
    }

    return (<>
        <div className={classes.root}>
            <div className={classes.body}>
                <div>
                    <Grid
                        container
                        alignItems="center"
                        direction="column" >
                        <Grid>
                            <Grid item>
                                <img src={KindergartenSectionStep1} className={classes.image} alt="KindergartenSectionStep1" />
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Typography variant="h4" style={{ marginTop: 50, marginBottom: 10 }}>
                                {t("kindergarten.step1.title")}
                            </Typography>
                        </Grid>
                        <Grid style={{ margin: 10, textAlign: "center" }}>
                            <Typography variant="body1" color="textSecondary">
                                {t("kindergarten.step1.text")}
                            </Typography>
                        </Grid>
                        <Grid>
                            <AppButton
                                onClick={submit}
                                type="submit"
                                text={t("kindergarten.step1.findYourFacility")}
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "145px" }}
                                classes={{ root: classes.button }}
                                endIcon={
                                    <SearchIcon className={classes.icon} />
                                }
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </>
    );
};

export default FacilitiesStep1;
