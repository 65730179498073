import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AppDialog } from "components/Dialog";
import { CheckoutForm } from "components/StripeForm";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientSecret, StripeSubscribe } from 'sdk';
import { BG_GRADIENT } from 'theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      width: "100%",
      overflow: "auto",
      background: BG_GRADIENT,
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    stepperContainer: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(10),
      paddingLeft: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    stepperBody: {
      width: "100%",
      maxWidth: "1200px",
    },
    container: {
      width: "100%",
      // marginTop: theme.spacing(5),
      // marginBottom: theme.spacing(5),
    },
    stepperLabel: {
      width: "100%",
      maxWidth: "500px",
    },
  }),
);

const stripePromise = loadStripe('pk_test_51L7HJUJTSiiZDbzeZ8xefrgwMBQedwdTihTOHuyHYiaeVnx7oDtZEgQeRAazw5TIcLDSheHp1sHT9UU7MN1MuuEn004cp0jTmr');

export interface AppStripeDialogProps {
  open: boolean;
  onClose: () => void;
  clientSecret: ClientSecret;
  stripeSubscribe: StripeSubscribe;
}

export const AppStripeDialog: React.FC<AppStripeDialogProps> =
  ({
    onClose, open, clientSecret, stripeSubscribe
  }) => {
    const classes = useStyles();
    const { t } = useTranslation();


    useEffect(() => {
      console.log(clientSecret)
      console.log("clientSecret")
    }, [])

    return (
      <AppDialog
        open={open}
        handleClose={onClose}
        className={classes.root}
        scroll="body"
        classes={{
          root: classes.root,
          container: classes.container,
        }}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center">
          <Grid item className={classes.title}>
            <Typography variant="h4">
              Stripe
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              <Elements stripe={stripePromise} options={clientSecret}>
                <CheckoutForm stripeSubscribe={stripeSubscribe} />
              </Elements>
            </Typography>
          </Grid>
        </Grid>
      </AppDialog>
    );
  };