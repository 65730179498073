import {
    createStyles,
    Grid,
    makeStyles,
    Slider,
    SliderProps,
    StandardProps,
    Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import SliderVectorLine from "assets/images/slider-vector-line.png";
import { StyleClassKey } from "globals";
import { COLOR_PRIMARY_DARK } from "theme";
import React from "react";
const styles = () =>
    createStyles({
        root: {},
    });

type AppSliderClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppSliderProps
    extends StandardProps<Omit<SliderProps, "onChange">, AppSliderClassKey> {
    unit?: string;
    onChange: (value: number) => void;
}
const CustomSlider = withStyles({
    root: {},
    thumb: {
        marginTop: "-4.5px",
        padding: "7px",
    },
    active: {},
    valueLabel: {
        left: "calc(-50% -10px)",
        top: -32,
        "& *": {
            width: "100px",
            background: "transparent",
            color: COLOR_PRIMARY_DARK,
            fontWeight: 500,
            fontSize: "1rem",
        },
    },
    track: {
        height: 4,
    },
    rail: {
        opacity: 1,
        height: 1,
        marginTop: "2px",
        backgroundRepeat: "repeat-x",
        background: `url("${SliderVectorLine}")`,
    },
})(Slider);

export const AppSlider = React.memo<AppSliderProps>(
    ({ unit, onChange, ...rest }) => {
        const handleSliderChange = (
            event: any,
            newValue: number | number[],
        ) => {
            onChange(newValue as number);
        };

        const classes = useStyles(rest);
        return (
            <div className={classes.root}>
                <CustomSlider
                    {...rest}
                    onChange={handleSliderChange}
                    aria-labelledby="discrete-slider-small-steps"
                    valueLabelDisplay="on"
                    valueLabelFormat={(value) => `${value} ${unit}`}
                />
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="body1" color="textSecondary">
                            {`${rest.min}  ${unit ? unit : ""} `}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="textSecondary">
                            {`${rest.max} ${unit ? unit : ""}`}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    },
);

export default AppSlider;
