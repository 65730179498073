import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import { COLOR_GRAY } from "theme";

export interface AppTableExpandableIconProps {
    toggle: boolean;
    id: number;
    onRowExpandClick: (id: number) => void;
}
export const AppTableExpandableIcon = React.memo<AppTableExpandableIconProps>(
    ({ toggle, id, onRowExpandClick }) => {
        return (
            <div
                style={{ fontSize: "1rem", color: COLOR_GRAY }}
                onClick={() => onRowExpandClick(id)}>
                {toggle ? (
                    <ArrowForwardIosIcon style={{ fontSize: "0.9rem" }} />
                ) : (
                    <KeyboardArrowDownIcon style={{ fontSize: "1.4rem" }} />
                )}
            </div>
        );
    },
);

export default AppTableExpandableIcon;
