export const RegistrationListDeTranslations = {
    registrationList: {
        title: "Kinderliste",
        registrationId: "Registrierungs ID",
        date: "Datum",
        firstName: "Vorname",
        lastName: "Nachname",
        age: "Alter",
        gender: "Geschlecht",
        start: "Start",
        end: "Ende",
        childRow: {
            title: "Informationen  über das Kind",
        },
    },
    kindergartenRegistrationList: {
        title: "Anmeldeliste für den Kindergarten",
        registrationId: "Registrierungs ID",
        date: "Datum",
        kindergarten: "Kindergarten",
        location: "Standort",
        info: "Infos",
        status: "Status",
        childRow: {
            title: "Kindergarten Informationen",
            name: "Name",
            city: "City",
            address: "Address",
            indexNumber: "Index Number",
            email: "Email",
            phoneNumber: "Phone Number",
            info: "Info",
        },
    },
    listChildrenRequests: {
        all: "Alle",
        Accepted: "Akzeptiert",
        Pending: "Anhängig",
        Rejected: "Abgelehnt",
        children: "Kinder",
    },
};
export default RegistrationListDeTranslations;
