export interface SearchFacilitiesRequest {
    name: string;
    zip: string,
}

export const getSearchFacilitiesRequestInitialValue =
    (): SearchFacilitiesRequest => {
        return {
            name: "",
            zip: "",
        };
    };

export interface FacilitiesDataList {
    Id: number;
    Name: string;
    ZipCode: number;
    Address: string;
    City: string;
    IsSelected?: boolean;
    IsRegistered: boolean;
}

export interface MultiStepRegistrationFacilities extends MultiStepRegistrationFacilitiesForm {
    IdFacilities: number;
    Owner: boolean,
}

export interface MultiStepRegistrationFacilitiesForm {
    FullName: string;
    Email: string;
    PhoneNumber: string;
    clientSetPasswordURI: string;
    langCode: string;
}

export const getMultiStepRegistrationFacilitiesInitialValue =
    (): MultiStepRegistrationFacilities => {
        return {
            IdFacilities: 0,
            Owner: false,
            FullName: "",
            Email: "",
            PhoneNumber: "",
            clientSetPasswordURI: "",
            langCode: "de",
        };
    };

export const getSearchFacilitiesInitialValue =
    (): FacilitiesDataList => {
        return {
            Id: 0,
            Name: "",
            ZipCode: 0,
            Address: "",
            City: "",
            IsRegistered: false,
        };
    };

export interface ConfirmationAndSetPasswordFacilities {
    code: string,
    userId: string,
    password: string,
    confirmPassword: string,
}

export const getConfirmationAndSetPasswordFacilitiesInitialValue =
    (): ConfirmationAndSetPasswordFacilities => {
        return {
            code: "",
            userId: "",
            password: "",
            confirmPassword: "",
        };
    };

export interface ConfirmationAndSetPasswordFacilitiesForm {
    Email: string;
    Password: string;
    ConfirmPassword: string;
}

export const getConfirmationAndSetPasswordFacilitiesFormInitialValue =
    (): ConfirmationAndSetPasswordFacilitiesForm => {
        return {
            Email: "",
            Password: "",
            ConfirmPassword: "",
        };
    };

export interface AddNewFacilitiesViewForm {
    firstname: string,
    lastname: string,
    kindergartenName: string,
    address: string,
    longitude: string,
    latitude: string,
    postalCode: string,
    city: string,
    phone: string,
    registerPerson: number,
    country: string,
    email: string,
}

export const getAddNewFacilitiesViewFormInitialValue =
    (): AddNewFacilitiesViewForm => {
        return {
            firstname: "",
            lastname: "",
            kindergartenName: "",
            address: "",
            longitude: "",
            latitude: "",
            postalCode: "",
            city: "",
            phone: "",
            registerPerson: 0,
            country: "",
            email: "",
        };
    };

export interface DataFacilitiesSearch {
    data: FacilitiesDataList[];
}

export const FacilitiesDataTestingList: FacilitiesDataList[] = [
    {
        Id: 1,
        Address: "Berliner Straße 2 23627 Groß Grönau Schleswig-Holstein",
        ZipCode: 23627,
        Name: "Ev. Kita Grß Grönau",
        City: "Berliin",
        IsRegistered: false,
    },
    {
        Id: 2,
        Address: "Berliner Straße 1 24852 Eggebek Schleswig-Holstein",
        ZipCode: 24852,
        Name: "Kita Beek-Spatzen",
        City: "Berlin",
        IsRegistered: false,

    },
    {
        Id: 3,
        Address: "Berliner Straße 58 25813 HusumSchleswig-Holstein",
        ZipCode: 25813,
        Name: "Kita Beek-Spatzen",
        City: "Berlin",
        IsRegistered: false,
    },
    {
        Id: 4,
        Address: "Berliner Straße 58 25813 HusumSchleswig-Holstein",
        ZipCode: 25813,
        Name: "Kita Beek-Spatzen2",
        City: "Berlin2",
        IsRegistered: false,

    }, {
        Id: 5,
        Address: "Berliner Straße 58 25813 HusumSchleswig-Holstein",
        ZipCode: 25813,
        Name: "Kita Beek-Spatzen2",
        City: "Berlin3",
        IsRegistered: false,
    },

];
