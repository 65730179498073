import {
    Divider,
    Grid,
    IconButton,
    Theme,
    Typography
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ReactComponent as Delete } from "assets/images/icons/delete.svg";
import clsx from "clsx";
import {
    AppControlledDatePicker,
    AppControlledRadioButton,
    AppFileUploader,
    AppLabelWrapper,
    AppTextField
} from "components";
import { CareHours, Gender } from "globals/Types";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RegistrationForChildrenStep1 } from "sdk";
import { CHILDREN_REGISTRATION_MAX_WIDTH } from "theme";
const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
        },
        fullWidth: { width: "100%" },
        fieldsContainer: {
            marginTop: theme.spacing(4),
        },
        body: {
            width: "100%",
            maxWidth: CHILDREN_REGISTRATION_MAX_WIDTH,
        },
        deleteIcon: {
            width: "25px",
            height: "25px",
        },
        divider: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(3),
        },
        radioLabel: { alignItems: "flex-start", paddingTop: "15px" },
    }),
);

export interface RegistrationForChildStep1FieldsProps {
    control: Control<{ children: RegistrationForChildrenStep1[] }>;
    index: number;
    removeChild: (index: number) => void;
    state: RegistrationForChildrenStep1;
}
export const RegistrationForChildStep1Fields: React.FC<RegistrationForChildStep1FieldsProps> =
    ({ control, index, removeChild, state }) => {
        const classes = useStyle();
        const { t } = useTranslation();

        return (
            <div className={classes.root}>
                <div className={classes.body}>
                    {index > 0 && <Divider className={classes.divider} />}
                    <Grid
                        container
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        style={{ textAlign: "center" }}>
                        <Grid item xs={10}>
                            <Typography variant="body1" color="textSecondary">
                                {t("registrationForChildren.childNo", {
                                    count: index + 1,
                                })}
                            </Typography>
                        </Grid>
                        {index > 0 && (
                            <Grid item style={{ maxWidth: "30px" }}>
                                <IconButton onClick={() => removeChild(index)}>
                                    <Delete className={classes.deleteIcon} />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        spacing={2}
                        className={clsx(
                            classes.fullWidth,
                            classes.fieldsContainer,
                        )}>
                        <Grid item xs={12}>
                            <AppLabelWrapper
                                label={t(
                                    "registrationForChildren.step1.firstNameChild.title",
                                )}
                                required>
                                <AppTextField
                                    control={control}
                                    name={`children.${index}.FirstNameChild`}
                                    fullWidth
                                    rules={{
                                        required: t(
                                            "registrationForChildren.step1.firstNameChild.missing",
                                        ) as string,
                                    }}
                                    placeholder={t(
                                        "registrationForChildren.step1.firstNameChild.placeHolder",
                                    )}
                                    required
                                />
                            </AppLabelWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <AppLabelWrapper
                                label={t(
                                    "registrationForChildren.step1.lastNameChild.title",
                                )}
                                required>
                                <AppTextField
                                    control={control}
                                    name={`children.${index}.LastNameChild`}
                                    fullWidth
                                    rules={{
                                        required: t(
                                            "registrationForChildren.step1.lastNameChild.missing",
                                        ) as string,
                                    }}
                                    placeholder={t(
                                        "registrationForChildren.step1.lastNameChild.placeHolder",
                                    )}
                                    required
                                />
                            </AppLabelWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <AppLabelWrapper
                                label={t(
                                    "registrationForChildren.step1.gender.title",
                                )}
                                required>
                                <AppControlledRadioButton
                                    defaultValue={state.Gender?.toString()}
                                    name={`children.${index}.Gender`}
                                    control={control}
                                    rules={{
                                        required: t(
                                            "registrationForChildren.step1.gender.missing",
                                        ) as string,
                                    }}
                                    gridProps={{ xs: 6 }}
                                    checkBoxes={[
                                        {
                                            value: Gender.MALE,
                                            label: t("common.gender.MALE"),
                                        },
                                        {
                                            value: Gender.FEMALE,
                                            label: t("common.gender.FEMALE"),
                                        },
                                    ]}
                                />
                            </AppLabelWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <AppLabelWrapper
                                label={t(
                                    "registrationForChildren.step1.birthdayOfTheChild.title",
                                )}
                                required>
                                <AppControlledDatePicker
                                    fullWidth
                                    name={`children.${index}.BirthdayOfTheChild`}
                                    control={control}
                                    rules={{
                                        required: t(
                                            "registrationForChildren.step1.birthdayOfTheChild.missing",
                                        ) as string,
                                    }}
                                />
                            </AppLabelWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <AppLabelWrapper
                                label={t(
                                    "registrationForChildren.step1.supportStart.title",
                                )}
                                required>
                                <AppControlledDatePicker
                                    fullWidth
                                    name={`children.${index}.SupportStart`}
                                    control={control}
                                    rules={{
                                        required: t(
                                            "registrationForChildren.step1.supportStart.missing",
                                        ) as string,
                                    }}
                                />
                            </AppLabelWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <AppLabelWrapper
                                classes={{ root: classes.radioLabel }}
                                label={t(
                                    "registrationForChildren.step1.careHours.title",
                                )}
                                required>
                                <AppControlledRadioButton
                                    defaultValue={state.CareHours?.toString()}
                                    columnSpacing={2}
                                    control={control}
                                    name={`children.${index}.CareHours`}
                                    rules={{
                                        required: t(
                                            "registrationForChildren.step1.careHours.missing",
                                        ) as string,
                                    }}
                                    gridProps={{ xs: 6 }}
                                    checkBoxes={[
                                        {
                                            value: CareHours.FOUR_TO_FIVE,
                                            label: t(
                                                "common.careHours.FOUR_TO_FIVE",
                                            ),
                                        },
                                        {
                                            value: CareHours.FIVE_TO_SEVEN,
                                            label: t(
                                                "common.careHours.FIVE_TO_SEVEN",
                                            ),
                                        },
                                        {
                                            value: CareHours.SEVEN_T0_NINE,
                                            label: t(
                                                "common.careHours.SEVEN_T0_NINE",
                                            ),
                                        },

                                        {
                                            value: CareHours.NINE_TO_TWELVE,
                                            label: t(
                                                "common.careHours.NINE_TO_TWELVE",
                                            ),
                                        },
                                    ]}
                                />
                            </AppLabelWrapper>
                        </Grid>

                        <Grid item xs={12}>
                            <AppLabelWrapper
                                label={t(
                                    "registrationForChildren.step2.message.title",
                                )}>
                                <AppTextField
                                    control={control}
                                    //name="Message"
                                    name={`children.${index}.Message`}
                                    fullWidth
                                    multiline
                                    rows={5}
                                    //  shouldUnregister={true}
                                    placeholder={t(
                                        "registrationForChildren.step2.message.placeHolder",
                                    )}
                                />
                            </AppLabelWrapper>
                        </Grid>
                        <Grid item xs={12}>
                            <AppLabelWrapper
                                label={t(
                                    "registrationForChildren.step2.attachFile.title",
                                )}>
                                <>
                                    <AppFileUploader
                                        control={control}
                                        name={`children.${index}.AttachedFile`}
                                        multiple
                                    />
                                    <Typography
                                        style={{
                                            marginLeft: "10px",
                                        }}
                                        color="textSecondary"
                                        variant="body1">
                                        {t(
                                            "registrationForChildren.step2.attachFile.attachFileWarning",
                                        )}
                                    </Typography>
                                </>
                            </AppLabelWrapper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    };

export default RegistrationForChildStep1Fields;
