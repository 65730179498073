import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { ReactComponent as SearchIcon } from "assets/images/icons/magnifier-white.svg";
import { AppButton, AppButtonProps } from "components";
import { AppSelect } from "components/Inputs/ComboBox";
import { COLOR_BOX_SHADOW, COLOR_WHITE } from "theme";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MapSearchRequest } from "sdk";
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { MapAutoCompleteField } from "./MapAutoCompleteField";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "10px",
            background: COLOR_WHITE,
            maxWidth: "1000px",
            width: "100%",
            boxShadow: `6px 6px 15px 1px ${COLOR_BOX_SHADOW}`,
        },
        button: {
            borderRadius: "0px 10px 10px 0px / 0px 10px 10px 10px",
            height: "56px",
            width: "100%",
        },
        icon: { objectFit: "contain", width: 20, height: 20 },
        iconLocation: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
        divider: {
            width: "1px",
            background: theme.palette.grey[500],
            height: "50%",
        },
        body: {
            display: "flex",
            width: "100%",
        },
    }),
);

export interface MapSearchField {
    zipCode: number;
    address: string;
}
export interface AppMapSearchFieldProps {
    values: MapSearchRequest;
    onSearchButtonClick: (value: MapSearchRequest) => void;
    searchButtonProps?: AppButtonProps;
}

export const AppMapSearchField = React.memo<AppMapSearchFieldProps>(
    ({ onSearchButtonClick, searchButtonProps, values }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const {
            suggestions: { data, loading },
            setValue,
        } = usePlacesAutocomplete({
            requestOptions: {
                componentRestrictions: { country: "DE" },
            },
            debounce: 300,
            cache: 24 * 60 * 60,
        });

        const { control, handleSubmit } = useForm<MapSearchField>({
            defaultValues: {
                address: values.address ? values.address : undefined,
                zipCode: values.zipCode ? values.zipCode : undefined,
            },
        });
        const addressWatching = useWatch({
            control,
            name: "address",
        });

        useEffect(() => {
            if (addressWatching) {
                setValue(addressWatching);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [addressWatching]);

        const submit = handleSubmit((submitData) => {
            getGeocode({
                address: submitData.address,
                componentRestrictions: {
                    postalCode: submitData.zipCode.toString(),
                },
            })
                .then((results) => {
                    return getLatLng(results[0]);
                })
                .then(({ lat, lng }) => {
                    onSearchButtonClick({
                        ...values,
                        lat: lat,
                        lng: lng,
                        address: submitData.address,
                        zipCode: submitData.zipCode,
                    });
                })
                .catch((error) => {
                    toast(t("mapSearch.error.noResultFound"), {
                        type: "error",
                    });
                });
        });
        return (
            <div className={classes.root}>
                <form noValidate onSubmit={submit}>
                    <div className={classes.body}>
                        <div style={{ flexGrow: 1.8 }}>
                            <AppSelect                            
                                // options={[{ title: "10115", value: 10115 }]}
                                control={control}
                                name="zipCode"
                                defaultValue={values.zipCode ? (values.zipCode + "") : ""}
                                rules={{
                                    required: t(
                                        "common.zipCode.missing"
                                    ) as string,
                                }}
                                //getOptionLabel={(option) => option.title}
                                freeSolo={true}
                                showerrorsintooltip={"true"}
                                tooltipprops={{ arrow: true }}
                                textfieldprops={{
                                    customBorderRadius: "10px 0px 0px 10px / 10px 0px 0px 10px",
                                    removeShadow: true,
                                    placeholder: t("home.zipCode"),
                                }} options={[]}                            />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className={classes.divider}></div>
                        </div>
                        <div style={{ flexGrow: 9 }}>
                            <MapAutoCompleteField
                                data={data}
                                control={control}
                                iconLocationClass={classes.iconLocation}
                                name="address"
                                defaultValue={values.address ? values.address : ""}
                                loading={loading}
                                rules={{
                                    required: t(
                                        "common.address.missing",
                                    ) as string,
                                }}
                            />
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <AppButton
                                type="submit"
                                text={t("common.findDaycareCentersNearMe")}
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "300px" }}
                                classes={{ root: classes.button }}
                                startIcon={
                                    <SearchIcon className={classes.icon} />
                                }
                                {...searchButtonProps}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    },
);

export default AppMapSearchField;
