export type AvailableLocale = "de" | "en";

export const config = {
    siteName: process.env.REACT_APP_SITE_NAME,
    release: process.env.REACT_APP_SITE_RELEASE,
    apiBaseUrl: "https://kitaplatzapi.azurewebsites.net/api",
    googleMap: {
        googleMapJavaScriptApiKey: "AIzaSyBhe3Lz4Il9hQCrHbfFVWry5m6Bh0XOmvA",
        googleMapAutoCompleteApiKey:
            "AIzaSyBhe3Lz4Il9hQCrHbfFVWry5m6Bh0XOmvA",
    },
    sentry: {
        enable: process.env.REACT_APP_ENABLE_SENTRY === "true",
        dsn: process.env.REACT_APP_SENTRY_DSN,
        env: process.env.REACT_APP_SENTRY_ENV,
    },
};

// console.debug("config", config);
export default config;
