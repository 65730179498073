import { Grid } from "@material-ui/core";
import {
    AppBackButtonWithBackwardArrow,
    AppPrimaryButtonWithForwardIcon,
    AppProfileUploader
} from "components";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
import { FacilitiesProfileModel } from "sdk";
export interface FacilitiesProfileHeaderProps {
    control: Control<FacilitiesProfileModel>;
    isEdit?: boolean;
    files?: string;
}
export const FacilitiesProfileHeader: React.FC<FacilitiesProfileHeaderProps> = ({
    control,
    isEdit,
    files,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const onEditClick = () => {
        history.push(appRoutesForContext().facilitiesProfile().facilitiesProfileEditPath);
    };
    const onBackClick = () => {
        history.push(appRoutesForContext().facilitiesProfile().facilitiesProfilePath);
    };
    return (
        <div style={{ width: "100%" }}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <AppProfileUploader
                        disabled={!isEdit}
                        control={control}
                        name="ProfilePicture"
                        files={files}
                    />
                </Grid>
                <Grid item>
                    {isEdit ? (
                        <AppBackButtonWithBackwardArrow
                            onClick={onBackClick}
                            style={{ minWidth: "138px" }}
                        />
                    ) : (
                        <AppPrimaryButtonWithForwardIcon
                            style={{ minWidth: "225px" }}
                            onClick={onEditClick}
                            text={t("profile.customizeData")}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default FacilitiesProfileHeader;
