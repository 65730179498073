import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppButton } from "components";
import React from "react";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            paddingRight: "25px",
            width: "100%",
        },
        image: {
            width: 30,
            height: 25,
            objectFit: "contain",
        },
    }),
);
export interface LoginWithOtherIconButtonProps {
    imageUrl: string;
    handleClick?: () => void;
}
export const LoginWithOtherIconButton: React.FC<LoginWithOtherIconButtonProps> =
    ({ imageUrl, handleClick }) => {
        const classes = useStyles();
        return (
            <>
                <AppButton
                    onClick={handleClick}
                    variant="contained"
                    customBackgroundColor="white"
                    customHoverBackgroundColor="white"
                    classes={{ root: classes.button }}
                    startIcon={
                        <img
                            src={imageUrl}
                            className={classes.image}
                            alt="google"
                        />
                    }
                />
            </>
        );
    };

export default LoginWithOtherIconButton;
