export interface StripeSubscribe {
    email: string;
    name: string;
    token?: string;
}

export const getStripeSubscribeInitialValue =
    (): StripeSubscribe => {
        return {
            email: "",
            name: "",
            token: "",
        };
    };
export interface ClientSecret {
    clientSecret: string;

}
export const getClientSecretInitialValue =
    (): ClientSecret => {
        return {
            clientSecret: ""

        };
    };
