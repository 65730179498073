import { AppButton, AppButtonProps } from "components";
import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
export const AppPrimaryButtonWithForwardIcon = React.memo<AppButtonProps>(
    (props) => {
        return (
            <AppButton
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIcon />}
                {...props}
            />
        );
    },
);

export default AppPrimaryButtonWithForwardIcon;
