import { StandardProps } from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper, { StepperProps } from "@material-ui/core/Stepper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AppStepperConnector } from "components/Stepper/Partials";
import AppStepperIcon from "components/Stepper/Partials/AppStepperIcon";
import { StyleClassKey } from "globals";
import React from "react";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            width: "100%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        footerButtons: {
            display: "flex",
            justifyContent: "flex-end",
        },
        backButton: {
            border: "0px red",
        },
    });
type AppStepperClassKey = StyleClassKey<typeof styles>;
export interface AppStepperProps
    extends StandardProps<Omit<StepperProps, "children">, AppStepperClassKey> {
    components: React.ReactNode[];
    showResetButton?: boolean;
    activeStep: number;
}
const useStyles = makeStyles(styles);
export const AppStepper = React.memo<AppStepperProps>(
    ({ components, showResetButton, activeStep, ...rest }) => {
        const classes = useStyles(rest);
        const steps = components;

        return (
            <div className={classes.root}>
                <Stepper
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<AppStepperConnector />}
                    {...rest}>
                    {steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel
                                StepIconProps={{
                                    id: index.toString(),
                                }}
                                StepIconComponent={AppStepperIcon}></StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <div className={classes.instructions}>
                    {components[activeStep]}
                </div>
            </div>
        );
    },
);
export default AppStepper;
