import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { AppDialog } from "components/Dialog";
import AppStepper from "components/Stepper/AppStepper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MultiStepRegistrationData } from "screens";
import Step1 from "screens/RegistrationForChild/Partials/RegistrationForChildStep1";
import {
    getRegistrationForChildrenInitialValue, RegistrationForChildren, RegistrationForChildrenStep1
} from "sdk";
import { BG_GRADIENT } from "theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100vh",
            width: "100%",
            overflow: "auto",
            background: BG_GRADIENT,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
        stepperContainer: {
            marginTop: theme.spacing(2),
            paddingRight: theme.spacing(10),
            paddingLeft: theme.spacing(10),
            paddingBottom: theme.spacing(10),
            display: "flex",
            justifyContent: "center",
            width: "100%",
        },
        stepperBody: {
            width: "100%",
            maxWidth: "1200px",
        },
        container: {
            width: "100%",
           // marginTop: theme.spacing(5),
           // marginBottom: theme.spacing(5),
        },
        stepperLabel: {
            width: "100%",
            maxWidth: "500px",
        },
    }),
);
export interface RegistrationForChildDialogProps {
    open: boolean;
    onClose: () => void;
    handleFormCompleted: (data: RegistrationForChildren) => void;
    handleStateSet: (value: MultiStepRegistrationData) => void;
    parentState: MultiStepRegistrationData;
}
export const RegistrationForChildDialog: React.FC<RegistrationForChildDialogProps> =
    ({ onClose, open, parentState, handleFormCompleted, handleStateSet }) => {
        const classes = useStyles();
        const { t } = useTranslation();

        const [state, setState] = useState<RegistrationForChildren>(
            getRegistrationForChildrenInitialValue(),
        );

        useEffect(() => {
            handleStateSet({
                ...parentState,
                childrensInfo: state
            });
        }, [state]);

        const onStateSetHandler = (value: RegistrationForChildren) => {
            setState(value);
        };

        const [activeStep, setActiveStep] = React.useState(0);

        const handleNext = () => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        };

        const handleBack = () => {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        };

        const steps: React.ReactNode[] = [
            <Step1
                handleNext={handleNext}
                activeStep={activeStep}
                handleBack={handleBack}
                stepsLength={1}
                handleStateSet={onStateSetHandler}
                parentState={state}
                handleFormCompleted={handleFormCompleted}
            />,
        ];
        const renderSubtitle = (): string => {
            if (activeStep === 0) {
                return t("registrationForChildren.step1.title");
            }
            return t("registrationForChildren.step2.title");
        };
        return (
            <AppDialog
                open={open}
                className={classes.root}
                handleClose={onClose}
                scroll="body"
                classes={{
                    root: classes.root,
                    container: classes.container,
                }}>
                <Grid
                    container
                    justifyContent="center"
                    direction="column"
                    alignItems="center">
                    <Grid item className={classes.title}>
                        <Typography variant="h4">
                            {t("registrationForChildren.title")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" color="textSecondary">
                            {renderSubtitle()}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.stepperContainer}>
                    <div className={classes.stepperBody}>
                        <AppStepper
                            components={steps}
                            className={classes.stepperLabel}
                            activeStep={activeStep}
                        />
                    </div>
                </div>
            </AppDialog>
        );
    };

export default RegistrationForChildDialog;
