import config from "../config";
import { RoutingContext } from "../globals";

export class BaseRoutingContext implements RoutingContext {
    getContextIdentifier(): string {
        return "base-context";
    }
    isForApi = false;
    constructor(isForApi: boolean) {
        this.isForApi = isForApi;
    }

    buildUrl(url: string, params: { [index: string]: string } = {}) {
        return Object.keys(params).reduce(
            (url: string, param: string) => {
                return url.replace(param, params[param]);
            },
            this.isForApi ? this.getApiBaseUrl() : this.getAppBaseUrl() + url,
        );
    }

    getApiBaseUrl() {
        return config.apiBaseUrl ? config.apiBaseUrl : "";
    }
    getAppBaseUrl() {
        return config.apiBaseUrl ? config.apiBaseUrl : "";
    }
}
