import { AppControlledDatePicker, AppTextField } from "components";
import { AppSelect } from "components/Inputs/ComboBox";
import { AppControlProps, SimpleOption } from "globals";
import React from "react";

export interface ProfileFieldsRenderingProps extends AppControlProps {
    name: string;
    fieldType: "text" | "select" | "date";
    options?: SimpleOption[];
}
const ProfileFieldsRendering: React.FC<ProfileFieldsRenderingProps> = ({
    fieldType,
    options = [],
    ...rest
}) => {
    return (
        <>
            {fieldType === "text" && <AppTextField {...rest} />}
            {fieldType === "select" && (
                <AppSelect
                    getOptionLabel={(option) => option.label as string}
                    {...rest}
                    options={options}
                />
            )}
            {fieldType === "date" && (
                <AppControlledDatePicker fullWidth={true} {...rest} />
            )}
        </>
    );
};

export default ProfileFieldsRendering;
