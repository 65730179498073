import { createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { ReactComponent as SearchIcon } from "assets/images/icons/magnifier-white.svg";
import { AppButton, AppTable } from "components";
import { AppSelectFacilities } from "components/Inputs/ComboBox";
import { handleRowExpand } from "globals/general-helper";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import { AllKindergartensModal, AllKindergartensRequest, getAllKindergartensRequestInitialValue, getMultiStepRegistrationFacilitiesInitialValue, KindergartenStatus, MultiStepRegistrationFacilities, SearchByFacilitiesName, UpdateKindergartens } from "sdk";
import { FacilitiesService } from "services/FacilitiesService";
import { SuperAdminService } from "services/SuperAdminService";
import { COLOR_BOX_SHADOW, COLOR_LIGTHEST_BLUE, COLOR_PRIMARY, COLOR_WHITE, FULL_VIEW_HEIGHT_WITHOUT_HEADER } from "theme";
import { AllKindergartensColumns, AllKindergartensExpendedRow } from "./Partials";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {

			minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
			overflow: "auto",
			background: `linear-gradient(180deg, ${COLOR_WHITE} 10%, ${COLOR_LIGTHEST_BLUE} 100%)`,
			display: "flex",
			flexDirection: "column",
			paddingTop: theme.spacing(8),
			paddingBottom: theme.spacing(10),

			alignItems: "center",
			paddingRight: theme.spacing(25),
			paddingLeft: theme.spacing(25),
			[theme.breakpoints.down("lg")]: {
				paddingRight: theme.spacing(15),
				paddingLeft: theme.spacing(15),
			},
		},
		title: {
			//marginBottom: theme.spacing(5),
		},
		rootSearch: {
			borderRadius: "10px",
			background: COLOR_WHITE,
			maxWidth: "1000px",
			width: "50%",
			boxShadow: `6px 6px 15px 1px ${COLOR_BOX_SHADOW}`,
			marginBottom: 60,
			marginTop: 20,
		},
		bodySearch: {
			display: "flex",
			width: "100%",
		},

		button: {
			borderRadius: "0px 10px 10px 0px / 0px 10px 10px 10px",
			height: "56px",
			width: "100%",
		},
		icon: {
			objectFit: "contain",
			width: 20,
			height: 20
		},
		divWhite: {
			backgroungColor: "white",
			color: "black",
		},
		divGreen: {
			backgroundColor: "#008000",
			color: "white",

		},
		divBlue: {
			backgroundColor: "#007fff",
			color: "white",
		},
		divPink: {
			backgroundColor: "#d71868",
			color: "white",

		},

	}),
);

const conditionalRowStyles = [
	{
		when: (row: AllKindergartensModal) => row.RowExpandedToggle === true,
		style: {
			backgroundColor: COLOR_WHITE,
			color: COLOR_PRIMARY,
			boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
			borderRadius: "10px",
		},
	},
];

const service = new SuperAdminService();
const serviceFacilities = new FacilitiesService();

export const AllKindergartens = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	let history = useHistory();

	const [state, setState] = useState<AllKindergartensModal[]>([]);
	const [search, setSearch] = useState<string>("");
	const [searchInput, setSearchInput] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false);
	const [totalRows, setTotalRows] = useState(10000);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const { control, handleSubmit } = useForm<SearchByFacilitiesName>({
		defaultValues: { search: "" },
	});

	const [selectedStatus, setSelectedStatus] = useState<number>("" as unknown as number);
	const [div1, setDiv1] = useState(classes.divWhite);
	const [div2, setDiv2] = useState(classes.divWhite);
	const [div3, setDiv3] = useState(classes.divWhite);

	const fetchUsers = async (page: any, search: any, status: any) => {
		setLoading(true);
		//	const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`);
		let kindergartens: AllKindergartensRequest = getAllKindergartensRequestInitialValue();
		kindergartens.page = page;
		kindergartens.perPage = perPage;
		kindergartens.search = search;
		kindergartens.kindergartenStatus = status;

		setSearch(search);
		service.getAllKindergartens(kindergartens)
			.then(function (response) {
				setTotalRows(response.data.totalFilteredCount);
				console.log(response);
				const u: AllKindergartensModal[] = [];
				response.data.data.forEach((element: any) => {
					let allKindergartens: AllKindergartensModal = {
						kindergartenName: element.name,
						address: element.address,
						Id: element.kindergartenId,
						phone: element.phone,
						email: element.email,
						firstname: element.firstname,
						lastname: element.lastname,
						longitude: element.longitude,
						latitude: element.latitude,
						postalCode: element.postalCode,
						city: element.city,
						companyID: element.companyID,
						//registerPerson: element.registerPerson,
						country: element.country,
						requestsCount: element.requestsCount,
						kindergartenStatus: (element.kindergartenStatus === 1 || element.kindergartenStatus === null) ? KindergartenStatus.REVIEWED : KindergartenStatus.PENDING,
						kindergartenId: element.kindergartenId,
						isRegistered: element.isRegistered === true ? t("superAdmin.column.status.yes") : t("superAdmin.column.status.no"),
					};
					u.push(allKindergartens);
				});
				setState(u);
			})
			.catch(function (error) {
				console.log("API ERROR");
				console.log(error);
			})
		setLoading(false);
		setPaginationResetDefaultPage(false);

	};
	useEffect(() => {
		setDiv1(classes.divGreen);
		fetchUsers(page, "", selectedStatus); // fetch page 1 of users
	}, []);

	const handlePageChange = (pageNew: any) => {
		fetchUsers(pageNew, search, selectedStatus);
	};

	const handlePerRowsChange = async (newPerPage: any, page: any) => {
		setLoading(true);

		let kindergartens: AllKindergartensRequest = getAllKindergartensRequestInitialValue();
		kindergartens.page = page;
		kindergartens.perPage = newPerPage;
		kindergartens.search = search;
		kindergartens.kindergartenStatus = selectedStatus
		service.getAllKindergartens(kindergartens)
			.then(function (response) {
				console.log("API");
				console.log(response);
				const u: AllKindergartensModal[] = [];
				response.data.data.forEach((element: any) => {
					let allKindergartens: AllKindergartensModal = {
						kindergartenName: element.name,
						address: element.address,
						Id: element.kindergartenId,
						phone: element.phone,
						email: element.email,
						firstname: element.firstname,
						lastname: element.lastname,
						longitude: element.longitude,
						latitude: element.latitude,
						postalCode: element.postalCode,
						city: element.city,
						companyID: element.companyID,
						//registerPerson: element.registerPerson,
						country: element.country,
						requestsCount: element.requestsCount,
						kindergartenStatus: (element.kindergartenStatus === 1 || element.kindergartenStatus === null) ? KindergartenStatus.REVIEWED : KindergartenStatus.PENDING,
						kindergartenId: element.kindergartenId,
						isRegistered: element.isRegistered === true ? t("superAdmin.column.status.yes") : t("superAdmin.column.status.no"),
					};
					u.push(allKindergartens);
				});
				setState(u);
			})
			.catch(function (error) {
				console.log("API ERROR");
				console.log(error);
			})
		setPerPage(newPerPage);
		setLoading(false);
	};

	//////////////////////////
	const onClick1 = () => {
		setDiv1(classes.divGreen);
		setDiv2(classes.divWhite);
		setDiv3(classes.divWhite);
		setSelectedStatus("" as unknown as number);
		setPaginationResetDefaultPage(true);
	}

	const onClick2 = () => {
		setDiv2(classes.divGreen);
		setDiv1(classes.divWhite);
		setDiv3(classes.divWhite);
		setSelectedStatus(1);
		setPaginationResetDefaultPage(true);
	}

	const onClick3 = () => {
		setDiv3(classes.divGreen);
		setDiv2(classes.divWhite);
		setDiv1(classes.divWhite);
		setSelectedStatus(0);
		setPaginationResetDefaultPage(true);
	}

	useEffect(() => {
		if (selectedStatus === "" as unknown as number) {
			fetchUsers(page, search, selectedStatus);
		} else if (selectedStatus === 0) {
			fetchUsers(page, search, selectedStatus);
		} else if (selectedStatus === 1) {
			fetchUsers(page, search, selectedStatus);
		}
	}, [selectedStatus]);

	////////////////Enter Search
	const submit = handleSubmit(() => {
		fetchUsers(1, searchInput, selectedStatus);
		setSearch(searchInput);
		setPaginationResetDefaultPage(true);
	});

	const onKeyDown = (event: any) => {
		if (event.key === "Enter") {
			fetchUsers(1, searchInput, selectedStatus);
			setSearch(searchInput);
			setPaginationResetDefaultPage(true);
		}
	}

	const onInputChange = (event: any) => {
		setSearchInput(event.target.value);
	}

	useEffect(() => {
		if (searchInput === undefined) {
			setSearchInput("");
		}
	}, [searchInput]);

	////////////////
	const onRowExpandClick = (id: number) => {
		console.log(id);
		setState([...handleRowExpand(id, state)]);
	};

	const onSubmit = (value: AllKindergartensModal) => {
		let updateRequest: UpdateKindergartens = {
			kindergartenName: value.kindergartenName,
			address: value.address,
			kindergartenId: value.Id as number,
			phone: value.phone,
			firstname: value.firstname,
			lastname: value.lastname,
			longitude: value.longitude,
			latitude: value.latitude,
			postalCode: value.postalCode,
			city: value.city,
			companyID: value.companyID,
			registerPerson: null,
			country: value.country,
		}

		service.updateKindergartens(updateRequest)
			.then(function (response) {
				console.log(response);
				setState([
					...state.map((x) => {
						if (x.Id === value.Id) {
							return { ...value };
						}
						return x;
					}),
				]);
			})
			.catch(function (error) {
				console.log("API ERROR put:");
				console.log(error);
			})
	};
	/////////////////////// Style Pagination
	const paginationComponentOptions = {
		rowsPerPageText: t("superAdmin.kindergartenList.rowsPerPage"),
		rangeSeparatorText: t("superAdmin.kindergartenList.of"),
		selectAllRowsItem: false,
		//selectAllRowsItemText: t("superAdmin.kindergartenList.end"),		
	};

	///////////Change status
	const changeKindergartenStatus = (input: KindergartenStatus, data: AllKindergartensModal) => {
		let registrationData: MultiStepRegistrationFacilities = getMultiStepRegistrationFacilitiesInitialValue();
		registrationData.IdFacilities = data.kindergartenId;
		registrationData.FullName = data.firstname + " " + data.lastname;
		registrationData.Email = data.email;
		registrationData.PhoneNumber = data.phone;

		serviceFacilities.registerFacilities(registrationData)
			.then(function (response) {
				console.log("API RESPONSE:");
				console.log(response);
				let newArr: AllKindergartensModal[] = [];
				state.forEach((x) => {
					if (x.kindergartenId === data.kindergartenId) {
						x.kindergartenStatus = input;
					}
					newArr.push(x);
				});
				setState(newArr);
			})
			.catch(function (error) {
				console.log("API ERROR:");
				console.log(error);
			})
	}

	///////////////addNewKindergarten
	const location = useLocation();

	const addNewKindergarten = () => {
		history.replace(
			appRoutesForContext()
				.facilitiesProfile()
				.addNewFacilitiesView
				.setAddNewFacilitiesViewPathParams(location.pathname)
		);
	}
	/////////////////////////delete
	const onDeleteItem = (id: number) => {
		service.deleteKindergarten(id)
			.then(function (response) {
				setState([
					...state.filter((x) => {
						return x.Id !== id
					}),
				]);
			})
			.catch(function (error) {
				console.log("API ERROR:");
				console.log(error);
			})
	};

	return (
		<div className={classes.root}>
			<Typography className={classes.title} variant="h4">
				{t("superAdmin.kindergartenList.title1")}
			</Typography>
			<Grid
				style={{ marginBottom: 10, textAlign: "end", justifyContent: "end", justifyItems: "end" }}
				container
				alignItems="flex-end"
				justifyContent="space-between">
				<Grid item>
					<AppButton
						text={t("superAdmin.button.addKindergarten")}
						variant="outlined"
						color="primary"
						onClick={addNewKindergarten}
					/>
				</Grid>
			</Grid>
			<div style={{ height: 45, width: "1200px", margin: 20, textAlign: "center", justifyContent: "center", justifyItems: "center" }}>
				<div style={{ borderRight: "grey dashed", height: 25, width: 150, display: "inline-block", margin: "auto" }}>
					<div className={div1} onClick={() => { onClick1() }} style={{ cursor: "pointer", height: 20, width: 40, margin: "auto", fontWeight: "bold" }}>
						{t("superAdmin.column.kindergartenRequest.all")}
					</div>
				</div>
				<div style={{ borderRight: "grey dashed", height: 25, width: 150, display: "inline-block" }}>
					<div className={div2} onClick={onClick2} style={{ cursor: "pointer", height: 20, width: 100, margin: "auto", fontWeight: "bold" }}>
						{t("superAdmin.column.kindergartenRequest.Reviewed")}
					</div>
				</div>
				<div style={{ height: 25, width: 150, display: "inline-block", margin: "auto" }}>
					<div className={div3} onClick={onClick3} style={{ cursor: "pointer", height: 20, width: 100, margin: "auto", fontWeight: "bold" }}>
						{t("superAdmin.column.kindergartenRequest.Pending")}
					</div>
				</div>
			</div>
			<div className={classes.rootSearch}>
				<form noValidate onSubmit={submit}>
					<div className={classes.bodySearch}>
						<div style={{ flexGrow: 9 }}>
							<AppSelectFacilities
								onKeyDown={onKeyDown}
								onInputChange={onInputChange}
								control={control}
								name="search"
								freeSolo={true}
								showerrorsintooltip={"true"}
								tooltipprops={{ arrow: true }}
								textfieldprops={{
									customBorderRadius: "10px 0px 0px 10px / 10px 0px 0px 10px",
									removeShadow: true,
									placeholder: t("superAdmin.search.input"),

								}}
								options={[]}
							/>
						</div>

						<div style={{ flexGrow: 1 }}>
							<AppButton
								type="submit"
								text={t("superAdmin.search.button")}
								variant="contained"
								color="primary"
								style={{ minWidth: "100px" }}
								classes={{ root: classes.button }}
								startIcon={
									<SearchIcon className={classes.icon} />
								}
							/>
						</div>
					</div>
				</form>
			</div>
			<AppTable
				progressPending={loading}
				pagination
				paginationServer
				paginationTotalRows={totalRows}
				paginationResetDefaultPage={paginationResetDefaultPage}
				onChangeRowsPerPage={handlePerRowsChange}
				pointerOnHover={true}
				onChangePage={handlePageChange}
				paginationComponentOptions={paginationComponentOptions}
				columns={AllKindergartensColumns(t, onRowExpandClick, onDeleteItem, changeKindergartenStatus)}
				data={state}
				paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
				///////////////////////////
				expandableRows
				expandableRowExpanded={(row) => row.RowExpandedToggle === true}
				conditionalRowStyles={conditionalRowStyles}
				expandOnRowClicked
				onRowExpandToggled={(
					expanded: boolean,
					row: AllKindergartensModal,
				) => onRowExpandClick(row.Id as number)}
				expandableRowsComponent={(t) => (
					<AllKindergartensExpendedRow
						data={t.data}
						onSubmit={onSubmit}
						requests={() => {
							history.push({
								pathname: "/kindergartensRequests/redirectPath=-allKindergartens",
								state: t.data.kindergartenId,
							});
						}}
					/>
				)}
			/>

		</div>

	);
}
