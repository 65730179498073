import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { ReactComponent as CameraIcon } from "assets/images/icons/camera-white.svg";
import { AppButton } from "components";
import { AppControlProps } from "globals";
import { COLOR_DARK_BLUE } from "theme";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& > *": {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: "none",
        },
        image: {
            width: 25,
            height: 30,
            marginRight: theme.spacing(0.5),
        },
        label: {
            display: "flex",
            alignItems: "center",
            marginLeft: theme.spacing(4),
        },
        cameraIcon: {
            objectFit: "contain",
            width: "15px",
            height: "15px",
            marginRight: "2px",
        },
        avatar: { width: "100px", height: "100px", objectFit: "contain" },
        uploadButton: {
            width: "35px",
            minWidth: "35px",
            height: "35px",
            borderRadius: "8px",
            marginBottom: "5px",
            marginLeft: "10px",
            display: "grid",
        },
    }),
);

export interface AppProfileUploaderProps
    extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
    >,
    AppControlProps {
    files?: string;
    onChangeFiles?: (files: File[]) => void;
}
export const AppProfileUploader = React.memo<AppProfileUploaderProps>(
    ({
        control,
        rules,
        name,
        shouldUnregister,
        files,
        onChangeFiles,
        disabled,
        ...rest
    }) => {
        const classes = useStyles();

        const fileRef = React.useRef<any>(null);
        return (
            <div className={classes.root}>
                <Controller
                    name={name ? name : ""}
                    rules={rules}
                    shouldUnregister={shouldUnregister}
                    control={control}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => {
                        if (onChangeFiles && value && value.length > 0) {
                            onChangeFiles(value);
                        }
                        console.log(value);
                        console.log(files);
                        const fileUrl =
                            value
                                ? URL.createObjectURL(value[0])
                                : files
                                    ? files
                                    : undefined;
                        return (
                            <>
                                <input
                                    className={classes.input}
                                    {...rest}
                                    type="file"
                                    ref={fileRef}
                                    onChange={(e) =>
                                        onChange(
                                            e &&
                                            e.target &&
                                            e.target.files &&
                                            e.target.files,
                                        )
                                    }
                                />
                                <Badge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    badgeContent={
                                        !disabled && (
                                            <AppButton
                                                customBackgroundColor={
                                                    COLOR_DARK_BLUE
                                                }
                                                onClick={() =>
                                                    fileRef &&
                                                    fileRef.current &&
                                                    fileRef.current.click &&
                                                    fileRef.current.click()
                                                }
                                                variant="contained"
                                                aria-label="delete"
                                                className={classes.uploadButton}
                                                color="primary">
                                                <CameraIcon
                                                    className={
                                                        classes.cameraIcon
                                                    }
                                                />
                                            </AppButton>
                                        )
                                    }>
                                    <Avatar
                                        alt="Travis Howard"
                                        className={classes.avatar}
                                        src={fileUrl}
                                    />
                                </Badge>
                            </>
                        );
                    }}></Controller>
            </div>
        );
    },
);
export default AppProfileUploader;
