import { StandardProps } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Tab, { TabProps } from "@material-ui/core/Tab";
import Tabs, { TabsProps } from "@material-ui/core/Tabs";
import TabPanel from "./Partials/TabPanel";
import { StyleClassKey } from "globals";
import React from "react";
import SwipeAbleViews from "react-swipeable-views";

export interface AppTab extends TabProps {
    children: React.ReactNode;
    value: string;
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}
const styles = () =>
    createStyles({
        root: { width: "100%" },
        appHeader: {
            boxShadow: "0px 0px",
        },
    });

type AppTabsClassKey = StyleClassKey<typeof styles>;

const useStyles = makeStyles(styles);
export interface AppTabsProps
    extends StandardProps<TabsProps, AppTabsClassKey> {
    selectedTab: string;
    tabs: AppTab[];
    onChange: (tab: any) => void;
}

export const AppTabs = React.memo<AppTabsProps>(
    ({ selectedTab, tabs, onChange, ...rest }) => {
        const classes = useStyles();
        const theme = useTheme();

        const handleChange = (
            event: React.ChangeEvent<{}>,
            newValue: number,
        ) => {
            onChange(tabs[newValue].value);
        };
        const handleChangeIndex = (index: number) => {
            onChange(tabs[index].value);
        };

        return (
            <div className={classes.root}>
                <AppBar
                    position="static"
                    color="transparent"
                    className={classes.appHeader}>
                    <Tabs
                        centered
                        style={{ textTransform: "none" }}
                        TabIndicatorProps={{
                            style: {
                                background: theme.palette.secondary.light,
                                height: "3px",
                            },
                        }}
                        value={tabs.findIndex((x) => x.value === selectedTab)}
                        onChange={handleChange}
                        aria-label="full width tabs example"
                        {...rest}>
                        {tabs.map(({ children, value, ...rest }, index) => (
                            <Tab
                                key={index}
                                style={{ textTransform: "none" }}
                                {...a11yProps(index)}
                                {...rest}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <SwipeAbleViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={tabs.findIndex((x) => x.value === selectedTab)}
                    onChangeIndex={handleChangeIndex}>
                    {tabs.map((tab, index) => (
                        <TabPanel
                            key={index}
                            index={index}
                            value={tabs.findIndex(
                                (x) => x.value === selectedTab,
                            )}>
                            {tab.children}
                        </TabPanel>
                    ))}
                </SwipeAbleViews>
            </div>
        );
    },
);
export default AppTabs;
