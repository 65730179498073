import axios from "axios";
import { apiRoutesForContext } from "routing/react-api-route-paths";
import {
    Login,
    PasswordForgotten,
    Register,
    SocialLogin, User
} from "sdk";
import { EmailComfirmationURL, ResetPassword, StripeSubscribe } from 'sdk/models';
import { BaseService } from "services/BaseService";

export interface IAuthService {
    login: (data: Login) => Promise<User>;
    register: (data: Register) => Promise<User>;
}

const tmp = apiRoutesForContext();
export class AuthService extends BaseService implements IAuthService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }

    login(data: Login): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.auth().login(),
            data: data
        })
    }

    register(data: Register): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.auth().register(),
            data: data
        })
    }

    multiStepRegistration(data: FormData): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "multipart/form-data" },
            url: this.routes.auth().multiStepRegistration(),
            data: data
        })
    }

    socialLogin(data: SocialLogin): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.auth().socialLogin(),
            data: data
        })
    }

    passwordForgotten(data: PasswordForgotten): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.auth().passwordForgotten(),
            data: data
        })
    }
    resetPassword(data: ResetPassword): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.auth().resetPassword(),
            data: data
        })
    }

    getEmailComfirmationURL(data: EmailComfirmationURL): Promise<any> {
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.auth().getEmailComfirmationUrl() + "?userId=" + data.userId + "&code=" + data.code,
        })
    }

    stripeSubscribe(data: StripeSubscribe): Promise<any> {
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json" },
            url: this.routes.auth().stripeSubscribe(),
            data: data
        })
    }
}
