import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    AuthRoutesList,
    ErrorComponent,
    MapSearchRoutesList,
    ProfileRoutesList,
    PublicRoutesList,
    RegistrationListRoutesList,
    FacilitiesRouteList,
    SuperAdminRouteList,
} from "screens";
import { RouteInterface } from "../globals";
import { ApplicationRoute } from "./ApplicationRoute";

const routesList: RouteInterface[] = [
    ...PublicRoutesList,
    ...AuthRoutesList,
    ...MapSearchRoutesList,
    ...RegistrationListRoutesList,
    ...ProfileRoutesList,
    ...FacilitiesRouteList,
    ...SuperAdminRouteList,

];
export const ReactRouter = () => {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => {
                        return <Redirect to="/home" />;
                    }}
                />

                {routesList.map((route) => (
                    <ApplicationRoute
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                        layout={route.layout}
                        key={route.path}
                        uniqueKey={route.uniqueKey}
                    />
                ))}

                <Route path="*">{<ErrorComponent />}</Route>
            </Switch>
        </>
    );
};

export default ReactRouter;
