import MomentUtils from "@date-io/moment";
import {
    createStyles,
    makeStyles,
    StandardProps,
    withStyles,
} from "@material-ui/core";
import {
    DatePicker,
    DatePickerProps,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { ReactComponent as CalenderIcon } from "assets/images/icons/calendar.svg";
import { AppControlProps, Locale, StyleClassKey } from "globals";
import {
    COLOR_BOX_SHADOW,
    COLOR_PRIMARY,
    COLOR_WHITE,
    COLOR_GRAY,
} from "theme";
import { GERMAN_DATE_FORMAT, US_DATE_FORMAT } from "globals/constants";
import moment from "moment";
import "moment/locale/de";
import React from "react";
import { Controller } from "react-hook-form";
import { useUserContext } from "providers";

const styles = () =>
    createStyles({
        imgRoot: {
            width: "1.5rem",
            cursor: "pointer",
            objectFit: "contain",
            height: "2rem",
        },
        root: {
            display: "flex",
            alignItems: "center",
            width: "100%",
        },
        label: {
            minWidth: "180px",
        },
        textFieldContainer: {
            width: "100%",
        },
    });

type AppControlledDatePickerClassKey = StyleClassKey<typeof styles>;

const useStyles = makeStyles(styles);

export interface AppControlledDatePickerProps
    extends StandardProps<
        Omit<DatePickerProps, "value" | "onChange"> & AppControlProps,
        AppControlledDatePickerClassKey
    > {
    locale?: Locale;
    removeShadow?: boolean;
    name: string;
}
export const AppControlledDatePicker = React.memo<AppControlledDatePickerProps>(
    ({
        removeShadow,
        locale,
        control,
        rules,
        name,
        shouldUnregister,
        ...rest
    }) => {
        const { lng } = useUserContext();
        moment.locale(lng); // it is required to select default locale manually
        const classes = useStyles(rest);
        const CssTextField = withStyles({
            root: {
                "& label.Mui-focused": {
                    color: COLOR_PRIMARY,
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: COLOR_PRIMARY,
                },
                "& .MuiOutlinedInput-adornedEnd": {
                    borderBottomColor: COLOR_WHITE,
                },
                "& .MuiOutlinedInput-adornedStart": {
                    borderBottomColor: COLOR_WHITE,
                },
                "& .MuiInputBase-input": {
                    background: COLOR_WHITE,
                    borderRadius: "8px",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    color: COLOR_GRAY,
                },
                "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    background: COLOR_WHITE,
                    "& fieldset": {
                        borderColor: "transparent",
                        boxShadow: removeShadow
                            ? "none"
                            : `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
                        borderRadius: "10px",
                    },
                    "&:hover fieldset": {
                        borderColor: COLOR_PRIMARY,
                    },
                    "&.Mui-focused fieldset": {
                        borderColor: COLOR_PRIMARY,
                    },
                },
            },
        })(DatePicker);

        return (
            <div className={classes.root}>
                <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={locale ? locale : lng}>
                    <Controller
                        name={name ? name : ""}
                        control={control}
                        rules={rules}
                        defaultValue={moment()}
                        shouldUnregister={shouldUnregister}
                        render={({
                            field: { value, onChange, onBlur },
                            fieldState,
                        }) => {
                            return (
                                <CssTextField
                                    onBlur={onBlur}
                                    value={value}
                                    onChange={onChange}
                                   // disableToolbar
                                   // variant="inline"
                                   // autoOk
                                    format={
                                        lng === "de"
                                            ? GERMAN_DATE_FORMAT
                                            : US_DATE_FORMAT
                                    }
                                    inputVariant="outlined"
                                    error={fieldState.invalid}
                                    helperText={
                                        fieldState.error &&
                                        fieldState.error.message
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <CalenderIcon
                                                className={classes.imgRoot}
                                            />
                                        ),
                                    }}
                                    {...rest}
                                />
                            );
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>
        );
    },
);

export default AppControlledDatePicker;
