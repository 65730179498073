export const ProfileDeTranslations = {
    profile: {
        title: "Persönliche Angaben",
        customizeData: "Angaben anpassen",
        personal: "Persönliche",
        information: "Informationen",
        firstName: {
            title: "Vollständige rechtliche Vorname und Mittelnamen",
            missing: "Vorname und zweiter Vorname fehlen",
        },
        land: {
            title: "Land",
            missing: "Land is missing",
        },
        lastName: {
            title: "Vollständiger rechtliche Nachname",
            missing: "Vollständiger rechtlicher Nachname fehlt",
        },
        address: {
            title: "Adresse",
            missing: "Adresse fehlt",
        },
        dateOfBirth: {
            title: "Geburtsdatum",
            missing: "Das Geburtsdatum fehlt",
        },
        city: {
            title: "Stadt",
            missing: "Die Stadt fehlt",
        },
        phoneNumber: {
            title: "Telefonnummer",
            missing: "Die Telefonnummer fehlt",
        },
        postalCode: {
            title: "Postleitzahl ",
            missing: "Die Postleitzahl fehlt",
        },
    },
    companyProfile: {
        title: "Company DataDE",
        company: "CompanyDE",
        location: "LocationDE",
        companyName: {
            title: "Company NameDE",
            missing: "Company name is missingDE",
        },
        email: {
            title: "EmailDE",
            missing: "Email is missingDE",
        },
    },
};
export default ProfileDeTranslations;
