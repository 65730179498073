import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HomeBg1 from "assets/images/bg/home-bg-1.png";
import HomeBg2 from "assets/images/bg/home-bg-2.png";
import HomeBg3 from "assets/images/bg/home-bg-3.png";
import { AppContainerWithBackground } from "components";
import { DASHBOARD_FOOTER_LEFT_RIGHT_PADDING, HEIGHT_OF_HEADER } from "theme";
import { AppFooter } from "layouts";
import React from "react";
import { HomeSection1, HomeSection2, HomeSection3 } from "./Partials";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: DASHBOARD_FOOTER_LEFT_RIGHT_PADDING,
        },
        bgImage: {},
    }),
);

export const Home = () => {
    const classes = useStyles();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isHd = useMediaQuery(theme.breakpoints.up("md"));
    const isFullHd = useMediaQuery(theme.breakpoints.up("lg"));
    const isQhd = useMediaQuery(theme.breakpoints.up("xl"));
    const is4k = useMediaQuery(theme.breakpoints.up("xll"));

    const homeSection2Height = is4k
        ? "1700px"
        : isQhd
        ? "1400px"
        : isFullHd
        ? "1300px"
        : isHd
        ? "1500px"
        : isMobile
        ? "1800px"
        : "1300px";

    const homeSection3Height = is4k
        ? "1350px"
        : isQhd
        ? "1200px"
        : isFullHd
        ? "900px"
        : "850px";
    return (
        <div>
            <AppContainerWithBackground
                bgUrl={HomeBg1}
                height={`calc(100vh - ${HEIGHT_OF_HEADER})`}>
                <div className={classes.root}>
                    <HomeSection1 />
                </div>
            </AppContainerWithBackground>
            <AppContainerWithBackground
                height={homeSection2Height}
                bgUrl={HomeBg2}
                classes={{ bgImage: classes.bgImage }}>
                <div className={classes.root}>
                    <HomeSection2 height={homeSection2Height} />
                </div>
            </AppContainerWithBackground>
            <AppContainerWithBackground
                bgUrl={HomeBg3}
                height={homeSection3Height}>
                <div className={classes.root}>
                    <HomeSection3 />
                </div>
            </AppContainerWithBackground>
            <AppFooter></AppFooter>
        </div>
    );
};

export default Home;
