export const SuperAdminDeTranslations = {
    superAdmin: {

        kindergartenList: {
            title1: "Kindergärten",
            title2: "Informationen über die Kindergärten",
            rowsPerPage: "Zeilen pro Seite",
            of: "von",
            end: "Ende",
            email: {
                title: "E-Mail Adresse",
                missing: "E-Mail Adresse fehlt",
                invalid: "E-Mail Adresse ist ungültig",
            },
            kindergartenId: {
                title: "Kindergarten Id",
                missing: "Kindergarten Id fehlt",
            },
            companyId: {
                title: "Firma Id",
                missing: "Die Firmen Id fehlt",
            },
            kindergartenName: {
                title: "Kindergarten Name",
                missing: "Kindergarten Name fehlt",
            },
            address: {
                title: "Adresse",
                missing: "Adresse fehlt",
            },
            phone: {
                title: "Telefon",
                missing: "Telefon fehlt",
            },
            registerPerson: {
                title: "Person registrieren",
                missing: "Registrieren Person fehlt",
            },
            firstName: {
                title: "Vorname",
                missing: "Vorname fehlt",
            },
            lastName: {
                title: "Nachname",
                missing: "Nachname fehlt",
            },
            country: {
                title: "Land",
                missing: "Land fehlt",
            },
            city: {
                title: "Stadt",
                missing: "Stadt fehlt",
            },
            postalCode: {
                title: "Postleitzahl",
                missing: "Postleitzahl fehlt",
            },
            longitude: {
                title: "Längengrad",
                missing: "Längengrad fehlt",
            },
            latitude: {
                title: "Breitengrad",
                missing: "Breitengrad fehlt",
            },

        },
        column: {
            kindergartenRequest: {
                Reviewed: "Überprüft",
                Pending: "Anhängig",
                all: "Alle",
            },
            requests: "Anfragen",
            registered: "Registriert",
            status: {
                status: "Status",
                yes: "Yes",
                no: "Nein",
            },
        },
        button: {
            addKindergarten: "Kindergarten hinzufügen",
        },
        search:{
            input: "Kindergarten oder Adresse",
            button: "Suche",
        },
    },
};
export default SuperAdminDeTranslations;
