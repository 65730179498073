import {
    createStyles,
    IconButton,
    InputAdornment,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { ReactComponent as EyeCrossIcon } from "assets/images/icons/eye-cross.svg";
import { ReactComponent as EyeIcon } from "assets/images/icons/eye.svg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppTextField, AppTextFieldProps } from "./AppTextField";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        passwordImage: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            cursor: "pointer",
        },
    }),
);

export interface AppPasswordTextFieldProps extends AppTextFieldProps {}
export const AppPasswordTextField: React.FC<AppPasswordTextFieldProps> = (
    props,
) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();
    return (
        <div style={{ position: "relative" }}>
            <AppTextField
                placeholder={t("auth.password")}
                {...props}
                type={visible ? "text" : "password"}
                endIcon={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setVisible(!visible)}>
                            {visible ? (
                                <EyeCrossIcon
                                    className={classes.passwordImage}
                                />
                            ) : (
                                <EyeIcon className={classes.passwordImage} />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </div>
    );
};

export default AppPasswordTextField;
