import {
    createStyles,
    List,
    ListItem,
    ListItemSecondaryAction,
    makeStyles,
    Theme,
} from "@material-ui/core";
import { ReactComponent as BabySquare } from "assets/images/icons/baby-square.svg";
import { AppCheckBox, AppTextWithIcon } from "components";
import { FULL_HEIGHT_OF_MAP_KINDERGARTEN_LIST } from "theme";
import React from "react";
import { KindergartenMapDataList } from "sdk/models";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: "auto",
            minHeight: FULL_HEIGHT_OF_MAP_KINDERGARTEN_LIST,
            maxHeight: FULL_HEIGHT_OF_MAP_KINDERGARTEN_LIST,
            height: FULL_HEIGHT_OF_MAP_KINDERGARTEN_LIST,
        },
        textContainer: {
            maxWidth: "16rem",
        },
        image: {
            width: "35px",
            height: "35px",
        },
        listItem: {
            paddingBottom: theme.spacing(3),
        },
        RightIcon: {
            right: theme.spacing(4),
        },
    }),
);

export interface KindergartensListProps {
    kindergartenList: KindergartenMapDataList[];
    onCheckBoxClickHandler: (
        value: KindergartenMapDataList,
        checked: boolean,
    ) => void;
}
export const KindergartensList: React.FC<KindergartensListProps> = ({
    kindergartenList,
    onCheckBoxClickHandler,
    
}) => {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            {kindergartenList.map((item, index) => (
                <ListItem
                    key={item.Id}
                    disableGutters
                    className={classes.listItem}>
                    <AppTextWithIcon
                        text={item.Name}
                        svg={<BabySquare className={classes.image} />}
                        classes={{
                            textContainer: classes.textContainer,
                        }}
                    />
                    <ListItemSecondaryAction
                        classes={{ root: classes.RightIcon }}>
                        <AppCheckBox
                            checked={item.IsSelected}
                            onChange={(value) => onCheckBoxClickHandler(
                                item,
                                value.target.checked,

                            )}
                            label={(index+1).toString()}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    );
};

export default KindergartensList;
