import {
    CircularProgress,
    createStyles,
    Grid,
    Theme,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import {
    AppContainerWithLineSeparation,
    AppPrimaryButtonWithForwardIcon,
} from "components";
import { BG_GRADIENT, FULL_VIEW_HEIGHT_WITHOUT_HEADER } from "theme";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    ProfileAddressInfo,
    ProfileHeader,
    ProfilePersonalInfo,
} from "./Partials";
import { DefaultProfiles, ProfileModel, ProfileUpdateRequest } from "sdk";
import VectorLine from "assets/images/vector-line-vertical.png";
import { useParams, useLocation, useHistory } from "react-router-dom";
import moment, { Moment } from "moment-timezone";
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import { ParentService } from "services/ParentService";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
            width: "100%",
            background: BG_GRADIENT,
            display: "flex",
            justifyContent: "center",
            paddingBottom: "50px",
        },
        body: {
            marginTop: theme.spacing(2),
            width: "100%",
            maxWidth: "1000px",
        },
        saveButton: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(5),
        },
        vectorImage: {
            backgroundRepeat: "repeat-y",
            marginTop: "60px",
            background: `url("${VectorLine}")`,
        },
        headerContainer: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(8),
        },
        spinner: {
            display: "flex",
            alignItems: "center",
            height: "100vh",
        },
    }),
);

const service = new ParentService();
interface ProfileDetailEditParams {
    id?: string;
}
export const Profile = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const { control, handleSubmit, reset } = useForm<ProfileModel>({
        defaultValues: {
            FirstName: "",
            LastName: "",
            Address: "",
            City: "",
            Country: "",
            DateOfBirth: moment().add(5, "days"),
            PhoneNumber: "",
            PostalCode: "",
        },
    });

    const { id } = useParams<ProfileDetailEditParams>();
    const location = useLocation();
    const isEdit = location.pathname.includes("edit");
    const [state, setState] = useState<{
        loading: boolean;
        profile: ProfileModel | undefined;
    }>({
        loading: true,
        profile: undefined,
    });

    useEffect(() => {
        service.getProfile()
            .then(function (response) {
                console.log(response);
                let userProfile: ProfileModel = {
                    FirstName: response.data.firstName,
                    LastName: response.data.lastName,
                    Country: response.data.country,
                    DateOfBirth: moment(response.data.dateOfBirth),
                    PostalCode: response.data.postalCode,
                    Address: response.data.address,
                    PhoneNumber: response.data.phoneNumber,
                    City: response.data.city,
                    ProfilePicture: response.data.imageUrl
                }
                const profile = userProfile;

                if (profile) {
                    reset({
                        ...profile,
                        ProfilePicture: state.profile?.ProfilePicture,
                    });
                    setState({
                        ...state,
                        profile: {
                            ...profile,
                            ProfilePicture:
                                profile.ProfilePicture &&
                                profile.ProfilePicture.length > 0
                                    ? response.data.imageUrl
                                    : state.profile?.ProfilePicture,
                        },
                        loading: false,
                    });
                }
                //setState({ profile: userProfile, loading: false });
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })
    }, []);

    const submit = handleSubmit((data) => {
        console.log(data);
        const updateRequest = new FormData();
        updateRequest.append('FirstName', data.FirstName);
        updateRequest.append("LastName", data.LastName);
        updateRequest.append("DateOfBirth", (data.DateOfBirth as Moment).format('YYYY-MM-DDTHH:mm:ss'));
        updateRequest.append("PostalCode", data.PostalCode);
        updateRequest.append("Country", data.Country);
        updateRequest.append("Address", data.Address);
        updateRequest.append("PhoneNumber", data.PhoneNumber);
        updateRequest.append("City", data.City);

        if(data.ProfilePicture) updateRequest.append("ImageFile", data.ProfilePicture[0]);

        console.log(updateRequest);
        service.updateProfile(updateRequest)
            .then(function (response) {
                setState({ profile: data as ProfileModel, loading: false });
                toast.success(t("common.successfullySaved"));
            history.push(
                appRoutesForContext()
                    .profile()
                    .setProfilePathParams(data.Id ? data.Id : 1),
        );
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })
    });
    return (
        <div className={classes.root}>
            {state.loading ? (
                <div className={classes.spinner}>
                    <CircularProgress />
                </div>
            ) : (
                <div className={classes.body}>
                    <form onSubmit={submit} noValidate={true}>
                        <Grid
                            container
                            alignItems="center"
                            direction="column"
                            className={classes.headerContainer}>
                            <Grid item>
                                <Typography variant="h4">
                                    {t("profile.title")}
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                                <ProfileHeader
                                    isEdit={isEdit}
                                    control={control}
                                    files={state.profile?.ProfilePicture}
                                />
                            </Grid>
                        </Grid>
                        <AppContainerWithLineSeparation
                            components={[
                                {
                                    component: (
                                        <ProfilePersonalInfo
                                            control={control}
                                            isEdit={isEdit}
                                            value={state.profile}
                                        />
                                    ),
                                },
                                {
                                    component: (
                                        <ProfileAddressInfo
                                            control={control}
                                            isEdit={isEdit}
                                            value={state.profile}
                                        />
                                    ),
                                },
                            ]}
                        />
                        <Grid container>
                            {isEdit && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.saveButton}>
                                    <AppPrimaryButtonWithForwardIcon
                                        type="submit"
                                        style={{ minWidth: "187px" }}
                                        text={t("common.save")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Profile;
