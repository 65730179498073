import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { RegisterDialog } from "./RegisterDialog";
export interface SignUpParams {
    redirectPath: string;
}

export const Register = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { redirectPath } = useParams<SignUpParams>();
    const handleClose = () => {
        setOpen(false);
        history.push(redirectPath.replaceAll("-", "/"));
    };
    return (
        <RegisterDialog
            open={open}
            handleClose={handleClose}
            redirectUrl={redirectPath}
        />
    );
};

export default Register;
