import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import RegisterBg from "assets/images/bg/login-sign-up-bg.png";
import {
    AppDialog,
    AppPrimaryButtonWithForwardIcon,
    AppTabs,
} from "components";
import { useUserContext } from "providers";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import { RegisterClient, RegisterDialogHeader } from "screens";
import { DefaultUsers, Register, RegistrationType } from "sdk";
import { AuthService } from "services/AuthService";

const service = new AuthService();

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(1),
            textAlign: "center",
        },
        dialogContainer: {},
        bodyContainer: {
            maxWidth: "800px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "1000px",
            },
        },
    }),
);

export interface RegisterDialogProps {
    open: boolean;
    handleClose: () => void;
    redirectUrl: string;
}
export const RegisterDialog: React.FC<RegisterDialogProps> = ({
    open,
    handleClose,
    redirectUrl,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const {lng, setLanguage } = useUserContext();

    const [state, setState] = useState(RegistrationType.CLIENT);
    const onTabChangeHandler = (tab: RegistrationType) => {
        setState(tab);
    };
    const { control, handleSubmit, watch } = useForm<Register>();
    
    let passwordWatcher = watch("Password", "");
    useEffect(() => {

    }, [passwordWatcher]);
    useEffect(() => {
        console.log("lng")

console.log(lng)
    }, []);
    const submit = handleSubmit((request) => {
        request.Role = RegistrationType.CLIENT;
        //request.ClientConfirmationEmailURI= "http://localhost:3000/registerSuccess/redirectPath=-home";
        request.ClientConfirmationEmailURI= "https://kitaplatz-demo.azurewebsites.net/registerSuccess/redirectPath=-home";
        request.langCode=lng;

        console.log(request);
        service.register(request)
            .then((data) => {
                console.log(data);
                history.push(
                    appRoutesForContext().auth().registerSuccessBeforeEmail.setRegisterSuccessBeforeEmailPathParams(redirectUrl),
                );
            }).catch();

    // const submit = handleSubmit((data) => {
    //     DefaultUsers.push({ ...data, Type: state });
    //     toast.success(t("auth.successfullyRegistered"));
    //     history.push(
    //         appRoutesForContext().auth().login.setLoginPathParams(redirectUrl),
    //     );
     });
    return (
        <div>
            {open && (
                <AppDialog
                    open={open}
                    handleClose={handleClose}
                    withBackgroundImage={true}
                    bgImageUrl={RegisterBg}
                    dialogHeaderRightChildren={
                        <RegisterDialogHeader redirectUrl={redirectUrl} />
                    }>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            height: "80vh",
                        }}>
                        <form noValidate={true} onSubmit={submit}>
                            <Grid
                                container
                                direction="column"
                                spacing={4}
                                justifyContent="center"
                                className={classes.bodyContainer}>
                                <Grid item className={classes.title}>
                                    <Typography
                                        variant="h4"
                                        color="textPrimary">
                                        {t("auth.signUpTitle")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <AppTabs
                                        selectedTab={state}
                                        onChange={(value) =>
                                            onTabChangeHandler(
                                                value as RegistrationType,
                                            )
                                        }
                                        tabs={[
                                            {
                                                children: (
                                                    <RegisterClient
                                                        control={control}
                                                        password={
                                                            passwordWatcher
                                                        }
                                                    />
                                                ),
                                                value: RegistrationType.CLIENT,
                                                label: (
                                                    <Typography variant="subtitle1">
                                                        {t(
                                                            `auth.registerTabs.${RegistrationType.CLIENT}`,
                                                        )}
                                                    </Typography>
                                                ),
                                            },
                                            // {
                                            //     children: (
                                            //         <RegisterClient
                                            //             control={control}
                                            //             password={
                                            //                 passwordWatcher
                                            //             }
                                            //         />
                                            //     ),
                                            //     value: RegistrationType.INSTITUTION_COMPANY,
                                            //     label: (
                                            //         <Typography variant="subtitle1">
                                            //             {t(
                                            //                 `auth.registerTabs.${RegistrationType.INSTITUTION_COMPANY}`,
                                            //             )}
                                            //         </Typography>
                                            //     ),
                                            // },
                                        ]}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}>
                                    <AppPrimaryButtonWithForwardIcon
                                        type="submit"
                                        text={t("auth.signUpButton")}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </AppDialog>
            )}
        </div>
    );
};

export default RegisterDialog;
