export interface SocialLogin {
    provider: string;
    token: string;
}

export const getSocialLoginInitialValue =
    (): SocialLogin => {
        return {
            provider: "",
            token: "",
        };
    };