import { CareHours, Gender } from "globals/Types";
import moment, { Moment } from "moment-timezone";

export interface MultiStepRegistration {
    kindergartens: number[],
    childrenData: ChildrenData[],
    message: string,
    //attachedFile: File[] | null,
    parentInfo: ParentInfo,
    email: string,
    password: string,
    confirmPassword: string
}

export interface AddChildsRequest {
    kindergartens: number[],
    childrenData: ChildrenData[],
    message: string,
}

export interface ChildrenData {
    childFirstname: string,
    childLastname: string,
    childGender: number,
    childBirthday: Moment,
    supportStart: Moment,
    careHour: number
}

export interface ParentInfo {
    firstname: string,
    lastname: string,
    dateOfBirth: Moment,
    phone: string,
    country: string,
    city: string,
    address: string,
    postalCode: number
}

export const getInitialValuesOfMultiStepRegistration =
    (): MultiStepRegistration => {
        return {
            kindergartens: [],
            childrenData: [getInitialValuesOfChildrenData()],
            message: "",
            //attachedFile: null,
            parentInfo: getInitialValuesOfParentInfo(),
            email: "",
            password: "",
            confirmPassword: "",
        };
    };

export const getInitialValuesOfAddChildsRequest =
    (): AddChildsRequest => {
        return {
            kindergartens: [],
            childrenData: [getInitialValuesOfChildrenData()],
            message: ""
        };
    };

export const getInitialValuesOfChildrenData = (): ChildrenData => {
    return {
        childFirstname: "",
        childLastname: "",
        childGender: 0,
        childBirthday: moment(),
        supportStart: moment(),
        careHour: 0
    };
};

export const getInitialValuesOfParentInfo =
    (): ParentInfo => {
        return {
            firstname: "",
            lastname: "",
            dateOfBirth: moment(),
            phone: "",
            country: "",
            city: "",
            address: "",
            postalCode: 0
        };
    };

export const findGender = (e: any) => {
    let gender = 0;
    if (Gender.FEMALE === e) {
        return gender = 2;
    } else {
        return gender = 1;
    }
}

export const findCareHour = (e: any) => {
    let careHours = 0
    if (CareHours.FOUR_TO_FIVE === e) {
        return careHours = 1;
    } else if (CareHours.FIVE_TO_SEVEN === e) {
        return careHours = 2;
    } else if (CareHours.SEVEN_T0_NINE === e) {
        return careHours = 3;
    } else {
        return careHours = 4;
    }
}