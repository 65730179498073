import { useTheme } from "@material-ui/core";
import {
    CustomTableLoadingOverlay,
    DefaultThemeForReactTable,
} from "./Partials";
import React from "react";
import DataTable, { TableProps } from "react-data-table-component";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
export interface AppTableProps<T> extends TableProps<T> {}
export function AppTable<T>(props: AppTableProps<T>) {
    const theme = useTheme();
    return (
        <DataTable
            customStyles={DefaultThemeForReactTable(theme)}
            expandableRowsHideExpander={true}
            progressComponent={<CustomTableLoadingOverlay />}
            sortIcon={
                <ArrowDropDownIcon
                    style={{
                        marginTop: "5px",
                    }}
                />
            }
            {...props}
        />
    );
}

export default AppTable;
