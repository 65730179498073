import {
    Grid,
    makeStyles,
    Theme,
    createStyles,
    Typography,
} from "@material-ui/core";
import { AppDetailsWithIcon, AppTitleWithMarker } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as PhoneCall } from "assets/images/icons/Phone-call.svg";
import { ReactComponent as PinLocation } from "assets/images/icons/pin-location.svg";
import { ReactComponent as ClockTime } from "assets/images/icons/clock-time.svg";
import { ReactComponent as Mail } from "assets/images/icons/mail.svg";
import { COLOR_TEXT_DARK } from "theme";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            marginBottom: theme.spacing(3),
        },
        icon: {
            width: "30px",
            height: "30px",
        },
        grayText: {
            fontWeight: 400,
        },
        fontWeight: { fontWeight: 900 },
        anchorTag: {
            textDecoration: "none",
            color: COLOR_TEXT_DARK,
        },
    }),
);
export const ContactsFirstContainer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                spacing={4}>
                <Grid item className={classes.title}>
                    <AppTitleWithMarker
                        firstTitle={t("contacts.ourContacts.title1")}
                        secondTitle={t("contacts.ourContacts.title2")}
                    />
                </Grid>
                <Grid item>
                    <AppDetailsWithIcon
                        iconUrl={<PinLocation className={classes.icon} />}
                        iconPosition="left"
                        justifyContent="flex-start"
                        gridSpacing={4}>
                        <Typography className={classes.fontWeight} variant="h6">
                            {t("contacts.familyAndCare")}
                        </Typography>
                        <Typography
                            className={classes.grayText}
                            variant="h6"
                            color="textSecondary">
                            Gotlindestrasse 54
                        </Typography>
                        <Typography
                            className={classes.grayText}
                            variant="h6"
                            color="textSecondary">
                            10365 Berlin-Lichtenberg
                        </Typography>
                    </AppDetailsWithIcon>
                </Grid>
                <Grid item>
                    <AppDetailsWithIcon
                        iconUrl={<PhoneCall className={classes.icon} />}
                        iconPosition="left"
                        justifyContent="flex-start"
                        gridSpacing={4}>
                        <a
                            href="tel:+49030863209109"
                            className={classes.anchorTag}>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                className={classes.fontWeight}>
                                fon +49 (0) 30 / 863 209 109
                            </Typography>
                        </a>
                        <a
                            href="tel:+49030863209199"
                            className={classes.anchorTag}>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                className={classes.fontWeight}>
                                fax +49 (0) 30 / 863 209 199
                            </Typography>
                        </a>
                    </AppDetailsWithIcon>
                </Grid>
                <Grid item>
                    <AppDetailsWithIcon
                        iconUrl={<Mail className={classes.icon} />}
                        iconPosition="left"
                        justifyContent="flex-start"
                        gridSpacing={4}>
                        <a
                            href="mailto:info@familyandcare.de"
                            className={classes.anchorTag}>
                            <Typography
                                variant="h6"
                                color="textPrimary"
                                className={classes.fontWeight}>
                                info@familyandcare.de
                            </Typography>
                        </a>
                    </AppDetailsWithIcon>
                </Grid>
                <Grid item>
                    <AppDetailsWithIcon
                        iconUrl={<ClockTime className={classes.icon} />}
                        iconPosition="left"
                        gridSpacing={4}
                        justifyContent="flex-start">
                        <Grid container>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.fontWeight}
                                    style={{
                                        marginRight: "2px",
                                    }}>
                                    Mon – Fri:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    color={"textSecondary"}
                                    className={classes.grayText}>
                                    6:30 – 18:00 Uhr
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.fontWeight}
                                    style={{
                                        marginRight: "2px",
                                    }}>
                                    {t("common.days.saturday")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    color={"textSecondary"}
                                    className={classes.grayText}>
                                    {t("common.closed")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    className={classes.fontWeight}
                                    style={{
                                        marginRight: "2px",
                                    }}>
                                    {t("common.days.sunday")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    color={"textSecondary"}
                                    className={classes.grayText}>
                                    {t("common.closed")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AppDetailsWithIcon>
                </Grid>
                <Grid item style={{ paddingLeft: "78px" }}>
                    <Typography
                        variant="h6"
                        color={"textSecondary"}
                        className={classes.grayText}>
                        {t("contacts.admission.description")}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default ContactsFirstContainer;
