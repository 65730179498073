import { Grid } from "@material-ui/core";
import React from "react";
import { HomeSection2Details, HomeSection2DetailsProps } from "screens";

export interface HomeSection2CardProps extends HomeSection2DetailsProps {
    imageSrc: React.ReactNode;
    className: string;
    imageClassName: string;
    position?: "left" | "right";
}
export const HomeSection2Card: React.FC<HomeSection2CardProps> = ({
    imageSrc,
    className,
    imageClassName,
    position = "left",
    ...rest
}) => {
    return position === "left" ? (
        <Grid container>
            <Grid item xs={6}>
                <HomeSection2Details {...rest} />
            </Grid>
            <Grid item xs={6} className={className}>
                {imageSrc}
            </Grid>
        </Grid>
    ) : (
        <Grid container>
            <Grid item xs={6} className={className}>
                {imageSrc}
            </Grid>
            <Grid item xs={6}>
                <HomeSection2Details {...rest} />
            </Grid>
        </Grid>
    );
};

export default HomeSection2Card;
