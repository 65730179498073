import {
    createStyles, Grid, makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-white.svg";
import { ReactComponent as CheckIcon } from "assets/images/icons/check.svg";
import { AppButton, AppStepperFooterProps } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { MultiStepRegistrationFacilities } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            paddingRight: "10px",
            paddingBottom: "150px",
            paddingLeft: "50px",
            justifyContent: "space-between",

            [theme.breakpoints.up("xl")]: {
                paddingBottom: "180px",
            },
            [theme.breakpoints.up("xll")]: {
                paddingTop: "200px",
                paddingBottom: "250px",
            },
        },
        body: {
            [theme.breakpoints.down("md")]: {
                marginTop: "10px",
            },
        },
        button: {
            height: "56px",
            width: "100%",
            margin: 5,
        },
        icon: {
            objectFit: "contain",
            width: 20,
            height: 20
        },

    }),
);
export interface FacilitiesStep5CommonProps
    extends AppStepperFooterProps {
    handleNext: () => void;
    handleStateSet: (value: MultiStepRegistrationFacilities) => void;
    parentState: MultiStepRegistrationFacilities;
}

export const FacilitiesStep5: React.FC<FacilitiesStep5CommonProps> = ({
    handleNext,
    handleStateSet,
    parentState,

    ...rest
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const submit = () => {
        handleNext();
    }

    return (<>
        <div className={classes.root}>
            <div className={classes.body}>
                <div>
                    <Grid
                        container
                        alignItems="center"
                        direction="column" >
                        <Grid item >
                            <Typography variant="h4">
                                <CheckIcon className={classes.icon} />{t("kindergarten.step5.title1")} <span style={{ color: "mediumseagreen" }}>{t("kindergarten.step5.title2Green")}</span>
                            </Typography>
                        </Grid>
                        <Grid style={{ marginTop: 20, textAlign: "center", marginBottom: 60 }}>
                            <Typography variant="body1" color="textSecondary">
                                {t("kindergarten.step5.text")} 
                                
                            </Typography>
                        </Grid>
                        <Grid>
                            <AppButton
                                onClick={submit}
                                type="submit"
                                 text={t("kindergarten.step5.goToHome")}
                                //text="Go to Home!!"
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "245px" }}
                                classes={{ root: classes.button }}
                                endIcon={
                                    <ArrowIcon className={classes.icon} />
                                }
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    </>
    );
};

export default FacilitiesStep5;
