import { RouteInterface } from "globals/ReactRouter";
import { HomeLayout } from "layouts";
import { Contacts } from "../Contacts";
import { Home } from "../Home";
import { Imprint } from "./../Imprint";
import { ContactPath, HomePath, ImprintPath } from "./public-routes-path";
export const PublicRoutesList: RouteInterface[] = [
    {
        component: Home,
        exact: true,
        layout: HomeLayout,
        path: HomePath.path,
        uniqueKey: HomePath.key,
    },
    {
        component: Contacts,
        exact: true,
        layout: HomeLayout,
        path: ContactPath.path,
        uniqueKey: ContactPath.key,
    },
    {
        component: Imprint,
        exact: true,
        layout: HomeLayout,
        path: ImprintPath.path,
        uniqueKey: ImprintPath.key,
    },

];
