import React, { useEffect } from "react";
import GoogleIcon from "assets/images/logos/google.png";
import { LoginWithOtherIconButton } from "./LoginWithOtherIconButton";
import { GoogleLogin } from 'react-google-login';
import { AuthService } from "services/AuthService";
import { getSocialLoginInitialValue, SocialLogin } from "sdk";
import { useUserContext } from "providers";
import { createUserCookies } from "globals/cookie";
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
import { SocialLoginProps } from "./LoginFacebook";

const clientId = '842355071332-qme2d7ve4tq2hpkrl6nt9f4j6a3v3gmg.apps.googleusercontent.com';
const service = new AuthService();

export const LoginGoogle: React.FC<SocialLoginProps> =
({
    submitData
}) => {
    const { user, setUser } = useUserContext();
    const history = useHistory();

    const onSuccess = (response: any) => {
        let socialLoginData: SocialLogin = getSocialLoginInitialValue();
        socialLoginData.provider = "Google";
        socialLoginData.token = response.tokenId;

        console.log("OVO CEMO SUBMITAT: ");
        console.log(socialLoginData);

        service.socialLogin(socialLoginData)
            .then(function (response) {
                console.log("API RESPONSE:");
                console.log(response);

                if (setUser) {
                    setUser({
                        Email: response.data.email,
                        jwtToken: response.data.jwtToken,
                        FirstName: response.data.firstName,
                        LastName: response.data.lastName,
                        Fullname: response.data.fullname,
                        Type: response.data.type,
                        ProfileImageUrl: response.data.profileImageUrl,
                    });
                } else {
                    //setError(t("auth.loginError") as string);
                }
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })

    }

    useEffect(() => {
        console.log("User: " + user);
        if (user) {
            createUserCookies(user);
            if(submitData)
                submitData();
            history.push(appRoutesForContext().public().homePath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const onFailure = (response: any) => {
        console.log("Error: ", response);
    }

    return (
        <GoogleLogin
            clientId={clientId}
            render={renderProps => (
                <LoginWithOtherIconButton imageUrl={GoogleIcon} handleClick={renderProps.onClick} />
            )}
            prompt="select_account"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
        />
    );
};

export default LoginGoogle;
