import { Grid, Typography } from "@material-ui/core";
import { AppTitleWithMarker } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ContactsFacilityDetails } from "./ContactsFacilityDetails";
export const ContactsSecondContainer = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                spacing={3}>
                <Grid item>
                    <AppTitleWithMarker
                        firstTitle={t("contacts.facility.title1")}
                        secondTitle={t("contacts.facility.title2")}
                        markerPosition="first"
                    />
                    <Typography variant="h5">
                        {t("contacts.facility.title3")}
                    </Typography>
                </Grid>
                <Grid item>
                    <ContactsFacilityDetails
                        count={1}
                        text1="U-Bahnhof „Lichtenberg“"
                        text2="Linie U5"
                        text3={
                            t("contacts.distanceAbout", {
                                count: 900,
                            }) as string
                        }
                    />
                </Grid>
                <Grid item>
                    <ContactsFacilityDetails
                        count={2}
                        text1="Bus-Haltestelle „Siegfriedstraße“"
                        text2="Linie 193, 240, 256"
                        text3={
                            t("contacts.distanceAbout", {
                                count: 200,
                            }) as string
                        }
                    />
                </Grid>
                <Grid item>
                    <ContactsFacilityDetails
                        count={3}
                        text1="Tram-Haltestelle „Siegfriedstraße“"
                        text2="Linie M21/ M37"
                        text3={
                            t("contacts.distanceAbout", {
                                count: 200,
                            }) as string
                        }
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default ContactsSecondContainer;
