import { AppBar, Grid, IconButton, Toolbar } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";
import React, { ReactNode } from "react";
export interface AppDialogHeaderBackProps {
    handleBack: () => void;
    children?: ReactNode;
    className?: string;
}
export const AppDialogHeaderBack = React.memo<AppDialogHeaderBackProps>(
    ({ handleBack, className, children }) => {
        return (
            <AppBar
                style={{ position: "relative", boxShadow: "0px 0px" }}
                className={className}
                color="transparent"
                position="static">
                <Toolbar>
                    <Grid container style={{ width: "100px" }}>
                        <Grid item>
                            <IconButton
                                edge="start"
                                color="primary"
                                onClick={handleBack}
                                aria-label="close">
                                <ArrowBackIos />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center">
                        <Grid item>{children}</Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    },
);

export default AppDialogHeaderBack;
