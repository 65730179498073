export const ContactsEnTranslations = {
    contacts: {
        title: "Contacts",
        ourContacts: {
            title1: "Our",
            title2: "Contacts",
        },
        facility: {
            title1: "The facility",
            title2: "is short",
            title3: "reachable via:",
        },
        familyAndCare: "FamilyandCare non-profit GmbH",
        distanceAbout: `(distance about {{count}} meters)`,
        admission: {
            description:
                "Admission to the KiTa premises only possible with prior registration possible ",
        },
    },
};
export default ContactsEnTranslations;
