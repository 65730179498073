import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AppButton, AppButtonProps } from "components";
import { COLOR_GRAY, COLOR_GRAY_WITH_LIGHT_BLUE, COLOR_WHITE } from "theme";
import React from "react";
import { useTranslation } from "react-i18next";
export const AppBackButtonWithBackwardArrow = React.memo<AppButtonProps>(
    (props) => {
        const { t } = useTranslation();
        return (
            <AppButton
                variant="contained"
                customHoverBackgroundColor={COLOR_WHITE}
                customTextColor={COLOR_GRAY}
                text={t("common.back")}
                startIcon={<ArrowBackIcon />}
                customBackgroundColor={COLOR_GRAY_WITH_LIGHT_BLUE}
                {...props}
            />
        );
    },
);

export default AppBackButtonWithBackwardArrow;
