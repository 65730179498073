import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
export interface LoginDialogHeaderProps {
    closeLoginDialog: () => void;
    redirectPath: string;
}
export const LoginDialogHeader: React.FC<LoginDialogHeaderProps> = React.memo(
    ({ closeLoginDialog, redirectPath }) => {
        const { t } = useTranslation();
        const history = useHistory();
        const handleHomeButtonClick = () => {
            history.push(appRoutesForContext().public().homePath);
        };

        const handleRegisterButtonClick = () => {
            history.push(
                appRoutesForContext()
                    .auth()
                    .registration.setRegistrationPathParams(redirectPath),
            );
        };

        return (
            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}>
                <Grid item>
                    <Typography variant="body1" color="textSecondary">
                        {t("auth.dontHaveAccountYet")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleRegisterButtonClick}
                        style={{ borderRadius: "0px" }}
                        size="large">
                        <Typography variant="button">
                            {t("auth.signUp")}
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleHomeButtonClick}
                        style={{ borderRadius: "0px" }}
                        size="large">
                        <Typography variant="button">
                            {t("home.home")}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        );
    },
);

export default LoginDialogHeader;
