import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { KindergartenRegistrationListModal } from "sdk";
import { KindergartenListExpendedRowFirstContainer } from "./KindergartenListExpendedRowFirstContainer";
import { KindergartenListExpendedRowSecondContainer } from "./KindergartenListExpendedRowSecondContainer";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
            paddingLeft: theme.spacing(10),
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
        },
        title: { paddingBottom: theme.spacing(3) },
        body: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
        },

        saveButton: { marginTop: theme.spacing(5) },
    }),
);
export interface KindergartenListExpendedRowProps
    extends ExpanderComponentProps<KindergartenRegistrationListModal> {}
export const KindergartenListExpendedRow: React.FC<KindergartenListExpendedRowProps> =
    ({ data }) => {
        const { t } = useTranslation();
        const classes = useStyles();

        return (
            <div className={classes.root}>
                <div style={{ maxWidth: "1200px" }}>
                    <div className={classes.title}>
                        <Typography variant="h5">
                            {t("kindergartenRegistrationList.childRow.title")}
                        </Typography>
                    </div>
                    <div className={classes.body}>
                        <KindergartenListExpendedRowFirstContainer
                            parentState={data}
                        />
                        <KindergartenListExpendedRowSecondContainer
                            parentState={data}
                        />
                    </div>
                </div>
            </div>
        );
    };
export default KindergartenListExpendedRow;
