import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
    AppCircleWithCounterAndDetails,
    AppCircleWithCounterProps,
} from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLOR_TEXT_DARK } from "theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { paddingLeft: theme.spacing(5) },
        textContainer: {
            marginTop: "52px",
            marginLeft: "-25px",
        },
        counter: {
            fontSize: "1.5rem",
        },
        details: {
            marginTop: theme.spacing(4),
            "& > p": {
                lineHeight: "1.7 !important",
            },
        },
        urlText: { fontWeight: 900 },
        containerSpacing: {
            display: "flex",
            marginTop: theme.spacing(6),
        },
        secondDescriptionSpacing: {
            marginTop: theme.spacing(3),
        },
        anchorTag: {
            textDecoration: "none",
            color: COLOR_TEXT_DARK,
            fontWeight: 900,
        },
    }),
);
export interface ImprintSecondContainerProps
    extends Omit<AppCircleWithCounterProps, "text"> {}
export const ImprintSecondContainer: React.FC<ImprintSecondContainerProps> = (
    props,
) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            {/* 4 detail */}
            <AppCircleWithCounterAndDetails
                {...props}
                text="4"
                title={t("imprint.liabilityForLinks.title")}>
                <div className={classes.details}>
                    <Typography
                        variant="body1"
                        className={classes.urlText}
                        color="textPrimary">
                        {t("imprint.liabilityForLinks.description1")}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.secondDescriptionSpacing}>
                        {t("imprint.liabilityForLinks.description2")}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        className={classes.secondDescriptionSpacing}>
                        {t("imprint.liabilityForLinks.description3")}
                    </Typography>
                </div>
            </AppCircleWithCounterAndDetails>

            {/* 5 detail */}

            <div className={classes.containerSpacing}>
                <AppCircleWithCounterAndDetails
                    {...props}
                    text="4"
                    title={t("imprint.copyright.title")}>
                    <div className={classes.details}>
                        <Typography variant="body1" color="textSecondary">
                            {t("imprint.copyright.description1")}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.secondDescriptionSpacing}>
                            {t("imprint.copyright.description1")}
                        </Typography>
                        <div style={{ display: "flex" }}>
                            <Typography
                                variant="body1"
                                className={classes.urlText}
                                color="textPrimary">
                                {t("imprint.copyright.urlTitle")}
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                                <a
                                    className={classes.anchorTag}
                                    rel="noreferrer"
                                    href={t("imprint.copyright.url")}
                                    target="_blank">
                                    {t("imprint.copyright.url")}
                                </a>
                            </Typography>
                        </div>
                    </div>
                </AppCircleWithCounterAndDetails>
            </div>
        </div>
    );
};

export default ImprintSecondContainer;
