import { CareHours, ExpandableRow, Gender } from "globals/Types";
import moment, { Moment } from "moment-timezone";
import { RegistrationForChildrenStep1 } from "sdk";
export interface RegistrationListModal
    extends ExpandableRow,
    RegistrationForChildrenStep1 {
    Date: Moment;
    Age: number;
    //End: string;
    Start: string;
    Message: string;
}

export interface UpdateChildRequest {
    ChildId: number,
    Firstname: string,
    Lastname: string,
    Gender: number,
    Start: Moment,
    CareHour: number,
    birthDate: Moment,
    Message: string
}

export const RegistrationListData: RegistrationListModal[] = [
    {
        Id: 1923223,
        Date: moment(),
        FirstNameChild: "William",
        LastNameChild: "Hutchinson",
        Age: 5,
        Gender: Gender.MALE,
        Start: "01-09-2021",
        //End: "30-05-2022",
        BirthdayOfTheChild: moment(),
        CareHours: CareHours.FIVE_TO_SEVEN,
        SupportStart: moment(),
        Message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    {
        Id: 1223223,
        Date: moment(),
        FirstNameChild: "William",
        LastNameChild: "Hutchinson",
        Age: 10,
        Gender: Gender.FEMALE,
        Start: "01-09-2021",
        //End: "30-05-2022",
        BirthdayOfTheChild: moment(),
        CareHours: CareHours.NINE_TO_TWELVE,
        SupportStart: moment(),
        Message:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
];
