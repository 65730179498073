import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { MapToolTipProps } from "./MapToolTip";
import React from "react";
import { MapToolTip } from "./MapToolTip";

export interface MapMarkerProps {
    text?: string;
    children?: React.ReactNode;
    bgColor: string;
    styleProps?: MarkerStyleProps;
    lat: number;
    lng: number;
    tooltipProps?: Omit<MapToolTipProps, "children">;
}

export interface MarkerStyleProps {
    arrowColor?: string;
    showHoverEffect?: boolean;
    borderRadius?: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        markerBox: (props: MarkerStyleProps) => {
            const {
                showHoverEffect,
                arrowColor,
                borderRadius = "10px",
            } = props;
            return {
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: borderRadius,
                transition: "width .2s, height .2s",
                width: "40px",
                fontSize: "1.7rem",
                color: theme.palette.common.white,
                height: "40px",
                margin: "0 auto",

                "&:hover": {
                    width: showHoverEffect ? "55px" : "40px",
                    height: showHoverEffect ? "55px" : "40px",
                },
                "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 37,
                    width: 13,
                    height: 10,
                    background: arrowColor,
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                },
            };
        },
    }),
);
export const MapMarker: React.FC<MapMarkerProps> = ({
    text,
    bgColor,
    styleProps,
    children,
    tooltipProps,
}) => {
    const classes = useStyles(
        styleProps ? styleProps : { showHoverEffect: true },
    );
    return (
        <>
            {tooltipProps ? (
                <MapToolTip {...tooltipProps}>
                    <Box
                        className={classes.markerBox}
                        style={{ background: bgColor }}>
                        {text ? text : children}
                    </Box>
                </MapToolTip>
            ) : (
                <Box
                    className={classes.markerBox}
                    style={{ background: bgColor }}>
                    {text ? text : children}
                </Box>
            )}
        </>
    );
};

export default MapMarker;
