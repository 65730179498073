import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import loginBg from "assets/images/bg/login-sign-up-bg.png";
import {
    AppPrimaryButtonWithForwardIcon,
    AppTextField
} from "components";
import { AppDialog } from "components/Dialog";
import { EMAIL_VALIDATION_PATTERN } from "globals/constants";
import { useUserContext } from "providers";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import { RegisterDialogHeader } from "screens";
import { getPasswordForgottenInitialValue, PasswordForgotten } from "sdk/models/PasswordForgotten";
import { AuthService } from "services/AuthService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            marginBottom: theme.spacing(3),
            textAlign: "center",
        },

        iconButtonsContainer: {
            marginTop: theme.spacing(1),
        },
        loginButtonContainer: {
            marginTop: theme.spacing(1),
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
        },
        dialogBody: {
            height: "80vh",

            maxWidth: "400px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "600px",
            },
        },
    }),
);
export interface PasswordForgottenDialogProps {
    open: boolean;
    handleClose: () => void;
    redirectUrl: string;
    redirectPath: string;
}

const service = new AuthService();
export const PasswordForgottenDialog: React.FC<PasswordForgottenDialogProps> = ({
    handleClose,
    open,
    redirectUrl,
    redirectPath,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const {lng, setLanguage } = useUserContext();

    const [error, setError] = useState<null | string>(null);
    const { control, handleSubmit } = useForm<PasswordForgotten>();

    const submit = handleSubmit((data) => {
        let passwordForgottenData: PasswordForgotten = getPasswordForgottenInitialValue();
        passwordForgottenData.email = data.email;
        passwordForgottenData.clientResetPassURI = "https://kitaplatz-demo.azurewebsites.net/resetPassword/redirectPath=-home";
        //passwordForgottenData.clientResetPassURI = "https://localhost:3001/resetPassword/redirectPath=-home";
        passwordForgottenData.langCode=lng;

        console.log(passwordForgottenData);

        service.passwordForgotten(passwordForgottenData)
            .then(function (response) {
                console.log(response);
                history.push(
                    appRoutesForContext()
                        .public()
                        .homePath,
                );
                toast.success("Check your Email!");
            })
            .catch(function (error) {
                console.log("API ERROR");
                console.log(error);
            })
    });

    return (
        <div className={classes.root}>
            <AppDialog
                withBackgroundImage={true}
                open={open}
                closeAfterTransition={true}
                handleClose={handleClose}
                bgImageUrl={loginBg}
                dialogHeaderRightChildren={
                    <RegisterDialogHeader redirectUrl={redirectUrl} />
                }
                bgImageHeight={"100vh"}>
                <div className={classes.dialogContainer}>
                    <form noValidate={true} onSubmit={submit}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.dialogBody}>
                            <Grid item>
                                <Grid container direction="column" spacing={2}>
                                    <Grid item className={classes.title}>
                                        <Typography
                                            variant="h4"
                                            color="textPrimary">
                                            {t("auth.email.title")}
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.title}>
                                        <Typography variant="body1" color="textSecondary">
                                            {t("auth.text")}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <AppTextField
                                            control={control}
                                            name="email"
                                            placeholder={t(
                                                "registrationForParent.step1.email.title",
                                            )}
                                            fullWidth={true}
                                            rules={{
                                                required: t(
                                                    "registrationForParent.step1.email.missing",
                                                ) as string,
                                                pattern: {
                                                    value: EMAIL_VALIDATION_PATTERN,
                                                    message: t(
                                                        "registrationForParent.step1.email.invalid",
                                                    ) as string,
                                                },
                                            }}
                                            customBorderRadius={
                                                "10px 0px 0px 10px / 10px 0px 0px 10px"
                                            }
                                            removeShadow={true}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    className={classes.loginButtonContainer}
                                    spacing={5}>
                                    <Grid item>
                                        <AppPrimaryButtonWithForwardIcon
                                            type="submit"
                                            text={t("common.continue")}
                                        />
                                    </Grid>
                                    {error && (
                                        <Typography
                                            color="error"
                                            variant="caption">
                                            {error}
                                        </Typography>
                                    )}

                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </AppDialog>
        </div>
    );
};

export default PasswordForgottenDialog;
