import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import loginBg from "assets/images/bg/login-sign-up-bg.png";
import clsx from "clsx";
import {
    AppPrimaryButtonWithForwardIcon
} from "components";
import { AppDialog } from "components/Dialog";
import { AppPasswordTextField } from "components/Inputs/TextFields/AppPasswordTextField";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import { ConfirmationAndSetPasswordFacilities, ConfirmationAndSetPasswordFacilitiesForm, getConfirmationAndSetPasswordFacilitiesInitialValue } from "sdk/models";
import { FacilitiesService } from "services/FacilitiesService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            marginBottom: theme.spacing(3),
            textAlign: "center",
        },

        iconButtonsContainer: {
            marginTop: theme.spacing(1),
        },
        loginButtonContainer: {
            marginTop: theme.spacing(1),
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
        },
        dialogBody: {
            height: "80vh",

            maxWidth: "400px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "600px",
            },
        },
        fullWidth: { width: "100%" },
        fieldsContainer: {
            marginTop: theme.spacing(4),
            maxWidth: "400px",
        },
    }),
);
const service = new FacilitiesService();

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export interface AddPasswordViewDialogProps {
    open: boolean;
    handleClose: () => void;
    redirectUrl: string;
    redirectPath: string;
}

export const AddPasswordViewDialog: React.FC<AddPasswordViewDialogProps> = ({
    handleClose,
    open,
    redirectUrl,
    redirectPath,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { control, handleSubmit, watch } = useForm<ConfirmationAndSetPasswordFacilitiesForm>();
    let query = useQuery();

    const submit = handleSubmit((data: ConfirmationAndSetPasswordFacilitiesForm) => {
        //https://betterprogramming.pub/using-url-parameters-and-query-strings-with-react-router-fffdcea7a8e9
        let userId = query.get("userId") as string;
        let code = query.get("code") as string;
        if (code !== null) {
            code = code.replaceAll("+", "%2B");
            code = code.replaceAll("/", "%2F");
            code = code.replaceAll("=", "%3D");
        } else {
            code = "0";
        }
        let confirmationAndSetPasswordData: ConfirmationAndSetPasswordFacilities = getConfirmationAndSetPasswordFacilitiesInitialValue();
        confirmationAndSetPasswordData.password = data.Password;
        confirmationAndSetPasswordData.confirmPassword = data.ConfirmPassword;
        confirmationAndSetPasswordData.code = code;
        confirmationAndSetPasswordData.userId = userId;

        console.log(confirmationAndSetPasswordData);
        service.confirmationAndSetPassword(confirmationAndSetPasswordData)
            .then(function (response) {
                console.log("SUCCESS");
                console.log(response);
                history.push(
                    appRoutesForContext()
                        .public()
                        .homePath,
                );
                toast.success(t("addPasswordView.toastSuccess"));

            })
            .catch(function (error) {
                console.log("API ERROR");
                console.log(error);
            })

    });

    let passwordWatcher = watch("Password", "");
    return (
        <div className={classes.root}>
            <AppDialog
                withBackgroundImage={true}
                open={open}
                closeAfterTransition={true}
                handleClose={handleClose}
                bgImageUrl={loginBg}
                bgImageHeight={"100vh"}>
                <div className={classes.dialogContainer}>
                    <form noValidate={true} onSubmit={submit}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            className={classes.dialogBody}>
                            <Grid container direction="column" spacing={2}>
                                <Grid item className={classes.title}>
                                    <Typography
                                        variant="h4"
                                        color="textPrimary">
                                        {t("auth.confirmPassword.title")}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.title}>
                                    <Typography variant="body1" color="textSecondary">
                                        {t("auth.text")}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <AppPasswordTextField
                                        control={control}
                                        name="Password"
                                        type="password"
                                        rules={{
                                            required: t(
                                                "auth.password.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("auth.password.placeHolder")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AppPasswordTextField
                                        control={control}
                                        name="ConfirmPassword"
                                        type="password"
                                        rules={{
                                            required: t(
                                                "auth.confirmPassword.missing",
                                            ) as string,
                                            validate: (value: string) =>
                                                value === passwordWatcher ||
                                                (t(
                                                    "auth.confirmPassword.notMatched",
                                                ) as string),
                                        }}
                                        placeholder={t(
                                            "auth.confirmPassword.placeHolder",
                                        )}
                                        fullWidth={true}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                    className={classes.loginButtonContainer}
                                    spacing={5}>
                                    <Grid item>
                                        <AppPrimaryButtonWithForwardIcon
                                            type="submit"
                                            text={t("common.continue")}
                                        />
                                    </Grid>
                                    {/*  {error && (
                                        <Typography
                                            color="error"
                                            variant="caption">
                                            {error}
                                        </Typography>
                                    )} */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </AppDialog>
        </div>
    );
};

export default AddPasswordViewDialog;
