import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import AppTextMarker from "components/Labels/AppTextMarker";
import React from "react";
export interface SelectAllCountWithTextProps {
    count: number;
    text: string;
    markerColor: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { display: "flex", alignItems: "center" },
        counterContainer: {
            width: "20px",
            height: "20px",
            marginRight: theme.spacing(1),
        },
        marker: {
            minWidth: "50px",
            marginRight: "10px",
            paddingBottom: "0px !important",

            paddingTop: "0px !important",
        },
    }),
);
export const SelectAllKindergartenCountWithText: React.FC<SelectAllCountWithTextProps> =
    ({ count, text, markerColor }) => {
        const classes = useStyles();
        return (
            <div className={classes.root}>
                <AppTextMarker
                    bgClr={markerColor}
                    color="white"
                    classes={{ root: classes.marker }}>
                    <Typography variant="h5">{count}</Typography>
                </AppTextMarker>

                <Typography variant="body1">{text}</Typography>
            </div>
        );
    };

export default SelectAllKindergartenCountWithText;
