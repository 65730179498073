import { RoutePathInterface } from "globals";
import { appRoutesForContext } from "routing/react-route-paths";

export const MapSearchRouteWithParamsPath: RoutePathInterface = {
    path: appRoutesForContext().mapSearch().mapSearchWithParamsPath,
    key: [],
};

export const MapSearchRoutePath: RoutePathInterface = {
    path: appRoutesForContext().mapSearch().mapSearchPath,
    key: [],
};
export const MapSearchRoutePathList: RoutePathInterface[] = [
    MapSearchRoutePath,
    MapSearchRouteWithParamsPath,
];
