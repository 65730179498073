import {
    createStyles, Grid, makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { ReactComponent as ArrowWhite } from "assets/images/icons/arrow-white.svg";
import Manager from "assets/images/logos/manager.jpg";
import Owner from "assets/images/logos/owner.jpg";
import { AppButton, AppStepperFooterProps } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { MultiStepRegistrationFacilities } from "sdk";
import { COLOR_WHITE } from "theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: "10px",
            maxWidth: "600px",
            width: "100%",
            justifyContent: "center",
            height: "100%",

        },
        body: {
            width: "100%",
            height: "100%",

        },
        button: {
            borderRadius: "10px 10px 10px 10px / 10px 10px 10px 10px",
            height: "30px",
            width: "15%",
        },
        fieldsContainer: {
            maxWidth: "600px",
            backgroundColor: COLOR_WHITE,
            border: '1px solid rgb(220,220,220, .3)',
            flex: 2,
            padding: 20,
            margin: 5,
            flexWrap: 'wrap',
        },
        icon: {
            objectFit: "contain",
            width: 20,
            height: 20,
        },
        arrowIcon: {
            objectFit: "contain",
            width: 20,
            height: 20,
            marginLeft: "-14px",
        },
        parent: {
            padding: 1,
            textAlign: "left",
            flexGrow: 1,
            display: "flex",
        }
    }),
);
export interface FacilitiesStep3CommonProps
    extends AppStepperFooterProps {
    handleNext: () => void;
    handleStateSet: (value: MultiStepRegistrationFacilities) => void;
    parentState: MultiStepRegistrationFacilities;
}

export const FacilitiesStep3: React.FC<FacilitiesStep3CommonProps> = ({
    handleNext,
    handleStateSet,
    parentState,


    ...rest
}) => {
    const { t } = useTranslation();
    const classes = useStyles();


    const submitOwner = () => {
        handleStateSet({
            ...parentState,
            Owner: true,
        });
        handleNext();
    }
    const submitManager = () => {
        handleStateSet({
            ...parentState,
            Owner: false,
        });
        handleNext();
    }

    return (<>
        <div className={classes.root}>
            <div className={classes.body}>
                <div>
                    <Grid
                        container
                        alignItems="center"
                        direction="column" >
                        <Grid item >
                            <Typography variant="h4">
                                {t("kindergarten.step3.title")}
                            </Typography>
                        </Grid>
                        <Grid style={{ margin: 10, textAlign: "center", marginBottom: 50 }}>
                            <Typography variant="body1" color="textSecondary">
                                {t("kindergarten.step3.text")}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>

                <Grid
                    container
                    justifyContent="space-between"
                    spacing={1}   >
                    <Grid item xs={6}>
                        <div className={classes.fieldsContainer}>
                            <div className={classes.parent}>
                                <div style={{ display: "inline-block", float: "left", }}>
                                    <div>
                                        <img src={Owner} className={classes.icon} alt="owner" /><br />
                                    </div>
                                    <div style={{ marginTop: 12, }}>
                                        <b>{t("kindergarten.step3.owner")}</b>
                                    </div>
                                </div>

                                <div style={{ display: "inline-block", float: "right", marginLeft: 80, }}>
                                    <div style={{ position: "fixed", textAlign: "end" }}>
                                        <AppButton
                                            onClick={submitOwner}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ minWidth: "10px" }}
                                            classes={{ root: classes.button }}
                                            endIcon={
                                                <ArrowWhite className={classes.arrowIcon} />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.fieldsContainer}>

                            <div className={classes.parent}>
                                <div style={{ display: "inline-block", float: "left", }}>
                                    <div>
                                        <img src={Manager} className={classes.icon} alt="manager" /><br />
                                    </div>
                                    <div style={{ marginTop: 12, }}>
                                        <b>{t("kindergarten.step3.manager")}</b>
                                    </div>
                                </div>

                                <div style={{ display: "inline-block", float: "right", marginLeft: 63, }}>
                                    <div style={{ position: "fixed", textAlign: "end" }}>
                                        <AppButton
                                            onClick={submitManager}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            style={{ minWidth: "10px" }}
                                            classes={{ root: classes.button }}
                                            endIcon={
                                                <ArrowWhite className={classes.arrowIcon} />
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    </>
    );
};

export default FacilitiesStep3;
