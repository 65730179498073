import React from "react";
import { useHistory, useParams } from "react-router-dom";
import PasswordForgottenDialog from "./PasswordForgottenDialog";
export interface PasswordForgottenParams {
    redirectPath: string;
}

export const PasswordForgotten = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { redirectPath } = useParams<PasswordForgottenParams>();
    const handleClose = () => {
        setOpen(false);
        history.push(redirectPath.replaceAll("-", "/"));
    };
    return (
        <PasswordForgottenDialog
            open={open}
            handleClose={handleClose}
            redirectUrl={redirectPath}
            redirectPath={redirectPath}
        />
    );
};

export default PasswordForgotten;
