import { StandardProps } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { StyleClassKey } from "globals";
import React from "react";
import { COLOR_PRIMARY, COLOR_WHITE } from "theme";

export interface MarkerStyles {
    bgClr?: string;
    color?: string;
    padding?: string;
}
const styles = () =>
    createStyles({
        root: (props: MarkerStyles) => ({
            paddingRight: "5px",
            paddingLeft: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            padding: props.padding && props.padding + "  !important",
            background: props.bgClr,
            color: props.color,
        }),
    });

type AppTextMarkerClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppTextMarkerProps
    extends StandardProps<MarkerStyles, AppTextMarkerClassKey> {
    children: React.ReactNode;
}

export const AppTextMarker = React.memo<AppTextMarkerProps>(
    ({
        children,
        bgClr = COLOR_PRIMARY,
        color = COLOR_WHITE,
        padding,
        ...rest
    }) => {
        const classes = useStyles({ ...rest, color, bgClr, padding });
        return (
            <mark className={classes.root} style={rest.style}>
                {children}
            </mark>
        );
    },
);

export default AppTextMarker;
