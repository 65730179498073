import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
export interface RegisterDialogHeaderProps {
    redirectUrl: string;
}

export const RegisterDialogHeader = React.memo<RegisterDialogHeaderProps>(
    ({ redirectUrl }) => {
        const history = useHistory();
        const onLoginButtonClickHandler = () => {
            history.push(
                appRoutesForContext()
                    .auth()
                    .login.setLoginPathParams(redirectUrl),
            );
        };
        const { t } = useTranslation();
        return (
            <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}>
                <Grid item>
                    <Typography variant="body1" color="textSecondary">
                        {t("auth.alreadyHaveAccount")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ borderRadius: "0px" }}
                        size="large"
                        onClick={onLoginButtonClickHandler}>
                        <Typography variant="button">
                            {t("auth.login")}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        );
    },
);

export default RegisterDialogHeader;
