export const ProfileEnTranslations = {
    profile: {
        title: "Personal Data",
        customizeData: "Customize Data",
        personal: "Personal",
        information: "Information",
        firstName: {
            title: "Full legal first name and middle name",
            missing: "First name and middle name is missing",
        },
        land: {
            title: "Land",
            missing: "Land is missing",
        },
        lastName: {
            title: "Full legal last name",
            missing: "Full legal last name missing",
        },
        address: {
            title: "Address",
            missing: "Address is missing",
        },
        dateOfBirth: {
            title: "Date of birth",
            missing: "Date of birth is missing",
        },
        city: {
            title: "City",
            missing: "City is missing",
        },
        phoneNumber: {
            title: "Phone number",
            missing: "Phone number is missing",
        },
        postalCode: {
            title: "Postal code",
            missing: "Postal code is missing",
        },
    },
    companyProfile: {
        title: "Company Data",
        company: "Company",
        location: "Location",
        companyName: {
            title: "Company Name",
            missing: "Company name is missing",
        },
        email: {
            title: "Email",
            missing: "Email is missing",
        },
    },
};
export default ProfileEnTranslations;
