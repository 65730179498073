import { RoutePathInterface } from "globals";
import { appRoutesForContext } from "routing/react-route-paths";

export const ProfileRoutePath: RoutePathInterface = {
    path: appRoutesForContext().profile().profilePath,
    key: [],
};
export const ProfileEditRoutePath: RoutePathInterface = {
    path: appRoutesForContext().profile().profileEditPath,
    key: [],
};
export const FacilitiesProfileRoutePath: RoutePathInterface = {
    path: appRoutesForContext().facilitiesProfile().facilitiesProfilePath,
    key: [],
};
export const FacilitiesProfileEditRoutePath: RoutePathInterface = {
    path: appRoutesForContext().facilitiesProfile().facilitiesProfileEditPath,
    key: [],
};
export const ProfileRoutePathList: RoutePathInterface[] = [
    ProfileRoutePath,
    ProfileEditRoutePath,
    FacilitiesProfileRoutePath,
    FacilitiesProfileEditRoutePath,

];
