import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import loginBg from "assets/images/bg/login-sign-up-bg.png";
import {
    AppPrimaryButtonWithForwardIcon
} from "components";
import { AppDialog } from "components/Dialog";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import { EmailComfirmationURL, getEmailComfirmationURLInitialValues } from "sdk";
import { AuthService } from "services/AuthService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {},
        title: {
            marginBottom: theme.spacing(3),
            textAlign: "center",
        },
        loginButtonContainer: {
            marginTop: theme.spacing(1),
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "10px",
        },
        dialogBody: {
            height: "80vh",

            maxWidth: "400px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "600px",
            },
        },
    }),
);

const service = new AuthService();

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export interface RegisterSuccessDialogProps {
    open: boolean;
    handleClose: () => void;
    redirectPath: string;
}

export const RegisterSuccessDialog: React.FC<RegisterSuccessDialogProps> = ({
    handleClose,
    open,
    redirectPath,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    let query = useQuery();

    useEffect(()=>{
        let userId = query.get("userId") as string;
        let code = query.get("code") as string;
        if (code !== null) {
            code = code.replaceAll("+", "%2B");
            code = code.replaceAll("/", "%2F");
            code = code.replaceAll("=", "%3D");

        } else {
            code = "0";
        } 
        let emailComfirmationUrlData: EmailComfirmationURL= getEmailComfirmationURLInitialValues();
        emailComfirmationUrlData.userId=userId;
        emailComfirmationUrlData.code=code;
        console.log(emailComfirmationUrlData);
        service.getEmailComfirmationURL(emailComfirmationUrlData)
        .then(function(response){
            console.log("SUCCESS");
            console.log(response);
        })
        .catch(function(error){
            console.log("API ERROR");
            console.log(error);
        })
    },[]);


    const submit = () => {
        history.push(
            appRoutesForContext()
                .auth()
                .login
                .setLoginPathParams(redirectPath)
        );
    };

    return (
        <div className={classes.root}>
            <AppDialog
                withBackgroundImage={true}
                open={open}
                closeAfterTransition={true}
                handleClose={handleClose}
                bgImageUrl={loginBg}
                bgImageHeight={"100vh"}>
                <div className={classes.dialogContainer}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.dialogBody}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item className={classes.title}>
                                    <Typography
                                        variant="h4"
                                        color="textPrimary">
                                        Registration Successful
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.title}>
                                    <Typography variant="body1" color="textSecondary">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi                                        </Typography>
                                </Grid>

                            </Grid>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                className={classes.loginButtonContainer}
                                spacing={5}>
                                <Grid item>
                                    <AppPrimaryButtonWithForwardIcon
                                        onClick={submit}
                                        type="submit"
                                        text="Login"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </AppDialog>
        </div>
    );
};

export default RegisterSuccessDialog;
