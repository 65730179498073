import { Box } from "@material-ui/core";
import React from "react";
//Rendering the components

export interface TabPanelProps {
    children: React.ReactNode;
    index?: number;
    value: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({
    children,
    value,
    index,
    ...other
}) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && <Box p={2}> {children}</Box>}
        </div>
    );
};

export default TabPanel;
