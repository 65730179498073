import React from "react";
import { useHistory, useParams } from "react-router-dom";
import RegisterSuccessDialog from "./RegisterSuccessDialog";

export interface RegisterSuccessParams {
    redirectPath: string;
}

export const RegisterSuccess = () => {
    const [open, setOpen] = React.useState(true);
    const history = useHistory();
    const { redirectPath } = useParams<RegisterSuccessParams>();
    const handleClose = () => {
        setOpen(false);
        history.push(redirectPath.replaceAll("-", "/"));
    };
   // http://localhost:3000/registerSuccess/redirectPath=-home
    return (
        <RegisterSuccessDialog
            open={open}
            handleClose={handleClose}
            redirectPath={redirectPath}
        />
    );
};

export default RegisterSuccess;
