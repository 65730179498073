import { Theme, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
    AppCircleWithCounterAndDetails,
    AppCircleWithCounterProps,
} from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { COLOR_TEXT_DARK } from "theme";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: { paddingRight: theme.spacing(1) },
        textContainer: {
            marginTop: "52px",
            marginLeft: "-25px",
        },

        details: {
            marginTop: theme.spacing(4),
            "& > p": {
                lineHeight: 1.7,
            },
        },
        urlText: { fontWeight: 900 },
        containerSpacing: {
            marginTop: theme.spacing(6),
        },
        secondDescriptionSpacing: {
            marginTop: theme.spacing(3),
        },
        anchorTag: {
            textDecoration: "none",
            color: COLOR_TEXT_DARK,
        },
    }),
);
export interface ImprintFirstContainerProps
    extends Omit<AppCircleWithCounterProps, "text"> {}
export const ImprintFirstContainer: React.FC<ImprintFirstContainerProps> = (
    props,
) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <div className={classes.root}>
            {/* 1 detail */}
            <AppCircleWithCounterAndDetails
                {...props}
                text="1"
                title={t("imprint.euDisputeResolution.title")}>
                <div className={classes.details}>
                    <Typography variant="body1" color="textSecondary">
                        {t("imprint.euDisputeResolution.description")}
                    </Typography>
                    <Typography variant="body1" className={classes.urlText}>
                        <a
                            className={classes.anchorTag}
                            href={t("imprint.euDisputeResolution.url")}
                            target="_blank"
                            rel="noreferrer">
                            {t("imprint.euDisputeResolution.url")}
                        </a>
                    </Typography>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                        className={classes.urlText}>
                        {t("imprint.euDisputeResolution.urlDescription")}
                    </Typography>
                </div>
            </AppCircleWithCounterAndDetails>
            {/* 2 detail */}
            <div className={classes.containerSpacing}>
                <AppCircleWithCounterAndDetails
                    {...props}
                    text="2"
                    title={t("imprint.consumerDispute.title")}>
                    <div className={classes.details}>
                        <Typography variant="body1" color="textSecondary">
                            {t("imprint.consumerDispute.description")}
                        </Typography>
                    </div>
                </AppCircleWithCounterAndDetails>
            </div>
            {/* 3 detail */}
            <div className={classes.containerSpacing}>
                <AppCircleWithCounterAndDetails
                    {...props}
                    text="3"
                    title={t("imprint.liabilityForContents.title")}>
                    <div className={classes.details}>
                        <Typography variant="body1" color="textSecondary">
                            {t("imprint.liabilityForContents.description1")}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            className={classes.secondDescriptionSpacing}>
                            {t("imprint.liabilityForContents.description2")}
                        </Typography>
                    </div>
                </AppCircleWithCounterAndDetails>
            </div>
        </div>
    );
};

export default ImprintFirstContainer;
