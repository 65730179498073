import {
    CssBaseline,
    responsiveFontSizes,
    ThemeProvider,
} from "@material-ui/core";
import { createBrowserHistory } from "history";
import { UserProvider } from "providers";
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "./localization/i18next";
import ReactRouter from "./routing/react-router";
import theme from "./theme/Theme";
const browserHistory = createBrowserHistory();

function App() {
    return (
        <React.Fragment>
            <ThemeProvider theme={responsiveFontSizes(theme)}>
                <CssBaseline />
                <I18nextProvider i18n={i18n}>
                    <UserProvider>
                        <Router history={browserHistory}>
                            <ReactRouter />
                        </Router>
                    </UserProvider>
                </I18nextProvider>
            </ThemeProvider>
            <ToastContainer
                position="bottom-right"
                autoClose={10000}
                theme="colored"
            />
        </React.Fragment>
    );
}

export default App;
