import { Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import {
    AppControlledDatePicker,
    AppControlledRadioButton,
    AppLabelWrapper,
    AppTextField,
} from "components";
import { CareHours } from "globals/Types";
import moment from "moment-timezone";
import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { RegistrationListModal } from "sdk";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        fullWidth: { width: "100%" },
    }),
);
export interface RegistrationListExpendedRowSecondContainerProps {
    readonly: boolean;
    parentState: RegistrationListModal;
    control: Control<RegistrationListModal>;
}
export const RegistrationListExpendedRowSecondContainer: React.FC<RegistrationListExpendedRowSecondContainerProps> =
    ({ control, readonly, parentState }) => {
        const classes = useStyles();
        const { t } = useTranslation();
        return (
            <Grid container spacing={2} className={classes.root}>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        direction="top"
                        readOnly={readonly}
                        value={t("dates.dateFormat", {
                            date: moment(parentState.SupportStart),
                        })}
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        label={t(
                            "registrationForChildren.step1.supportStart.title",
                        )}
                        required>
                        <AppControlledDatePicker
                            fullWidth
                            shouldUnregister={true}
                            name="SupportStart"
                            control={control}
                            rules={{
                                required: t(
                                    "registrationForChildren.step1.supportStart.missing",
                                ) as string,
                            }}
                        />
                    </AppLabelWrapper>
                </Grid>

                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        direction="top"
                        readOnly={readonly}
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        value={t(`common.careHoursTable.${parentState.CareHours}`)}
                        label={t(
                            "registrationForChildren.step1.careHours.title",
                        )}
                        required>
                        <AppControlledRadioButton
                            columnSpacing={2}
                            defaultValue={parentState.CareHours?.toString()}
                            name="CareHours"
                            control={control}
                            rules={{
                                required: t(
                                    "registrationForChildren.step1.careHours.missing",
                                ) as string,
                            }}
                            gridProps={{ xs: 6 }}
                            checkBoxes={[
                                {
                                    value: CareHours.FOUR_TO_FIVE,
                                    label: t("common.careHours.FOUR_TO_FIVE"),
                                },
                                {
                                    value: CareHours.FIVE_TO_SEVEN,
                                    label: t("common.careHours.FIVE_TO_SEVEN"),
                                },
                                {
                                    value: CareHours.SEVEN_T0_NINE,
                                    label: t("common.careHours.SEVEN_T0_NINE"),
                                },

                                {
                                    value: CareHours.NINE_TO_TWELVE,
                                    label: t("common.careHours.NINE_TO_TWELVE"),
                                },
                            ]}
                        />
                    </AppLabelWrapper>
                </Grid>
                <Grid item className={classes.fullWidth}>
                    <AppLabelWrapper
                        direction="top"
                        readOnly={readonly}
                        variant="button"
                        color={!readonly ? "textPrimary" : "textSecondary"}
                        value={parentState.Message}
                        label={t(
                            "registrationForChildren.step2.message.title",
                        )}>
                        <AppTextField
                            control={control}
                            name="Message"
                            fullWidth
                            multiline
                            rows={5}
                            shouldUnregister={true}
                        />
                    </AppLabelWrapper>
                </Grid>
            </Grid>
        );
    };

export default RegistrationListExpendedRowSecondContainer;
