import { makeStyles, Radio, RadioProps } from "@material-ui/core";
import { ReactComponent as CheckDisable } from "assets/images/icons/check-disable.svg";
import { ReactComponent as Check } from "assets/images/icons/check.svg";
import { AppLabelWrapper } from "components";
import { COLOR_BOX_SHADOW } from "theme";
import React from "react";

const useStyles = makeStyles({
    root: {
        width: "3.5rem",
        height: "3.5rem",
        borderRadius: "10px",
        WebkitTouchCallout: "none" /* iOS Safari */,
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
        boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
        background: "#fffff",
    },
    checkIcon: {
        width: "2rem",
        height: "2rem",
        objectFit: "contain",
    },
    body1: {
        paddingLeft: "20px",
    },
});
export interface CustomRadioButtonProps extends RadioProps {
    label: string;
    direction?: "left" | "right";
}
export const CustomRadioButton = React.memo<CustomRadioButtonProps>(
    ({ disabled, direction = "right", label, required, ...rest }) => {
        const classes = useStyles();
        return (
            <AppLabelWrapper
                label={label}
                required={required}
                direction={direction}
                classes={{
                    body1: classes.body1,
                }}
                fullWidth={false}
                color="textSecondary">
                <Radio
                    className={classes.root}
                    disableRipple
                    color="default"
                    checkedIcon={
                        disabled ? (
                            <CheckDisable className={classes.checkIcon} />
                        ) : (
                            <Check className={classes.checkIcon} />
                        )
                    }
                    icon={<></>}
                    {...rest}
                />
            </AppLabelWrapper>
        );
    },
);

export default CustomRadioButton;
