import { StandardProps } from "@material-ui/core";
import Stepper, { StepperProps } from "@material-ui/core/Stepper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { StyleClassKey } from "globals";
import React from "react";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
        },
        instructions: {
            width: "100%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    });
type AppStepperClassKey = StyleClassKey<typeof styles>;
export interface AppStepperFacilitiesMainProps
    extends StandardProps<Omit<StepperProps, "children">, AppStepperClassKey> {
    components: React.ReactNode[];
    showResetButton?: boolean;
    activeStep: number;
}
const useStyles = makeStyles(styles);
export const AppStepperFacilitiesMain = React.memo<AppStepperFacilitiesMainProps>(
    ({ components, showResetButton, activeStep, ...rest }) => {
        const classes = useStyles(rest);

        return (
            <div className={classes.root}>
                <Stepper
                    activeStep={activeStep}
                    {...rest}>
                </Stepper>
                <div className={classes.instructions}>
                    {components[activeStep]}
                </div>
            </div>
        );
    },
);
export default AppStepperFacilitiesMain;
