import { CookiesKey } from "globals/Types/General";
import { RoutePathInterface } from "globals";
import { cookieAvailable, getCookie } from "globals/cookie";
import { useUserContext } from "providers";
import { Route, useHistory } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import {
    MapSearchRoutePathList,
    PublicRoutePathList,
    RoutesPathsForAuthentication,
    FacilitiesRoutePathList,
    SuperAdminRoutePathList,
} from "screens";
import { User } from "sdk";
import { useEffect } from "react";
const isStaticRoutes: RoutePathInterface[] = [
    ...PublicRoutePathList,

    ...MapSearchRoutePathList,

    ...FacilitiesRoutePathList,

    ...SuperAdminRoutePathList,

];
const pathForAuthentication: RoutePathInterface[] = [
    ...RoutesPathsForAuthentication,
];

export const ApplicationRoute: React.FC<any> = ({
    component: Component,
    layout: Layout,
    uniqueKey,
    ...rest
}) => {
    const history = useHistory();
    const { user, setUser } = useUserContext();
    const userJsonValues = getCookie(CookiesKey.USER_COOKIE);
    useEffect(() => {
        if (!user && setUser) {
            //okay i get it user do have cookies but he isn't login so
            //get the cookies are create the sessions
            if (userJsonValues) {
                setUser(JSON.parse(userJsonValues) as User);
            }

            // );
            // UserClaimsService.setClaims(
            //     JSON.parse(getCookie(CookiesKey.USER_CLAIMS)),
            // );
            // SideBarMenuItemsService.createItemsList(
            //     sessionService.getSession().User.Role,
            // );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userJsonValues]);

    return (
        <Route
            {...rest}
            render={(props: any) => {
                //first of all check cookies are available or not

                if (cookieAvailable(CookiesKey.USER_COOKIE) === false) {
                    //okay i get it users dosen't have any cookie
                    //now check where he/she wants to go

                    if (
                        isStaticRoutes.some(
                            (e) => e.path === props.match.path,
                        ) ||
                        pathForAuthentication.some(
                            (e) => e.path === props.match.path,
                        )
                    ) {
                        //now user wants to go the nonauthenticate path like login ,signin
                        //or user wants to go to the static routes like home or else
                        //let him/her pass
                        return (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        );
                    } else {
                        //stop right there users doesn't have any cookie so he/she can't
                        //go to the any other routes else than noauthenticate or static path so
                        //redirect to the logion pages
                        history.push(
                            appRoutesForContext()
                                .auth()
                                .login.setLoginPathParams(props.match.url),
                        );
                        return;
                    }
                }
                //Now i get cookies so check user if login is logion

                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );
};
