import { StandardProps, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { StyleClassKey } from "globals";
import React from "react";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            position: "relative",
            overflow: "hidden",
        },
        bgImage: {
            objectFit: "fill",
            width: "100%",
        },
        center: {
            position: "absolute",
            top: 0,
            width: "100%",
        },
    });

type AppContainerWithBackgroundClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppContainerWithBackgroundProps
    extends StandardProps<
        React.DetailedHTMLProps<
            React.HTMLAttributes<HTMLDivElement>,
            HTMLDivElement
        >,
        AppContainerWithBackgroundClassKey
    > {
    bgUrl: string;
    children: React.ReactNode;
    height?: string;
}
export const AppContainerWithBackground =
    React.memo<AppContainerWithBackgroundProps>(
        ({ children, bgUrl, height, ...rest }) => {
            const classes = useStyles(rest);

            return (
                <div
                    className={classes.root}
                    style={{ height: height }}
                    {...rest}>
                    <img
                        className={classes.bgImage}
                        style={{ height: "100%" }}
                        src={bgUrl}
                        alt=""></img>
                    <div className={classes.center}>{children}</div>
                </div>
            );
        },
    );

export default AppContainerWithBackground;
