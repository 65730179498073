import { AppBar, Grid, IconButton, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { ReactNode } from "react";
export interface AppDialogHeaderProps {
    handleClose: () => void;
    children?: ReactNode;
    className?: string;
}
export const AppDialogHeader = React.memo<AppDialogHeaderProps>(
    ({ handleClose, className, children }) => {
        return (
            <AppBar
                style={{ position: "relative", boxShadow: "0px 0px" }}
                className={className}
                color="transparent"
                position="static">
                <Toolbar>
                    <Grid container style={{ width: "100px" }}>
                        <Grid item>
                            <IconButton
                                edge="start"
                                color="primary"
                                onClick={handleClose}
                                aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center">
                        <Grid item>{children}</Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    },
);

export default AppDialogHeader;
