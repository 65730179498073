import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { ReactComponent as HomeSection1Image1 } from "assets/images/home-section-1-image-1.svg";
import { ReactComponent as CursorSelect } from "assets/images/icons/cursor-select-click.svg";
import { ReactComponent as FileBackList } from "assets/images/icons/file-back-list.svg";
import { ReactComponent as FileCheck } from "assets/images/icons/file-check.svg";
import KitaplatzDe from "assets/images/kitaplatz-de.png";
import clsx from "clsx";
import { AppDetailsWithIcon, AppMapSearchField } from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
import { MapSearchRequest } from "sdk";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            paddingRight: "10px",
            paddingBottom: "150px",
            paddingLeft: "50px",
            justifyContent: "space-between",

            [theme.breakpoints.up("xl")]: {
                paddingBottom: "180px",
            },
            [theme.breakpoints.up("xll")]: {
                paddingTop: "200px",
                paddingBottom: "250px",
            },
        },
        body: {
            [theme.breakpoints.down("md")]: {
                marginTop: "10px",
            },
        },
        textCenter: {
            textAlign: "center",
        },
        detailWithIconContainer: {
            [theme.breakpoints.down("lg")]: {
                marginTop: "30px",
            },
            [theme.breakpoints.up("lg")]: {
                paddingBottom: "20px",
            },
        },
        vectorLineImage: {
            position: "absolute",
        },
        image: {
            objectFit: "contain",
            width: "320px",
            height: "320px",
            [theme.breakpoints.down("lg")]: {
                width: "250px",
                height: "250px",
            },
            [theme.breakpoints.up("xll")]: {
                width: "390px",
                height: "390px",
            },
        },
        textMarker: {
            background: theme.palette.secondary.light,
            paddingRight: "10px",
            paddingLeft: "10px",
            paddingTop: "3px",
        },
        mapSearchFieldContainer: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(4),
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        icon: {
            width: "30px",
            height: "35px",
        },
        siteNameImage: {
            width: "250px",
            objectFit: "contain",
            [theme.breakpoints.down("lg")]: {
                width: "220px",
            },
        },
    }),
);

export const HomeSection1 = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState<MapSearchRequest>({
        lat: null,
        lng: null,
    });
    // const isHd = useMediaQuery(theme.breakpoints.down("md"));
    // const isAboveHd = useMediaQuery(theme.breakpoints.up("md"));
    // const isAboveFullHd = useMediaQuery(theme.breakpoints.up("lg"));
    const history = useHistory();
    return (
        <div className={classes.root}>
            <Grid
                container
                className={classes.body}
                alignContent="center"
                direction="column"
                justifyContent="center">
                <Grid item className={classes.textCenter}>
                    <HomeSection1Image1 className={classes.image} />
                </Grid>
                <Grid item className={classes.textCenter}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img
                            className={classes.siteNameImage}
                            src={KitaplatzDe}
                            alt=""
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    className={classes.textCenter}
                    style={{ marginBottom: "30px" }}>
                    <Typography
                        variant="h5"
                        component="span"
                        color="textSecondary">
                        {t("home.stressFree")}
                    </Typography>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <Typography variant="body1" color="textSecondary">
                        {t("home.findingPlace")}
                    </Typography>
                </Grid>
                <Grid item className={classes.textCenter}>
                    <mark className={classes.textMarker}>
                        <Typography
                            component="span"
                            variant="subtitle1"
                            color="textPrimary">
                            {t("home.weAreChangingThat")}
                        </Typography>
                    </mark>
                </Grid>
                <Grid
                    item
                    className={classes.textCenter}
                    style={{ marginTop: "50px" }}>
                    <Typography variant="h4" color="textPrimary">
                        {t("home.areaSearch")}
                    </Typography>
                </Grid>
            </Grid>
            <div
                className={clsx(
                    classes.textCenter,
                    classes.mapSearchFieldContainer,
                )}>
                <AppMapSearchField
                    values={state}
                    onSearchButtonClick={(value: MapSearchRequest) => {
                        setState(value);

                        history.push(
                            appRoutesForContext()
                                .mapSearch()
                                .mapSearchSetParams(value),
                        );
                    }}
                />
            </div>
            <Grid
                container
                justifyContent="space-between"
                className={classes.detailWithIconContainer}>
                <Grid item>
                    <AppDetailsWithIcon
                        minWidth="306px"
                        iconUrl={<FileBackList className={classes.icon} />}
                        text={t("home.compileKitaWishlist")}
                    />
                </Grid>
                <Grid item>
                    <AppDetailsWithIcon
                        minWidth="231px"
                        iconUrl={<CursorSelect className={classes.icon} />}
                        text={t("home.applyDirectlyOnline")}
                    />
                </Grid>
                <Grid item>
                    <AppDetailsWithIcon
                        minWidth="231px"
                        iconUrl={<FileCheck className={classes.icon} />}
                        text={t("home.ViewApplicationStatus")}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default HomeSection1;
