import {
    makeStyles,
    StandardProps,
    Typography,
    TypographyProps,
} from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import clsx from "clsx";
import { StyleClassKey } from "globals";
import React from "react";
const styles = () =>
    createStyles({
        root: { display: "flex", alignItems: "center", width: "100%" },
        fullWidth: { width: "100%", display: "flex", flexDirection: "column" },
        body1: {
            minWidth: "180px",
        },
        marginBottom: { marginBottom: "10px" },
        textFieldContainer: {
            width: "100%",
        },
    });

type AppLabelWrapperClassKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppLabelWrapperProps
    extends StandardProps<TypographyProps, AppLabelWrapperClassKey> {
    label: string;
    required?: boolean;
    children?: React.ReactNode;
    direction?: "left" | "right" | "top";
    fullWidth?: boolean;
    readOnly?: boolean;
    value?: string;
}
export const AppLabelWrapper = React.memo<AppLabelWrapperProps>(
    ({
        label,
        required,
        direction = "left",
        fullWidth = true,
        children,
        readOnly,
        value,
        className,
        ...rest
    }) => {
        const classes = useStyles(rest);
        return (
            <div
                className={
                    direction === "top" ? classes.fullWidth : classes.root
                }>
                {direction === "left" || direction === "top" ? (
                    <>
                        <Typography
                            className={clsx(classes.body1, {
                                //always applies
                                [classes.marginBottom]:
                                    direction === "top" && !readOnly, //only when open === true
                            })}
                            variant="subtitle1"
                            color="textPrimary"
                            classes={rest.classes}>
                            {label}
                            {required && !readOnly ? " *" : readOnly ? ":" : ""}
                        </Typography>

                        <div
                            className={
                                fullWidth ? classes.textFieldContainer : ""
                            }>
                            {readOnly || children === undefined ? (
                                <Typography
                                    color="textPrimary"
                                    variant="h6"
                                    {...rest}>
                                    {value}
                                </Typography>
                            ) : (
                                children
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            className={
                                fullWidth ? classes.textFieldContainer : ""
                            }>
                            {children}
                        </div>
                        <Typography
                            className={classes.body1}
                            variant="subtitle1"
                            color="textPrimary"
                            {...rest}>
                            {required && !readOnly ? "*" : readOnly ? ":" : ""}{" "}
                            {label}
                        </Typography>
                    </>
                )}
            </div>
        );
    },
);

export default AppLabelWrapper;
