import { ExpandableRow } from "globals";
import { KindergartenRegistrationStatus, KindergartenStatus } from "sdk";

export interface SearchByFacilitiesName {
    search?: string;
}

export interface AllKindergartensRequest extends SearchByFacilitiesName {
    page: number;
    perPage: number;
    kindergartenStatus?: number;
}

export const getAllKindergartensRequestInitialValue =
    (): AllKindergartensRequest => {
        return {
            page: 0,
            perPage: 0,
            search: "",
        };
    };

export interface AllKindergartensModal extends ExpandableRow, AllKindergartensInfo {
}

export interface AllKindergartensInfo {
    email: string;
    firstname: string;
    lastname: string;
    kindergartenName: string;
    address: string;
    longitude: string;
    latitude: string;
    postalCode: string;
    city: string;
    phone: string;
    companyID: string;
    // registerPerson: boolean;
    country: string;
    requestsCount: number;
    kindergartenStatus: KindergartenStatus;
    kindergartenId: number;
    isRegistered: string;
}

export interface UpdateKindergartens {
    kindergartenId: number | null;
    firstname: string | null;
    lastname: string | null;
    kindergartenName: string | null;
    address: string | null;
    longitude: string | null;
    latitude: string | null;
    postalCode: string | null;
    city: string | null;
    phone: string | null;
    companyID: string | null;
    registerPerson: boolean | null;
    country: string | null;
}

export interface AllParentsRequest {
    page: number;
    perPage: number;
}

export const getAllParentsRequestInitialValue =
    (): AllParentsRequest => {
        return {
            page: 0,
            perPage: 0,
        };
    };

export interface AllParentsInfo {
    id: number,
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    phoneNumber: string,
    country: string,
    city: string,
    address: string,
    postalCode: string,
    userId: number,
    imageUrl: File[] | null,
    imageFile: File[] | null,
}

export interface AllParentsModal extends ExpandableRow, AllParentsInfo {
}

export interface KindergartenRequestsInfo {
    childId: number;
    firstname: string;
    lastname: string;
    gender: GenderKindergartenRequests;
    birthDate: string,
    parentId: number;
    parentFirstName: string;
    parentLastName: string;
    supportStart: string;
    careHour: CareHoursKindergartenRequests;
    message: string;
    kindergartenRequestId: number;
    status: KindergartenRegistrationStatus;
}

export interface KindergartenRequestsModal extends ExpandableRow, KindergartenRequestsInfo {
}

export enum GenderKindergartenRequests {
    MALE = "Male",
    FEMALE = "Female",
}

export enum CareHoursKindergartenRequests {
    FOUR_TO_FIVE = "FOUR_TO_FIVE",
    FIVE_TO_SEVEN = "FIVE_TO_SEVEN",
    SEVEN_T0_NINE = "SEVEN_T0_NINE",
    NINE_TO_TWELVE = "NINE_TO_TWELVE",
}
