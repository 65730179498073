export const RegistrationListEnTranslations = {
    registrationList: {
        title: "Child List",
        registrationId: "Registration ID",
        date: "Date",
        firstName: "First Name",
        lastName: "Last Name",
        age: "Age",
        gender: "Gender",
        start: "Start",
        end: "End",
        childRow: {
            title: "Information about the child",
        },
    },
    kindergartenRegistrationList: {
        title: "Kindergarten Registration List",
        registrationId: "Registration ID",
        date: "Date",
        kindergarten: "Kindergarten",
        location: "Location",
        info: "Info",
        status: "Status",
        childRow: {
            title: "Kindergarten Information",
            name: "Name",
            city: "City",
            address: "Address",
            indexNumber: "Index Number",
            email: "Email",
            phoneNumber: "Phone Number",
            info: "Info",
        },
    },
    listChildrenRequests: {
        all: "All",
        Accepted: "Accepted",
        Pending: "Pending",
        Rejected: "Rejected",
        children: "Children",
    },
};
export default RegistrationListEnTranslations;
