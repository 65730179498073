import { Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import img from "assets/images/bg/select-all-results-bg.png";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-white.svg";
import Success from "assets/images/logos/success.png";
import {
    AppButton
} from "components";
import { AppDialog } from "components/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
        },
        button: {
            height: "56px",
            width: "75%",
            margin: 5,
        },
        icon: {
            objectFit: "contain",
            paddingTop: 5,
            width: 40,
            height: 30,
        },
        arrow: {
            objectFit: "contain",
            width: 20,
            height: 20,
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "150px",
        },

    }),
);
export interface RegisterSuccessBeforeEmailDialogProps {
    open: boolean;
    handleClose: () => void;
}

export const RegisterSuccessBeforeEmailDialog: React.FC<RegisterSuccessBeforeEmailDialogProps> = ({
    handleClose,
    open,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    const submit = () => {
        history.push(
            appRoutesForContext()
                .public()
                .homePath,
        );
    };

    return (
        <div className={classes.root}>
            <AppDialog
                withBackgroundImage={true}
                open={open}
                closeAfterTransition={true}
                handleClose={handleClose}
                bgImageUrl={img}
                bgImageHeight={"100vh"}
            >
                <div className={classes.dialogContainer}>
                    <Grid
                        container
                        alignItems="center"
                        direction="column" >
                        <Grid item >
                            <Typography variant="h4">
                                <img src={Success} className={classes.icon} alt="success" />{t("auth.registerSucces.registration")} <span style={{ color: "mediumseagreen" }}><>{t("auth.registerSucces.isDone")}</></span>
                            </Typography>
                        </Grid>
                        <Grid style={{ marginTop: 30, textAlign: "center", marginBottom: 30 }}>
                            <Typography variant="body1" color="textSecondary">
                                {t("auth.registerSucces.registrationMessage")}
                            </Typography>
                        </Grid>
                        <Grid>
                            <AppButton
                                onClick={submit}
                                type="submit"
                                text={t("auth.registerSucces.btnText")}
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "230px" }}
                                classes={{ root: classes.button }}
                                endIcon={
                                    <ArrowIcon className={classes.arrow} />
                                }
                            />
                        </Grid>
                    </Grid>
                </div>
            </AppDialog>
        </div>

    );
};

export default RegisterSuccessBeforeEmailDialog;
