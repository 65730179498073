import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";
import { AppCircleWithCounterAndDetails } from "components";
import React from "react";
export interface HomeSection2DetailsProps {
    count: number;
    title: string;
    body1: string;
    body2: string;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        bodyContainer: {
            marginTop: theme.spacing(2),
        },
        body1: {
            marginBottom: "30px",
        },

        counter: {
            fontSize: "1.5rem",
        },
    }),
);
export const HomeSection2Details: React.FC<HomeSection2DetailsProps> = ({
    body1,
    body2,
    count,
    title,
}) => {
    const classes = useStyles();

    return (
        <Grid container direction="column">
            <AppCircleWithCounterAndDetails
                height="70px"
                width="70px"
                textProps={{
                    color: "textSecondary",
                    className: classes.counter,
                }}
                text={count.toString()}
                title={title}>
                <Grid item className={classes.bodyContainer}>
                    <Typography variant="body1" className={classes.body1}>
                        {body1}
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        {body2}
                    </Typography>
                </Grid>
            </AppCircleWithCounterAndDetails>
        </Grid>
    );
};

export default HomeSection2Details;
