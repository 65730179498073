import { RouteInterface } from "globals";
import { HomeLayout } from "layouts";
import KindergartenRegistrationList from "../KindergartenRegistrationList";
import { ListChildrenRequests } from "../ListChildrenRequests";
import RegistrationList from "../RegistrationList";

import {
    RegistrationListRoutePath,
    KindergartenRegistrationListRoutePath,
    ChildsRequestsListRoutePath,
} from "./registration-list-route-paths";

export const RegistrationListRoutesList: RouteInterface[] = [
    {
        component: RegistrationList,
        exact: true,
        layout: HomeLayout,
        path: RegistrationListRoutePath.path,
        uniqueKey: RegistrationListRoutePath.key,
    },
    {
        component: KindergartenRegistrationList,
        exact: true,
        layout: HomeLayout,
        path: KindergartenRegistrationListRoutePath.path,
        uniqueKey: KindergartenRegistrationListRoutePath.key,
    },
    {
        component: ListChildrenRequests,
        exact: true,
        layout: HomeLayout,
        path: ChildsRequestsListRoutePath.path,
        uniqueKey: ChildsRequestsListRoutePath.key,
    },
];
