export const RegistrationForChildrenEnTranslations = {
    registrationForChildren: {
        title: "Registration for your child",
        childNo: "Child {{count}}",
        addNewChild: "Add an additional Child",
        step1: {
            title: "Information about the child",
            firstNameChild: {
                title: "First name child",
                placeHolder: "Enter first name",
                missing: "First name is missing",
            },
            lastNameChild: {
                title: "Last name child",
                placeHolder: "Enter last name",
                missing: "Last name is missing",
            },
            gender: {
                title: "Gender",
                missing: "Gender is missing",
                female: "Female",
                male: "Male",
            },
            birthdayOfTheChild: {
                title: "Birthday of the child",
                missing: "Birthday is missing",
            },
            supportStart: {
                title: "Support start",
                missing: "Support start is missing",
            },
            careHours: {
                title: "Care hours",
                missing: "Care hours is missing",
                fourTo5Hours: "4-5 hours",
                fiveTo7Hours: "5-7 hours",
                sevenTo9Hours: "7-9 hours",
                nineTo12Hours: "9-12 hours",
            },
        },
        step2: {
            title: "More information",
            message: {
                title: "Message",
                placeHolder: "Your Message",
            },
            attachFile: {
                title: "Attach file (e.g. daycare voucher)",
                attachFileWarning:
                    "Please do not upload family pictures or pictures of your children",
            },
        },
    },
};
export default RegistrationForChildrenEnTranslations;
