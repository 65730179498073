import axios from "axios";
import Cookies from "js-cookie";
import { apiRoutesForContext } from "routing/react-api-route-paths";
import { AddNewFacilitiesViewForm, AllKindergartensRequest, AllParentsRequest, UpdateKindergartens } from "sdk";
import { BaseService } from "services/BaseService";

const tmp = apiRoutesForContext();
export class SuperAdminService extends BaseService {
    public authRoutes: typeof tmp;

    constructor() {
        super();
        this.authRoutes = apiRoutesForContext();
    }




    getAllKindergartens(data: AllKindergartensRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().allKindergartens() + "?pageSize=" + data.perPage + "&page=" + data.page + "&search=" + data.search + "&status=" + data.kindergartenStatus + "&delay=1",
        })
    }


    updateKindergartens(data: UpdateKindergartens): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "put",
            baseURL: this.routes.server.root(),
            data: data,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().updateKindergartens(),
        })
    }

    adminAddNewFacilities(data: AddNewFacilitiesViewForm): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "post",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().adminAddNewFacilities(),
            data: data
        })
    }

    getAllParents(data: AllParentsRequest): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().allParents() + "?pageSize=" + data.perPage + "&page=" + data.page,
        })
    }

    kindergartenRequests(data: number): Promise<any> {
        var token = Cookies.get("JwtToken");
        return axios({
            method: "get",
            baseURL: this.routes.server.root(),
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().kindergartenRequests() + "?kindergartenId=" + data,
        })
    }

    deleteKindergarten(data: number): Promise<any> {
        console.log(data)
        var token = Cookies.get("JwtToken");
        return axios({
            method: "delete",
            baseURL: this.routes.server.root(),
            data: data,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
            url: this.routes.superAdmin().deleteKindergarten() + "/" + data,
        })
    }
}
