import {
    createStyles, Grid, makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { AppPrimaryButtonWithForwardIcon, AppStepperFooterProps, AppTextField } from "components";
import { EMAIL_VALIDATION_PATTERN } from "globals";
import { useUserContext } from "providers";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MultiStepRegistrationFacilities, MultiStepRegistrationFacilitiesForm, getMultiStepRegistrationFacilitiesInitialValue } from "sdk";
import { FacilitiesService } from "services/FacilitiesService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%"
        },
        title: {
            textAlign: "center",
        },

        iconButtonsContainer: {
            marginTop: theme.spacing(1),
        },
        loginButtonContainer: {
            marginTop: theme.spacing(1),
        },
        dialogContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
        },
        dialogBody: {
            height: "55vh",
            maxWidth: "1000px",
            [theme.breakpoints.up("xll")]: {
                maxWidth: "1200px",
            },
        },
    }),
);


const service = new FacilitiesService();

export interface FacilitiesStep4CommonProps
    extends AppStepperFooterProps {
    handleNext: () => void;
    handleStateSet: (value: MultiStepRegistrationFacilities) => void;
    parentState: MultiStepRegistrationFacilities;

}

export const FacilitiesStep4: React.FC<FacilitiesStep4CommonProps> = ({
    handleNext,
    handleStateSet,
    parentState,

    ...rest
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { control, handleSubmit } = useForm<MultiStepRegistrationFacilitiesForm>({});
    const {lng, setLanguage } = useUserContext();

    const submit = handleSubmit((data) => {
        handleStateSet({
            ...parentState,
            ...(data as MultiStepRegistrationFacilitiesForm),
        });
        handleNext();

        let registrationData: MultiStepRegistrationFacilities = getMultiStepRegistrationFacilitiesInitialValue();
        registrationData.IdFacilities = parentState.IdFacilities;
        registrationData.Owner = parentState.Owner;
        registrationData.FullName = data.FullName;
        registrationData.Email = data.Email;
        registrationData.PhoneNumber = data.PhoneNumber;
        registrationData.clientSetPasswordURI= "https://kitaplatz-demo.azurewebsites.net/addPasswordView/redirectPath=-home";
        //registrationData.clientSetPasswordURI= "https://localhost:3001/addPasswordView/redirectPath=-home";
        registrationData.langCode=lng;

        console.log("OVO CEMO SUBMITAT: ");
        console.log(registrationData);

        service.registerFacilities(registrationData)
            .then(function (response) {
                console.log("API RESPONSE:");
                console.log(response);
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })

    });

    return (
        <div className={classes.root}>
            <Grid
                container
                alignItems="center"
                direction="column" >
                <Grid item className={classes.title}>
                    <Typography
                        variant="h4"
                        color="textPrimary">
                        {t("kindergarten.step4.title")}
                    </Typography>
                    <Grid style={{ marginTop: 15, textAlign: "center" }}>
                        <Typography variant="body1" color="textSecondary">
                            {t("kindergarten.step4.text1")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <form noValidate={true} onSubmit={submit}>
                <div className={classes.dialogContainer}>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        className={classes.dialogBody}>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>

                                <Grid item style={{ width: "400px" }}>
                                    <AppTextField
                                        control={control}
                                        name="FullName"
                                        //type="email"
                                        rules={{
                                            required: t(
                                                "kindergarten.step4.fullName.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("kindergarten.step4.fullName.placeHolder")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <AppTextField
                                        control={control}
                                        name="Email"
                                        type="email"
                                        rules={{
                                            required: t(
                                                "kindergarten.step4.email.missing",
                                            ) as string,
                                            pattern: {
                                                value: EMAIL_VALIDATION_PATTERN,
                                                message: t(
                                                    "auth.email.invalid",
                                                ) as string,
                                            },
                                        }}
                                        placeholder={t("kindergarten.step4.email.placeHolder")}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <AppTextField
                                        control={control}
                                        name="PhoneNumber"
                                        // type="email"
                                        rules={{
                                            required: t(
                                                "kindergarten.step4.phone.missing",
                                            ) as string,
                                        }}
                                        placeholder={t("kindergarten.step4.phone.placeHolder")}
                                        fullWidth={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                className={classes.loginButtonContainer}
                                spacing={5}>
                                <Grid item >
                                    <AppPrimaryButtonWithForwardIcon
                                        onClick={submit}
                                        type="submit"
                                        text={t("kindergarten.step4.registration")}
                                        style={{ width: "300px", marginTop: 8 }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </form>

            <Grid
                container
                alignItems="center"
                direction="column" >
                <Grid style={{ textAlign: "center" }}>
                    <Typography variant="body1" color="textSecondary">
                        {t("kindergarten.step4.text2")} <span style={{ color: "blue" }}> {t("kindergarten.step4.text2Blue")} </span>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default FacilitiesStep4;
