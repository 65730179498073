export const AuthDeTranslations = {
    auth: {
        loginError: "E-Mail oder Passwort ist ungültig",
        login: "EINLOGGEN",
        signUp: "ANMELDEN",
        dontHaveAccountYet: "Sie haben noch kein Konto?",
        loginTitle: "Einloggen",
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi",
        email: {
            title: "Deine E-Mail",
            missing: "E-Mail fehlt",
            invalid: "Ungültige E-Mail",
        },
        password: {
            title: "Passwort",
            missing: "Das Passwort fehlt",
            placeHolder: "Passwort eingeben",
            invalid: "Ungültiges Passwort",
        },
        confirmPassword: {
            title: "Bestätigen Sie Ihr Passwort",
            missing: "Die Passwortbestätigung fehlt",
            invalid: "Passwort bestätigen ist ungültig",
            notMatched: "Passwortbestätigung stimmt nicht überein",
            placeHolder: "Bestätigen Sie Ihr Passwort",
        },
        firstName: {
            firstName: "Vorname",
            firstNameMissing: "Der Vorname fehlt",
        },
        lastName: {
            lastName: "Nachname",
            lastNameMissing: "Nachname fehltt",
        },
        companyName: {
            companyName: "Name des Unternehmens",
            companyNameMissing: "Der Firmenname fehlt",
        },

        stayLoggedIn: "Angemeldet bleiben",
        passwordForgotten: "Password vergessen?",
        loginButtonText: "Einloggen",
        OrRegisterWith: "Oder registrieren mit",
        alreadyHaveAccount: "Sie haben bereits ein Konto?",
        signUpTitle: "Anmelden",
        signUpButton: "Anmelden",
        registerTabs: {
            Parent: "Kunde",
            Kindergarten: "Einrichtung / Unternehmen",
            SuperAdministrator: "Super Admin",
        },
        successfullyRegistered: "Erfolgreich registriert",
        registerSucces: {
            registration: "Ihre Registrierung",
            isDone: "ist abgeschlossen!",
            registrationMessage: "Sie erhalten nun eine Bestätigungsemail. Bitte bestätigen Sie Ihre Emailadresse.",
            btnText: "Fertig",
        }
    },
};
export default AuthDeTranslations;
