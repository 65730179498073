export interface PasswordForgotten {
    email: string;
    clientResetPassURI: string;
    langCode: string;
}

export const getPasswordForgottenInitialValue =
    (): PasswordForgotten => {
        return {
            email: "",
            clientResetPassURI: "",
            langCode: "de",
        };
    };

export interface ResetPassword {
    email: string;
    password: string;
    confirmPassword: string;
    code: string;
}

export const getResetPasswordInitialValue =
    (): ResetPassword => {
        return {
            email: "",
            password: "",
            confirmPassword: "",
            code: "",
        };
    };


export interface ResetPasswordForm {
    Email: string;
    Password: string;
    ConfirmPassword: string;
}

export const getResetPasswordFormInitialValue =
    (): ResetPasswordForm => {
        return {
            Email: "",
            Password: "",
            ConfirmPassword: "",
        };
    };