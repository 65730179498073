import {
    createStyles,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import { ReactComponent as AppLogo } from "assets/images/logos/kitaplatz-footer-logo.svg";
import config from "config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
import {
    COLOR_PURPLE,
    COLOR_PURPLE_LIGHT_FOOTER,
    DASHBOARD_FOOTER_LEFT_RIGHT_PADDING, HEIGHT_OF_FOOTER
} from "theme";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: COLOR_PURPLE,
            height: HEIGHT_OF_FOOTER,
            padding: DASHBOARD_FOOTER_LEFT_RIGHT_PADDING,
            display: "flex",
            alignItems: "center",
        },
        versionLabel: {},
        caption: {
            color: COLOR_PURPLE_LIGHT_FOOTER,
            cursor: "pointer",
        },
        title: { color: theme.palette.common.white },
        footerLogo: { marginBottom: "20px", marginTop: "-15px" },
    }),
);
export const AppFooter = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const theme = useTheme();

    const isHd = useMediaQuery(theme.breakpoints.down("md"));
    const linkMargin = isHd ? "70px" : "150px";
    return (
        <div className={classes.root}>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                style={{ height: "100%" }}>
                <Grid item className={classes.versionLabel}>
                    <AppLogo className={classes.footerLogo} />
                    <Typography variant="body1" className={classes.caption}>
                        © 2021 kitaplatz
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item style={{ marginRight: linkMargin }}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography
                                        onClick={() =>
                                            history.push(
                                                appRoutesForContext().registrationList()
                                                    .registrationListPath,
                                            )
                                        }
                                        variant="body1"
                                        className={classes.title}>
                                        {t("footer.about")}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginTop: "15px" }}>
                                    <Typography
                                        onClick={() =>
                                            history.push(
                                                appRoutesForContext()
                                                    .public()
                                                    .contactUsPath
                                            )
                                        }
                                        variant="body1"
                                        className={classes.caption}>
                                        {t("footer.contact")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        onClick={() =>
                                            history.push(
                                                appRoutesForContext()
                                                    .public()
                                                    .imprintPath
                                            )
                                        }
                                        variant="body1"
                                        className={classes.caption}>
                                        {t("footer.imprint")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item style={{ marginRight: linkMargin }}>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography className={classes.title}>
                                        {config.siteName?.toUpperCase()}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginTop: "15px" }}>
                                    <Typography
                                        variant="body1"
                                        className={classes.caption}>
                                        {t("footer.jobs")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={1}>
                                <Grid item>
                                    <Typography className={classes.title}>
                                        {t("footer.believedSearch")}
                                    </Typography>
                                </Grid>
                                <Grid item style={{ marginTop: "15px" }}>
                                    <Typography
                                        variant="body1"
                                        className={classes.caption}>
                                        {t("footer.daycareCenters1")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        className={classes.caption}>
                                        {t("footer.daycareCenters2")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default AppFooter;
