import {
    CircularProgress,
    createStyles,
    Grid, makeStyles, Theme,
    Typography
} from "@material-ui/core";
import VectorLine from "assets/images/vector-line-vertical.png";
import {
    AppContainerWithLineSeparation,
    AppPrimaryButtonWithForwardIcon
} from "components";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import { FacilitiesProfileModel, FacilitiesProfileUpdateRequest } from "sdk/models/Profile";
import { FacilitiesService } from "services/FacilitiesService";
import { BG_GRADIENT, FULL_VIEW_HEIGHT_WITHOUT_HEADER } from "theme";
import {
    FacilitiesProfileAddressInfo,
    FacilitiesProfilePersonalInfo
} from "./Partials";
import FacilitiesProfileHeader from "./Partials/FacilitiesProfileHeader";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: FULL_VIEW_HEIGHT_WITHOUT_HEADER,
            width: "100%",
            background: BG_GRADIENT,
            display: "flex",
            justifyContent: "center",
            paddingBottom: "50px",
        },
        body: {
            marginTop: theme.spacing(2),
            width: "100%",
            maxWidth: "1000px",
        },
        saveButton: {
            display: "flex",
            justifyContent: "center",
            marginTop: theme.spacing(5),
        },
        vectorImage: {
            backgroundRepeat: "repeat-y",
            marginTop: "60px",
            background: `url("${VectorLine}")`,
        },
        headerContainer: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(8),
        },
        spinner: {
            display: "flex",
            alignItems: "center",
            height: "100vh",
        },
    }),
);

const service = new FacilitiesService();
export const FacilitiesProfile = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const classes = useStyles();
    const { control, handleSubmit, reset } = useForm<FacilitiesProfileModel>({
        defaultValues: {
            Name: "",
            FirstName: "",
            LastName: "",
            Email: "",

            Country: "",
            Address: "",
            City: "",
            PostalCode: "",
        },
    });
    const location = useLocation();
    const isEdit = location.pathname.includes("edit");
    const [state, setState] = useState<{
        loading: boolean;
        profile: FacilitiesProfileModel | undefined;

    }>({
        loading: true,
        profile: undefined,

    });


    useEffect(() => {
        service.getFacilitiesProfile()
            .then(function (response) {
                console.log(response);
                let userProfile: FacilitiesProfileModel = {
                    UserId: response.data.userId,
                    Name: response.data.name,
                    FirstName: response.data.firstname,
                    LastName: response.data.lastname,
                    Email: response.data.email,
                    Country: response.data.country,
                    PostalCode: response.data.postalCode,
                    Address: response.data.address,
                    City: response.data.city,
                    ProfilePicture: response.data.imageUrl,
                }

                const profile = userProfile;
                if (profile) {
                    reset({
                        ...profile,
                        ProfilePicture: state.profile?.ProfilePicture,
                    });
                    setState({
                        ...state,
                        profile: {
                            ...profile,
                            ProfilePicture:
                                profile.ProfilePicture &&
                                    profile.ProfilePicture.length > 0
                                    ? response.data.imageUrl
                                    : state.profile?.ProfilePicture,
                        },
                        loading: false,
                    });
                }
            })
            .catch(function (error) {
                console.log("API ERROR:");
                console.log(error);
            })
    }, []);

    const submit = handleSubmit((data) => {
        console.log(data);
        let updateRequest: FacilitiesProfileUpdateRequest = {
            kindergartenName: data.Name,
            firstName: data.FirstName,
            lastName: data.LastName,
            email: data.Email,
            country: data.Country,
            address: data.Address,
            city: data.City,
            postalCode: data.PostalCode,
        }
        const updateRequestImage = new FormData();

        if (data.ProfilePicture) { updateRequestImage.append("imageFile", data.ProfilePicture[0]); }

        if (data.ProfilePicture) {
            service.updateFacilitiesProfile(updateRequest)
                .then(function (response) {
                    service.updateFacilitiesProfileImage(updateRequestImage)
                        .then(function (response) {
                            toast.success(t("common.successfullySaved"));
                            setState({ profile: data as FacilitiesProfileModel, loading: false });
                            history.push(
                                appRoutesForContext()
                                    .facilitiesProfile()
                                    .facilitiesProfilePath,
                            );
                        })
                        .catch(function (error) {
                            console.log("API ERROR:");
                            console.log(error);
                        })

                })
                .catch(function (error) {
                    console.log("API ERROR:");
                    console.log(error);
                })
        } else {
            service.updateFacilitiesProfile(updateRequest)
                .then(function (response) {
                    toast.success(t("common.successfullySaved"));
                    setState({ profile: data as FacilitiesProfileModel, loading: false });
                    history.push(
                        appRoutesForContext()
                            .facilitiesProfile()
                            .facilitiesProfilePath,
                    );
                })
                .catch(function (error) {
                    console.log("API ERROR:");
                    console.log(error);
                })
        }
    });


    return (
        <div className={classes.root}>

            {state.loading ? (
                <div className={classes.spinner}>
                    <CircularProgress />
                </div>
            ) : (
                <div className={classes.body}>
                    <form onSubmit={submit} noValidate={true}>
                        <Grid
                            container
                            alignItems="center"
                            direction="column"
                            className={classes.headerContainer}>
                            <Grid item>
                                <Typography variant="h4">
                                    {t("companyProfile.title")}
                                </Typography>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                                <FacilitiesProfileHeader
                                    isEdit={isEdit}
                                    control={control}
                                    files={state.profile?.ProfilePicture}
                                />
                            </Grid>
                        </Grid>
                        <AppContainerWithLineSeparation
                            components={[
                                {
                                    component: (
                                        <FacilitiesProfilePersonalInfo
                                            control={control}
                                            isEdit={isEdit}
                                            value={state.profile}
                                        />
                                    ),
                                },
                                {
                                    component: (
                                        <FacilitiesProfileAddressInfo
                                            control={control}
                                            isEdit={isEdit}
                                            value={state.profile}
                                        />
                                    ),
                                },
                            ]}
                        />
                        <Grid container>
                            {isEdit && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.saveButton}>
                                    <AppPrimaryButtonWithForwardIcon
                                        type="submit"
                                        style={{ minWidth: "187px" }}
                                        text={t("common.save")}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </div>
            )}
        </div>
    );
};

export default FacilitiesProfile;
