import {
    createStyles,
    Grid,
    makeStyles,
    StandardProps,
    Typography,
} from "@material-ui/core";
import { StyleClassKey } from "globals";
import React from "react";

const styles = () =>
    createStyles({
        root: {},
        text: {},
        image: {
            width: "35px",
            height: "35px",
        },
        imageContainer: {
            display: "flex",
            alignItems: "center",
        },
        textContainer: {
            display: "flex",
            alignItems: "center",
        },
    });

type AppTextWithIconKey = StyleClassKey<typeof styles>;
const useStyles = makeStyles(styles);
export interface AppTextWithIconProps
    extends StandardProps<
        React.DetailedHTMLProps<
            React.ImgHTMLAttributes<HTMLImageElement>,
            HTMLImageElement
        >,
        AppTextWithIconKey
    > {
    text: string;
    svg?: React.ReactNode;
}

export const AppTextWithIcon: React.FC<AppTextWithIconProps> = ({
    text,
    svg,
    ...rest
}) => {
    const classes = useStyles(rest);
    return (
        <Grid
            container
            alignItems="center"
            spacing={1}
            className={classes.root}>
            {svg && (
                <Grid
                    item
                    xs={2}
                    style={{ maxWidth: "40px" }}
                    className={classes.imageContainer}>
                    {svg}
                </Grid>
            )}
            <Grid item xs={10} className={classes.textContainer}>
                <Typography
                    noWrap={true}
                    variant="subtitle1"
                    color="textSecondary">
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AppTextWithIcon;
