import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import clsx from "clsx";
import { useUserContext } from "providers";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { appRoutesForContext } from "routing/react-route-paths";
import { StripeSubscribe } from "sdk";
import { AuthService } from "services/AuthService";
import { CHILDREN_REGISTRATION_MAX_WIDTH, COLOR_BOX_SHADOW, COLOR_GRAY, COLOR_LIGHT_BLUE_TABLE_HEADER_BG, COLOR_PRIMARY, COLOR_WHITE } from "theme";
import theme from "theme/Theme";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: "20px",
      backgroundColor: COLOR_LIGHT_BLUE_TABLE_HEADER_BG,
      borderRadius: "10px",
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      border: "3px solid lightgrey",
    },
    fullWidth: { width: "400px" },
    fieldsContainer: {
      marginTop: theme.spacing(4),
    },
    body: {
      width: "100%",
      maxWidth: CHILDREN_REGISTRATION_MAX_WIDTH,
    },
    stripeElement: {
      margin: "10px",
      boxShadow: `3px 6px 15px -3px ${COLOR_BOX_SHADOW}`,
      borderRadius: "10px",
      backgroundColor: COLOR_WHITE,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: COLOR_WHITE,
        borderRadius: "10px",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)",
        border: `1px solid ${COLOR_PRIMARY}`,
        marginRight: "-1px",
        marginLeft: "-1px",
      },
    },
    stripeButton: {
      float: "left",
      display: "block",
      background: "#666EE8",
      color: "white",
      boxShadow: "0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08)",
      borderRadius: "10px",
      border: 0,
      marginTop: "20px",
      fontSize: "15px",
      fontWeight: 400,
      width: "100%",
      height: "40px",
      lineHeight: "38px",
      outline: "none",
      "&:focus": {
        background: "#555ABF",
      },
      ":active": {
        background: "#43458B",
      }
    },
    stripeCardError: {
      fontSize: "15px",
      color: "#E4584C",
    },

  }),
);

const serviceAuth = new AuthService();

export interface CheckoutFormProps {
  stripeSubscribe: StripeSubscribe;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({ stripeSubscribe }) => {
  // 1️⃣ Setup state to track client secret, errors and checkout status
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const classes = useStyles();
  const { user } = useUserContext();
  const history = useHistory();

  // 2️⃣ Store reference to Stripe
  const stripe = useStripe();
  const elements = useElements();

  const inputStyle = {
    showIcon: true,
    iconStyle: "solid" as StripeCardNumberElementOptions["iconStyle"],
    style: {
      base: {
        iconColor: "lightgrey",

        lineHeight: "40px",
        maxHeight: "40px",
        textAlign: "center",
        justifyContent: "center",

        color: COLOR_GRAY,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontWeight: 600,
        fontSize: "20px",
        "::placeholder": {
          color: "lightgrey",
          fontFamily: `sans-serif`,
          fontSize: "20px",
          textAlign: "center",
          justifyContent: "center",
        },
      },
      invalid: {
        color: "red",
        iconColor: "red",
      },
    },
  };

  const handleChange = async (event: any) => {
    // 4️⃣ Listen for changes in the CardElement and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    setProcessing(true);
    // 5️⃣ Confirm Card Payment.
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      console.log("stripe")
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let cardElement = elements?.getElement(CardNumberElement);

    if (cardElement) {
      // 5️⃣ Confirm Card Payment.
      if (cardElement && user) {
        stripe.createToken(cardElement).then(function (response) {
          // Handle result.error or result.token
          if (response.token) {
            console.log(response.token.id)
            console.log(user)
            let stribeSubscribe: StripeSubscribe = {
              email: user.Email,
              name: user.Fullname,
              token: response.token.id,
            };

            serviceAuth.stripeSubscribe(stribeSubscribe)
              .then(function (response) {
                console.log(response);
                console.log(user)
                history.push(
                  appRoutesForContext()
                    .public()
                    .homePath
                );
              })
              .catch(function (error) {
                console.log("API ERROR");
                console.log(error);
              })
          } else {
            console.log(response.error)
          }
        })

      } else if (cardElement && !user) {
        stripe.createToken(cardElement).then(function (response) {
          // Handle result.error or result.token
          if (response.token) {
            console.log(response.token.id)
            console.log(user)

            let stribeSubscribe: StripeSubscribe = {
              email: stripeSubscribe.email,
              name: stripeSubscribe.name,
              token: response.token.id,
            };
            console.log(stribeSubscribe)
            serviceAuth.stripeSubscribe(stribeSubscribe)
              .then(function (response) {
                console.log(response);
                history.push(
                  appRoutesForContext()
                    .auth()
                    .registerSuccessBeforeEmail
                    .setRegisterSuccessBeforeEmailPathParams("home"),
                );
              })
              .catch(function (error) {
                console.log("API ERROR");
                console.log(error);
              })
          } else {
            console.log(response.error)
          }
        })
      }
    }
  };
  // 6️⃣ Construct UI.


  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Grid
          container
          direction="column"
          spacing={2}
          className={clsx(
            classes.fullWidth,
            classes.fieldsContainer,
          )}>
          <form id="payment-form" onSubmit={handleSubmit}>
            <div className={classes.stripeElement}>
              <CardNumberElement
                options={inputStyle}
                onChange={handleChange}
              />
            </div>
            <div className={classes.stripeElement}>
              <CardCvcElement
                options={inputStyle}
                onChange={handleChange}
              />
            </div>
            <div className={classes.stripeElement}>
              <CardExpiryElement
                options={inputStyle}
                onChange={handleChange}
              />
            </div>
            <button type="submit" className={classes.stripeButton}>
              Submit
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
              <div className={classes.stripeCardError} role="alert">{error}</div>
            )}
          </form>
        </Grid>
      </div>
    </div>
  );
}