import FacebookIcon from "assets/images/logos/facebook.png";
import { createUserCookies } from "globals/cookie";
import { useUserContext } from "providers";
import { useEffect } from "react";
import FacebookLogin from 'react-facebook-login-typed';
import { useHistory } from "react-router";
import { appRoutesForContext } from "routing/react-route-paths";
import { getSocialLoginInitialValue, SocialLogin } from "sdk";
import { AuthService } from "services/AuthService";
import { LoginWithOtherIconButton } from "./LoginWithOtherIconButton";
const service = new AuthService();

export interface SocialLoginProps {
    submitData?: () => void;
}

export const LoginFacebook: React.FC<SocialLoginProps> =
({
    submitData
}) => {
  const { user, setUser } = useUserContext();
  const history = useHistory();

  const responseFacebook = (response: any) => {
    let socialLoginData: SocialLogin = getSocialLoginInitialValue();
    socialLoginData.provider = "Facebook";
    socialLoginData.token = response.accessToken;

    console.log("OVO CEMO SUBMITAT: ");
    console.log(socialLoginData);

    service.socialLogin(socialLoginData)
      .then(function (response) {
        console.log("API RESPONSE:");
        console.log(response);

        if (setUser) {
          setUser({
            Email: response.data.email,
            jwtToken: response.data.jwtToken,
            FirstName: response.data.firstName,
            LastName: response.data.lastName,
            Fullname: response.data.fullname,
            Type: response.data.type,
            ProfileImageUrl: response.data.profileImageUrl,
          });
        } else {
          //setError(t("auth.loginError") as string);
        }
      })
      .catch(function (error) {
        console.log("API ERROR:");
        console.log(error);
      })

  };
  useEffect(() => {
    console.log("User: " + user);
    if (user) {
      createUserCookies(user);
      if(submitData)
        submitData();
      history.push(appRoutesForContext().public().homePath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <FacebookLogin
        appId="1568265603558842"
        autoLoad={false}
        callback={responseFacebook}
        render={(renderProps: any) => (
          <LoginWithOtherIconButton imageUrl={FacebookIcon} handleClick={renderProps.onClick} />
        )}
      />
    </>
  );
};

export default LoginFacebook;
