import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";
import { COLOR_GRAY_900 } from "theme";

export interface AppStripeBorderProps {
    color?: string;
    strokeWidth?: number;
    dashoffset?: number;
    containerWidth?: string;
    containerHeight?: string;
    backgroundColor?: string;
    children?: React.ReactNode;
    className?: string;
    borderRadius?: number;
    dashHeight?: number;
    dashSpacing?: number;
}
const useStyles = makeStyles({
    // style rule

    border: (props: AppStripeBorderProps) => ({
        backgroundColor: props.backgroundColor,
        width: props.containerWidth,
        height: props.containerHeight,
        borderRadius: props.borderRadius + "px",
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${props.borderRadius}' ry='${props.borderRadius}' stroke='%23${props.color}FF' stroke-width='${props.strokeWidth}' stroke-dasharray='${props.dashHeight}%2c ${props.dashSpacing}' stroke-dashoffset='${props.dashoffset}' stroke-linecap='square'/%3e%3c/svg%3e")`,
    }),
});
export const AppContainerWithStripeBorder: React.FC<AppStripeBorderProps> = ({
    children,
    color = COLOR_GRAY_900,
    dashSpacing = 6,
    dashHeight = 3,
    containerWidth = "100%",
    className,
    ...rest
}) => {
    const theme = useTheme();
    color = color ? color : theme.palette.grey[900];
    const classes = useStyles({
        ...rest,
        dashSpacing,
        containerWidth,
        dashHeight,
        color: color.replace("#", ""),
    });
    return <div className={clsx(classes.border, className)}>{children}</div>;
};

export default AppContainerWithStripeBorder;
