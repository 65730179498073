import { createTheme, ThemeOptions } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import {
    BREAK_POINT_XL,
    COLOR_GRAY,
    COLOR_LIGHT_GREEN,
    COLOR_PRIMARY,
    COLOR_PRIMARY_DARK,
    COLOR_SECONDARY,
    COLOR_TEXT_DARK,
    COLOR_WHITE,
} from "./ThemeConstants";
import React from "react";

declare module "@material-ui/core" {
    //custom theme options typings
    interface Theme extends ThemeOptions {}
    interface ThemeOptions {
        colors?: {
            green: React.CSSProperties["color"];
            orange: React.CSSProperties["color"];
            red?: React.CSSProperties["color"];
            redDark?: React.CSSProperties["color"];
            darkGrey?: React.CSSProperties["color"];
            yellow?: React.CSSProperties["color"];
        };
        drawer?: {
            breakpoint: Breakpoint;
            width: React.CSSProperties["width"];
            titleFontSize: React.CSSProperties["fontSize"];
            color: React.CSSProperties["color"];
            activeItemColor: React.CSSProperties["color"];
            logoSize: React.CSSProperties["width"];
        };
        header?: {
            height: React.CSSProperties["height"];
            color: React.CSSProperties["color"];
            titleFontSize: React.CSSProperties["fontSize"];
            background: React.CSSProperties["background"];
        };
        footer?: {
            height: React.CSSProperties["height"];
            zIndex: React.CSSProperties["zIndex"];
            aboveFooter: React.CSSProperties["zIndex"];
            zIndexOnModal: React.CSSProperties["zIndex"];
        };
        custom?: {
            sectionTopMargin: React.CSSProperties["marginTop"];
        };
        infoPanel?: {
            background: React.CSSProperties["background"];
            breakpoint: Breakpoint;
            width: React.CSSProperties["width"];
        };
    }
}
declare module "@material-ui/core/styles/createBreakpoints" {
    interface BreakpointOverrides {
        xs: true; // removes the `xs` breakpoint
        sm: true;
        md: true;
        lg: true;
        xl: true;
        xll: true; // adds the `tablet` breakpoint
    }
}
function createAppTheme(options: ThemeOptions) {
    return createTheme({
        typography: {
            fontFamily: ["Roboto"].join(","),
        },

        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1550,
                xl: 1920,
                xll: BREAK_POINT_XL,
            },
        },
        palette: {
            background: {
                default: COLOR_WHITE,
            },
            primary: {
                main: COLOR_PRIMARY,
                dark: COLOR_PRIMARY_DARK,
                contrastText: "#fff",
            },
            secondary: {
                main: COLOR_SECONDARY,
                light: COLOR_LIGHT_GREEN,
                contrastText: "#fff",
            },
            success: {
                light: COLOR_LIGHT_GREEN,
                main: COLOR_SECONDARY,
                contrastText: "#fff",
            },
            grey: {
                "100": "#efefef",
                "200": "#eaecec",
                "300": "#dfdfdf",
                "400": "#dedede",
                "500": "#cccccc",
                "600": "#a0a8ae",
                "800": "#999",
                "900": "#7697ae",
            },
        },
        colors: {
            green: COLOR_SECONDARY,
            orange: "#ffa000",
            red: "#f65c5c",
            redDark: "#e35454",
            darkGrey: "#25263e",
            yellow: "#ebe30e",
        },

        overrides: {
            MuiButton: {
                contained: {
                    textTransform: "none",
                    boxShadow: "none",
                },
                outlined: {
                    textTransform: "none",
                },
            },

            MuiStepConnector: {
                line: {
                    borderColor: "transparent",
                },
            },
            MuiStepper: {
                root: {
                    backgroundColor: "transparent",
                },
            },
            MuiRadio: {
                root: {
                    background: COLOR_WHITE,
                },
            },
            MuiTypography: {
                colorTextPrimary: {
                    color: COLOR_TEXT_DARK,
                },
                colorTextSecondary: {
                    color: COLOR_GRAY,
                },
                h1: {},
                h3: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "3.5rem",
                    // },
                },
                h4: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "3.0rem",
                    // },
                },
                h5: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "2.5rem",
                    // },
                },
                h6: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "1.85rem",
                    // },
                },
                subtitle1: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "1.5rem",
                    // },
                    fontWeight: 500,
                },
                subtitle2: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "1rem",
                    // },
                },
                body1: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "1.5rem",
                    // },
                },
                body2: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "1rem",
                    // },
                },
                caption: {
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "0.85rem",
                    // },
                },
                button: {
                    textTransform: "none",
                    fontSize: "1rem",
                    // [MEDIA_QUERY_XL]: {
                    //     fontSize: "1.2rem",
                    // },
                },
            },
        },
        ...options,
    });
}

const theme = createAppTheme({});

export default theme;
