import { Grid, Typography } from "@material-ui/core";
import { AppCircleWithCounter } from "components";
import React from "react";
import { useTranslation } from "react-i18next";

export const SelectAllResultsServices = () => {
    const { t } = useTranslation();
    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item>
                <Typography variant="body1" color="textSecondary">
                    {t("selectAllResults.ourServiceIs") + ":"}
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="h6"></Typography>
                <AppCircleWithCounter text="5,90 €" />
            </Grid>
            <Grid item>
                <Typography variant="h6">/ 6 {t("common.month")}</Typography>
            </Grid>
        </Grid>
    );
};

export default SelectAllResultsServices;
