import {
    createStyles, Grid, makeStyles, Theme, Typography, useTheme
} from "@material-ui/core";
import { ReactComponent as SearchIcon } from "assets/images/icons/magnifier-white.svg";
import { AppButton, AppButtonProps, AppStepperFooterProps, DefaultThemeForReactTableFacilities } from "components";
import AppSelectFacilities from "components/Inputs/ComboBox/AppSelectFacilities";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { appRoutesForContext } from "routing/react-route-paths";
import { FacilitiesDataList, getSearchFacilitiesRequestInitialValue, MultiStepRegistrationFacilities, SearchFacilitiesRequest } from "sdk/models";
import { FacilitiesService } from "services/FacilitiesService";
import {
    COLOR_BOX_SHADOW, COLOR_WHITE
} from "theme";
import { FacilitiesListColumns } from "./FacilitiesListColumns";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "900px",
            flexDirection: "column",
            alignItems: "center",
            height: "60vh",
            paddingRight: "100px",
            paddingBottom: "150px",
            paddingLeft: "50px",
            justifyContent: "space-between",

            [theme.breakpoints.up("xl")]: {
                paddingBottom: "180px",
            },
            [theme.breakpoints.up("xll")]: {
                paddingTop: "200px",
                paddingBottom: "250px",
            },
        },
        body: {
            [theme.breakpoints.down("md")]: {
                marginTop: "10px",
            },
        },
        rootSearch: {
            borderRadius: "10px",
            background: COLOR_WHITE,
            maxWidth: "1000px",
            width: "100%",
            boxShadow: `6px 6px 15px 1px ${COLOR_BOX_SHADOW}`,
            marginTop: 50,
        },
        bodySearch: {
            display: "flex",
            width: "100%",
        },
        rootTable: {
            /*  overflow: "auto",
             background: "transparent",
             display: "flex",
             flexDirection: "column", */
        },
        button: {
            borderRadius: "0px 10px 10px 0px / 0px 10px 10px 10px",
            height: "56px",
            width: "100%",
        },
        icon: {
            objectFit: "contain",
            width: 20,
            height: 20
        },
        divider: {
            width: "1px",
            background: theme.palette.grey[500],
            height: "50%",
        },
        iconLocation: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(2),
        },
    }),

);

export interface FacilitiesStep2CommonProps
    extends AppStepperFooterProps {
    handleNext: () => void;
    searchButtonProps?: AppButtonProps;
    handleStateSet: (value: MultiStepRegistrationFacilities) => void;
    parentState: MultiStepRegistrationFacilities;
    changePicture: () => void;
}

const service = new FacilitiesService();
export const FacilitiesStep2: React.FC<FacilitiesStep2CommonProps> = ({
    handleNext,
    searchButtonProps,
    handleStateSet,
    parentState,
    changePicture,

    ...rest
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    let history = useHistory();

    const [listOfFacilities, setlistOfFacilities] = useState<FacilitiesDataList[]>([]);
    const [table, setTable] = useState(false);
    const { control, handleSubmit } = useForm<SearchFacilitiesRequest>();
    const [counter, setCounter] = useState(0);
    const [state, setState] = useState<SearchFacilitiesRequest>(getSearchFacilitiesRequestInitialValue());

    const openTable = () => {
        setTable(true);
    };

    const closeTable = () => {
        setTable(false);
    };

    useEffect(() => {
        closeTable();
    }, []);

    const onInputChangeName = (event: any, value: any) => {
        setState({
            ...state,
            name: event.target.value === undefined ? "" : event.target.value,
        });
    }

    const onChangeName = (event: any, value: any) => {
        let newValue = value as FacilitiesDataList;
        try {
            setState({
                ...state,
                name: newValue.Name === undefined ? "" : newValue.Name,
            });
        } catch (error) {

        }
    }

    const onChangeZipCode = (event: any, value: any) => {
        setState({
            ...state,
            zip: event.target.value === undefined ? "" : event.target.value,
        });
    }
    //////////////////////////////


    useEffect(() => {
        setCounter(0);

        if ((state.zip.length >= 5 && state.name.length < 5) || (state.name.length >= 5 && state.zip.length < 5) || (state.name.length >= 5 && state.zip.length)) {
            changePicture();
            setlistOfFacilities([]);
            setCounter(0);
            openTable();

            service.getKindergartensFacilitiesByZipAndName(state)
                .then(function (response) {
                    console.log(response);
                    let list: FacilitiesDataList[] = [];
                    response.data.data.forEach((element: any) => {
                        let kindergartenItem: FacilitiesDataList = {
                            Address: element.address,
                            Name: element.name,
                            Id: element.kindergartenId,
                            ZipCode: parseInt(element.postalCode),
                            City: element.city,
                            IsRegistered: element.isRegistered,
                        };
                        setCounter(counter => counter + 1);
                        list.push(kindergartenItem);
                    });
                    setlistOfFacilities(list);
                })
                .catch(function (error) {
                    console.log(error);
                })
            console.log(counter)
        }
    }, [state]);

    const renderOption = (option: FacilitiesDataList) => (
        <div>
            <div>{option.Name}</div>
            <Grid style={{ margin: 2, textAlign: "left" }}>
                <Typography variant="body1" color="textSecondary">
                    {option.ZipCode}, {option.Address}, {option.City}
                </Typography>
            </Grid>
        </div>
    )

    const getOptionLabel = (option: FacilitiesDataList) => (option.Name);

    const submit = handleSubmit(() => {

        changePicture();
        setlistOfFacilities([]);
        setCounter(0);
        openTable();

        service.getKindergartensFacilitiesByZipAndName(state)
            .then(function (response) {
                console.log(response);
                let list: FacilitiesDataList[] = [];
                response.data.data.forEach((element: any) => {
                    let kindergartenItem: FacilitiesDataList = {
                        Address: element.address,
                        Name: element.name,
                        Id: element.kindergartenId,
                        ZipCode: parseInt(element.postalCode),
                        City: element.city,
                        IsRegistered: element.isRegistered,
                    };
                    setCounter(counter => counter + 1);
                    list.push(kindergartenItem);
                });
                setlistOfFacilities(list);
            })
            .catch(function (error) {
                console.log(error);
            })


    });

    const getFacilities = (isRegistered: boolean, id: number) => {
        handleStateSet({
            ...parentState,
            IdFacilities: id
        });
        if (isRegistered === false) {
            handleNext();
        } else {
            toast.success("Already Registered");
        }
    }
    ///////////////////////button addNewKindergarten
    const location = useLocation();
    const addNewKindergarten = () => {
        history.replace(
            appRoutesForContext()
                .facilitiesProfile()
                .addNewFacilitiesView
                .setAddNewFacilitiesViewPathParams(location.pathname)
        );
    }
    //////////////////////////////////////////

    return (<>
        <div className={classes.root}>
            <div className={classes.body}>
                <div>
                    <Grid
                        container
                        alignItems="center"
                        direction="column" >
                        <Grid item >
                            <Typography variant="h4" style={{ textAlign: "center", justifyContent: "center", justifyItems: "center" }}>
                                {t("kindergarten.step2.title")}
                            </Typography>
                        </Grid>
                        <Grid style={{ margin: 10, textAlign: "center", justifyContent: "center", justifyItems: "center" }}>
                            <Typography variant="body1" color="textSecondary">
                                {t("kindergarten.step2.text")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        style={{ marginBottom: 30, textAlign: "end", justifyContent: "end", justifyItems: "end" }}
                        container
                        alignItems="flex-end"
                        justifyContent="space-between">
                        <Grid item>
                            <AppButton
                                text={t("kindergarten.step2.addNewKindergarten")}
                                variant="outlined"
                                color="primary"
                                onClick={addNewKindergarten}
                            />
                        </Grid>

                    </Grid>
                </div>
                <div className={classes.rootSearch}>
                    <form noValidate onSubmit={submit}>
                        <div className={classes.bodySearch}>
                            <div style={{ flexGrow: 2 }}>
                                <AppSelectFacilities
                                    control={control}
                                    name="zipCode"
                                    onInputChange={onChangeZipCode}
                                    freeSolo={true}
                                    showerrorsintooltip={"true"}
                                    tooltipprops={{ arrow: true }}
                                    textfieldprops={{
                                        customBorderRadius: "10px 0px 0px 10px / 10px 0px 0px 10px",
                                        removeShadow: true,
                                        placeholder: t("kindergarten.step2.search.posctcode"),
                                    }}
                                    options={[]}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className={classes.divider}></div>
                            </div>
                            <div style={{ flexGrow: 9 }}>

                                <AppSelectFacilities
                                    control={control}
                                    name="name"
                                    renderOption={renderOption}
                                    getOptionLabel={getOptionLabel}
                                    onChange={onChangeName}
                                    onInputChange={onInputChangeName}
                                    freeSolo={true}
                                    showerrorsintooltip={"true"}
                                    tooltipprops={{ arrow: true }}
                                    textfieldprops={{
                                        customBorderRadius: "0px 0px 0px 0px / 0px 0px 0px 0px",
                                        removeShadow: true,
                                        placeholder: t("kindergarten.step2.search.facilityName"),
                                    }} options={[]} />
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <AppButton
                                    type="submit"
                                    text={t("kindergarten.step2.search.search")}
                                    variant="contained"
                                    color="primary"
                                    style={{ minWidth: "145px" }}
                                    classes={{ root: classes.button }}
                                    startIcon={
                                        <SearchIcon className={classes.icon} />
                                    }
                                    {...searchButtonProps}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                {table && (
                    <Grid style={{ margin: 10, textAlign: "end" }}>
                        <Typography variant="body1" color="textSecondary">
                            {t("kindergarten.step2.table.found")} {counter} {t("kindergarten.step2.table.results")}
                        </Typography>
                    </Grid>
                )}

                {table && (<div className={classes.rootTable}>
                    <DataTable
                        customStyles={DefaultThemeForReactTableFacilities(theme)}
                        columns={FacilitiesListColumns(getFacilities)}
                        data={listOfFacilities}
                        onRowClicked={(row) => {
                            handleStateSet({
                                ...parentState,
                                IdFacilities: row.Id
                            });
                            if (row.IsRegistered === false) {
                                handleNext();
                            } else {
                                toast.success(t("kindergarten.step2.table.toastSuccess"));
                            }
                        }}
                        noTableHead={true}
                        noDataComponent={t("kindergarten.step2.table.noRecords")}
                    />
                </div>)}
            </div>
        </div>
    </>
    );
};

export default FacilitiesStep2;




