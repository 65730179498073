export const ImprintEnTranslations = {
    imprint: {
        title: "Imprint",
        euDisputeResolution: {
            title: "EU Dispute Resolution",
            description:
                "The European Commission provides a platform for Online Dispute Resolution (OS):",
            url: "https://ec.europa.eu/consumers/odr",
            urlDescription: "You can find our e-mail address under Contact",
        },
        liabilityForLinks: {
            title: "Liability for links",
            description1:
                "Our offer contains links to external websites of third parties, on whose contents we have no influence.",
            description2:
                "Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking.",
            description3:
                "However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately.",
        },
        consumerDispute: {
            title: "Consumer Dispute Resolution",
            description:
                "We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.",
        },
        liabilityForContents: {
            title: "Liability for contents",
            description1:
                "As a service provider, we are responsible for our own content on these pages in accordance with general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG). According to §§ 8 to 10 TMG, however, we are not obligated as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity. ",
            description2:
                "Obligations to remove or block the use of information under the general 2 / 4 laws remain unaffected. However, liability in this regard is only possible from the time of knowledge of a specific infringement. If we become aware of such infringements, we will remove this content immediately.",
        },
        copyright: {
            title: "Copyright",
            description1:
                "The content and works created by the site operators on these pages are subject to German copyright law. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator.",
            description2:
                "Downloads and copies of this page are only permitted for private, non-commercial use. Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.",
            urlTitle: "Source:",
            url: "https://www.e-recht24.de",
        },
    },
};
export default ImprintEnTranslations;
