import { AppStepperFooterProps } from "components";
import moment, { Moment } from "moment";

export interface RegistrationForParentModal
    extends RegistrationForParentStep1Modal,
    RegistrationForParentStep2Modal,
    RegistrationForParentStep3Modal { }

export interface RegistrationForParentStep1Modal {
    Email: string;
}

export interface RegistrationForParentStep2Modal {
    Password: string;
    ConfirmPassword: string;
}

export interface RegistrationForParentStep3Modal {
    FirstNameParent: string;
    LastNameParent: string;
    DateOfBirth: Moment;
    PhoneNumber: string;
    Country: string;
    Address: string;
    City: string;
    PostalCode: number;
}

export interface RegistrationForParentStepperCommonProps
    extends AppStepperFooterProps {
    handleNext: () => void;
    handleStateSet: (value: RegistrationForParentModal) => void;
    parentState: RegistrationForParentModal;
}

export const getInitialValuesOfParentRegistration =
    (): RegistrationForParentModal => {
        return {
            Address: "",
            City: "",
            ConfirmPassword: "",
            Email: "",
            FirstNameParent: "",
            DateOfBirth: moment(),
            Country: "",
            LastNameParent: "",
            Password: "",
            PhoneNumber: "",
            PostalCode: 0,
        };
    };
